import { Button, Input, Modal, Popconfirm, Table, Tooltip } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined, PlusCircleOutlined
} from '@ant-design/icons';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MASKS_TYPE, roundButtonWidth } from '../../../constants/constants';
import { useStore } from '../../../store/store';
import { useModalOpener } from '../../../hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';


const { Search } = Input;

const selector = (state) => {
  const masksWithoutHeaderFooter = state.masks.filter((m) => m.id !== MASKS_TYPE.HEADER.id &&
    m.id !== MASKS_TYPE.FOOTER.id && (m?.KeyPdfToImage === null || !m?.KeyPdfToImage)).map((m) => ({ ...m, ids: [m.id], key: m.id, deletable: true, duplicable: true, editable: true }));
  const editableMaskValue = state.masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask
  const mergedHeaderFooterMask = {
    name: `${MASKS_TYPE.HEADER.name}/${MASKS_TYPE.FOOTER.name}`,
    key: `${MASKS_TYPE.HEADER.id}-${MASKS_TYPE.FOOTER.id}`,
    deletable: false,
    duplicable: false,
    editable: false,
    editableMask: typeof editableMaskValue !== 'boolean' ? true : editableMaskValue,
    ids: [MASKS_TYPE.HEADER.id, MASKS_TYPE.FOOTER.id]
  }
  const selectableMasks = state.landscape ? masksWithoutHeaderFooter : [mergedHeaderFooterMask, ...masksWithoutHeaderFooter]
  return {
    handleMaskList: state.handleMaskList,
    currentDocument: state.currentDocument,
    selectableMasks,
    deleteMask: state.deleteMask,
    createMask: state.createMask,
    renameMask: state.renameMask,
    duplicateMask: state.duplicateMask,
    setMaskEditing: state.setMaskEditing,
    setEditableMask: state.setEditableMask
  }
};

export const TabsMasks = ({ closeConfiguration }) => {
  const {
    currentDocument,
    selectableMasks,
    handleMaskList,
    deleteMask,
    createMask,
    renameMask,
    duplicateMask,
    setMaskEditing,
    setEditableMask
  } = useStore(selector);
  const { t } = useTranslation();
  const [nameSearch, setNameSearch] = useState('');
  const [isHovered, setIsHovered] = useState(false);

  const padLock = (mask) => {
    if (mask?.editableMask || typeof mask?.editableMask !== 'boolean') {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole-open', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
    else {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
  }

  const handleSetEdiatebleMask = (mask) => {
    if (typeof mask?.editableMask !== 'boolean' || mask.editableMask) {
      mask.ids.map((id) => setEditableMask(id, false))
    } else {
      mask.ids.map((id) => setEditableMask(id, true))
    }
  }

  const cols = [
    {
      title: 'Nom',
      key: 'name',
      //editable: true,
      align: 'center',
      className: 'nameColumn',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
        },
      }),
      onCell: (record) => {
        return {
          style: {
            wordWrap: 'anywhere',
            wordBreak: 'normal',
          },
          onClick: async () => {
            if (currentDocument.type === 'template') {
              setMaskEditing(record.ids);
              handleMaskList(false);
              closeConfiguration();
            }
          },
        };
      },
      filteredValue: [nameSearch],
      onFilter: (value, record) => {
        return record.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          );
      },
      render: (record) => <div>{record.name}</div>,
      width: 300,
    },
  ];
  cols.push({
    title: "Éditable",
    key: "editable",
    align: "center",
    width: 50,
    onHeaderCell: () => ({
      style: {
        backgroundColor: '#0B2239',
        color: 'white',
      },
    }),
    render: (record) =>
      <div
        style={{ display: "flex", justifyContent: "center" }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Button shape="circle"
          size={14}
          onClick={() => handleSetEdiatebleMask(record)}
          style={{
            width: roundButtonWidth,
            height: roundButtonWidth,
            minWidth: roundButtonWidth,
            minHeight: roundButtonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon={
            padLock(record)
          }
        />
      </div>
  })



  cols.push({
    title: 'Actions',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          fontSize: '1vw',
          justifyContent: 'space-evenly',
        }}
      >
        {record.deletable ? <Tooltip title='Supprimer'>
          <Popconfirm
            title={'Supprimer ce masque ?'}
            onConfirm={() => {
              setMaskEditing([])
              deleteMask(record.id)
            }}
            okText={t('yes')}
            cancelText={t('cancel')}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </Tooltip> : <div style={{ width: '1vw' }} />}
        {record.editable ? <Tooltip title='Renommer'>
          <EditOutlined
            onClick={() => {
              showModal('renameMask', record);
              setEditNewName(record.name);
            }}
          />
        </Tooltip> : <div style={{ width: '1vw' }} />}
        {record.duplicable ? <Tooltip title='Dupliquer'>
          <div style={{ transform: 'rotate(180deg)' }}>
            <CopyOutlined
              style={{ transform: 'scaleX(-1)' }}
              onClick={() => showModal('duplicateMask', record)}
            />
          </div>
        </Tooltip> : <div style={{ width: '1vw' }} />}
      </div>
    ),
    onHeaderCell: () => ({
      style: {
        backgroundColor: '#0B2239',
        color: 'white',
      },
    }),
    width: 150,
  });

  const columns = cols.map((c) => {
    if (!c.editable) return c;
    return {
      ...c,
      onCell: (record) => ({
        record,
        editable: c.editable,
        dataIndex: c.dataIndex,
        title: c.title,
      }),
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
        },
      }),
    };
  });
  const [isOpen, setIsOpen] = useModalOpener(false);
  const [modalSelection, setModalSelection] = useState('');
  const [selectedMask, setSelectedMask] = useState([]);

  const showModal = (origin, mask) => {
    setIsOpen(true);
    setModalSelection(origin);
    setSelectedMask(mask);
  };

  const [editNewName, setEditNewName] = useState('');
  const [duplicateNewName, setDuplicateNewName] = useState('');
  const [maskName, setMaskName] = useState('');

  const handleOk = (e) => {
    if (modalSelection === 'renameMask') {
      renameMask(selectedMask.id, editNewName);
    } else if (modalSelection === 'createMask') {
      createMask({ name: maskName.name });
    } else if (modalSelection === 'duplicateMask') {
      duplicateMask(selectedMask.id, duplicateNewName);
    }
    setIsOpen(false)
  };

  const handleCancel = () => {
    setIsOpen(false);
    // setIsModalOpen({ ...isModalOpen, isOpen: false });
    setEditNewName('');
    setDuplicateNewName('');
  };

  console.log("eeee", selectableMasks);

  const [isCreateButtonHovered, setIsCreateButtonHovered] = useState(false);
  return (
    <div name='mainDiv'>
      <div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '1%',
          }}
        >
          <div style={{ display: "flex", alignItems: 'center', flexGrow: 1, marginRight: 8 }}>
            <div style={{ whiteSpace: 'nowrap' }}>Recherche par nom:</div>
            <Search
              key='nameSearch'
              placeholder='Rechercher un masque par nom'
              value={nameSearch}
              style={{
                width: 300,
                flexGrow: 1,
                marginBottom: '2%',
                marginTop: '2%',
                marginLeft: '2%',
              }}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              ghost
              size='large'
              icon={<PlusCircleOutlined />}
              onClick={() => showModal('createMask')}
              onMouseEnter={() => {
                setIsCreateButtonHovered(true);
              }}
              onMouseLeave={() => {
                setIsCreateButtonHovered(false);
              }}
              style={{
                backgroundColor: isCreateButtonHovered ? '#2166ab' : 'white',
                color: isCreateButtonHovered ? 'white' : '#2166ab',
                marginBottom: '3%',
                borderColor: '#2166ab',
                fontSize: '0.9vw',
                // fontWeight: 'bold',
                borderRadius: '30px',
                border: '1.5px solid ',
              }}
            >
              Créer un nouveau masque
            </Button>
          </div>
        </div>
        <Table
          pagination={false}
          columns={columns}
          dataSource={selectableMasks}
          rowKey="id"
          bordered
        />
      </div>
      <Modal
        title={
          modalSelection === 'renameMask'
            ? 'Edition du masque'
            : modalSelection === 'createMask'
              ? 'Creation du masque'
              : modalSelection === 'duplicateMask'
                ? 'Duplication du masque'
                : null
        }
        open={isOpen}
        onOk={(e) => {
          if (
            maskName !== '' ||
            editNewName !== '' ||
            duplicateNewName !== ''
          ) {
            handleOk(e);
          } else {
            alert('Le nom du masque ne peut pas être vide');
          }
        }}
        onCancel={handleCancel}
        width='50%'
      >
        {modalSelection === 'renameMask' ? (
          <div name='RenameMaskComponent-MainDiv'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '30%' }}>Nouveau nom:</div>
              <Input
                key='renameInput'
                placeholder='Nouveau nom'
                value={editNewName}
                size='middle'
                onChange={(e) => {
                  setEditNewName(e.target.value);
                }}
              />
            </div>
          </div>
        ) : modalSelection === 'createMask' ? (
          <div name='maskNameComponent-MainDiv'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '30%' }}>Nom du nouveau masque:</div>
              <Input
                key='createNameInput'
                placeholder='Nom du nouveau masque'
                value={maskName.name}
                size='middle'
                onChange={(e) => {
                  setMaskName({
                    ...maskName,
                    name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
        ) : modalSelection === 'duplicateMask' ? (
          <div name='DuplicateMaskComponent-MainDiv'>
            <div style={{ marginBottom: '3%', display: 'flex' }}>
              Duplication du masque:
              <div style={{ fontWeight: 'bold', marginLeft: '1%' }}>
                {selectedMask.name}
              </div>
            </div>
            <div>Nom du masque dupliqué:</div>
            <Input
              key='duplicateInput'
              placeholder='Nouveau nom du modèle dupliqué'
              value={duplicateNewName}
              size='middle'
              onChange={(e) => {
                setDuplicateNewName(e.target.value);
              }}
            />
          </div>
        ) : null}
      </Modal>
    </div >
  );
};
