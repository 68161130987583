import { Checkbox, Form, InputNumber, Select } from 'antd';
import { maxFontSize, minFontSize, searchInputWidth } from '../../../constants/gridConfig';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import Container from '../../Container/Container';
import { MASKS_TYPE, viewerTypes } from '../../../constants/constants';
import { useTranslation } from 'react-i18next';

const selector = ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
}) => ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
});

export const TabsPages = () => {
  const { t } = useTranslation();
  const {
    configuration,
    setConfiguration,
  } = useStore(selector);
  const handleChangePageNumberFontColor = (selectedColor) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle,
        color: configuration.colors.find((color) => color.label === selectedColor),
      },
    });
  };

  const handleChangeFontSize = (value) => {
    setConfiguration({
      pageNumberStyle: { ...configuration.pageNumberStyle, size: value },
    });
  };
  const handleChangePageNbFormat = (value) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle,
        format: value
      },
    });
  };
  const handleChangePositionBottom = (value) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle, position: {
          ...configuration.pageNumberStyle.position,
          bottom: value
        }
      },
    });
  };
  const handleChangePositionRight = (value) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle, position: {
          ...configuration.pageNumberStyle.position,
          right: value
        }
      },
    });
  };

  const handleChangeFontWeight = (e) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle,
        fontWeight: e.target.checked ? 'bold' : '',
      },
    });
  };

  const handleChangeFontStyle = (e) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle,
        fontStyle: e.target.checked ? 'italic' : '',
      },
    });
  };


  const handleChangeTextDecoration = (e) => {
    setConfiguration({
      pageNumberStyle: {
        ...configuration.pageNumberStyle,
        textDecoration: e.target.checked ? 'underline' : '',
      },
    });
  };


  return (
    <>
      <Form.Item
        name='page-format'
        label={'Format du numéro de page'}
      >
        <Select
          onChange={handleChangePageNbFormat}
          defaultValue={configuration.pageNumberStyle.format}
          style={{ width: searchInputWidth }}
          options={[
            { value: 'simple', label: 'N° de page (seulement)' },
            { value: 'total', label: 'N° de page / Nb total de pages' },
            { value: 'empty', label: 'Sans numéro de page' },
          ]}
        >
        </Select>
      </Form.Item>
      <Form.Item
        name='page-font-size'
        label={'Taille de police'}
      >
        <InputNumber
          min={minFontSize}
          max={maxFontSize}
          defaultValue={configuration.pageNumberStyle.size}
          onChange={handleChangeFontSize}
        />
      </Form.Item>
      <Form.Item label='Style du texte'>
        <Form.Item label={t('bold')} style={{ display: 'inline-block', margin: '0 8px' }}>
          <Checkbox
            key='bold'
            style={{ fontWeight: 'bold' }}
            checked={configuration.pageNumberStyle.fontWeight}
            onChange={handleChangeFontWeight}
          />
        </Form.Item>
        <Form.Item label={t('italic')} style={{ display: 'inline-block', margin: '0 8px' }}>
          <Checkbox
            key='italic'
            style={{ fontStyle: 'italic' }}
            checked={configuration.pageNumberStyle.fontStyle}
            onClick={handleChangeFontStyle}
          />
        </Form.Item>
        <Form.Item label={t('underline')} style={{ display: 'inline-block', margin: '0 8px' }}>
          <Checkbox
            key='underline'
            style={{ textDecoration: 'underline' }}
            checked={configuration.pageNumberStyle.textDecoration}
            onChange={handleChangeTextDecoration}
          />
        </Form.Item>
      </Form.Item>
      <Form.Item
        name='base-fontColor'
        label='Couleur de police par défaut'
      >
        <ColorSelector
          forcedValue={configuration.pageNumberStyle.color?.label ?? configuration.baseFontColor.label}
          disableTransparent={true}
          onChange={(color) => handleChangePageNumberFontColor(color)}
          colors={configuration.colors}
        />
      </Form.Item>
      <Form.Item
        name='position-bottom'
        label={'Écart en bas'}
      >
        <InputNumber
          min={0}
          defaultValue={configuration.pageNumberStyle.position.bottom}
          onChange={handleChangePositionBottom}
        />
      </Form.Item>
      <Form.Item
        name='position-right'
        label={'Écart à droite'}
      >
        <InputNumber
          min={0}
          defaultValue={configuration.pageNumberStyle.position.right}
          onChange={handleChangePositionRight}
        />
      </Form.Item>
      <div style={{
        display: 'flex',
        alignItems: 'center'
      }}>
        <div style={{
          width: 250,
          display: 'flex',
          justifyContent: 'end',
        }}>
          <div style={{
            marginRight: 10
          }}>
            Aperçu du {MASKS_TYPE.FOOTER.name}
          </div>
        </div>
        <div
          style={{
            position: 'relative'
          }}
        >
          <Container id="footer" fromViewer={viewerTypes.PAGENUMBER} />
          {configuration.pageNumberStyle.format !== 'empty' &&
            <span style={{
              position: 'absolute',
              fontFamily: 'Helvetica',
              right: configuration.pageNumberStyle.position.right,
              bottom: configuration.pageNumberStyle.position.bottom,
              fontSize: configuration.pageNumberStyle.size,
              color: configuration.pageNumberStyle.color?.style ?? configuration.baseFontColor.style,
              fontStyle: configuration.pageNumberStyle.fontStyle,
              fontWeight: configuration.pageNumberStyle.fontWeight,
              textDecoration: configuration.pageNumberStyle.textDecoration,
              lineHeight: 1,
              zIndex: 999
            }}>
              1{configuration.pageNumberStyle.format === 'total' ? '/3' : ''}
            </span>
          }
        </div>
      </div>
    </>
  );
};
