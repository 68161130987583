import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const LineBreak = () => {
  return (
    < FontAwesomeIcon icon={icon({ name: 'turn-down-left', style: 'solid' })} style={{ fontSize: '30px' }} />
  )
}

export default LineBreak
