import React from 'react'
import VariableName from './VariableName'
import { variableStyle } from '../../utils/styles'
import SelectionMiddle from './SelectionMiddle'
import LabelsArray from './LabelsArray'
import VariableRemark from './VariableRemark'

const VariableSelector = ({ box, onBlur, form }) => {
  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableName box={box} onBlur={onBlur} form={form} />
        <VariableRemark box={box} onBlur={onBlur} form={form} />
      </div>
      <div className='variable-editor__middle' style={variableStyle["middle"]}>
        <SelectionMiddle
          box={box}
          onBlur={onBlur}
          form={form}
          arrayKey="options"
          selectionKey="selected"
        />
      </div>
      <div style={variableStyle["right"]}>
        <LabelsArray
          box={box}
          onBlur={onBlur}
          form={form}
          arrayKey="options"
          selectionKey="selected"
        />
      </div>
    </>
  )
}

export default VariableSelector
