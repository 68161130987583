import React from 'react'
import VariableName from './VariableName'
import { variableStyle } from '../../utils/styles'
import VariableRemark from './VariableRemark'

const VariableImages = ({ box, onBlur, form }) => {
  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableName box={box} onBlur={onBlur} form={form} />
        <VariableRemark box={box} onBlur={onBlur} form={form} />
      </div>
      <div className='variable-editor__middle' style={variableStyle["middle"]}>
      </div>
      <div style={variableStyle["right"]}>
      </div>
    </>
  )
}

export default VariableImages
