import React, { useState, useCallback } from 'react';
import { pageContainerWidth } from '../constants/gridConfig';
import { useStore } from '../store/store';

const leftShownStatus = {
  SUMMARY: "summary",
  VARIABLES: "variables"
}

const defaultWidth = 220
const availableScreenWidth = window.screen.width - pageContainerWidth;
const siderWidth = defaultWidth + 34;
const isDefaultCollapsed = availableScreenWidth < siderWidth * 2

const defaultProps = {
  width: defaultWidth,
  left: {
    collapsed: isDefaultCollapsed,
    shownStatus: leftShownStatus["SUMMARY"]
  },
  right: {
    collapsed: isDefaultCollapsed,
  },
}

const OverlayContext = React.createContext({
  ...defaultProps,
  handleOverlayCollapse: (side, collapsed) => null,
  updateLeftShownStatus: (status, boxId) => null
});

const useOverlay = () => {
  const context = React.useContext(OverlayContext);

  if (!context) {
    throw new Error(`useOverlay must be used within a OverlayProvider`);
  }

  return context;
};

const selector = ({ handleBoxVariableSelectedId }) => ({
  handleBoxVariableSelectedId
})

const OverlayProvider = ({ children }) => {
  const { handleBoxVariableSelectedId } = useStore(selector)
  const [overlayProps, setOverlayProps] = useState({
    ...defaultProps,
  });

  const handleOverlayCollapse = useCallback((side, collapsed) => {
    if (side !== "left" && side !== "right") return;

    setOverlayProps((oldProps) => ({
      ...oldProps,
      [side]: {
        ...oldProps[side],
        collapsed
      }
    }))
    handleBoxVariableSelectedId();
  }, [handleBoxVariableSelectedId]);

  const updateLeftShownStatus = useCallback((status, boxId = undefined) => {
    if (!Object.values(leftShownStatus).includes(status)) return;

    setOverlayProps((oldProps) => ({
      ...oldProps,
      left: {
        ...oldProps.left,
        shownStatus: status,
        collapsed: false
      }
    }))
    handleBoxVariableSelectedId(boxId);
  }, [handleBoxVariableSelectedId])

  const value = React.useMemo(
    () => ({ overlayProps, handleOverlayCollapse, updateLeftShownStatus }),
    [overlayProps, handleOverlayCollapse, updateLeftShownStatus]
  );

  return (
    <OverlayContext.Provider value={value}>
      {children}
    </OverlayContext.Provider>
  );
};

export { OverlayProvider, useOverlay, leftShownStatus };
