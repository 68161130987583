import { ItemTypes, MASKS_TYPE, uuid } from '../constants/constants';
import { gridUnit } from '../constants/gridConfig';
import {
  isATitleBox,
  mutateAddBoxes,
  reinitializeBoxWidthAndHeight,
} from './boxes';
import { computeColumnWidth, mutateAddColumns } from './columns';
import { mutateRemoveContainer, mutateRemoveContainers } from './containers';
import { indentTitles } from './titles';
import { mutateSortVariableGroups } from './variableGroups';
import { mutateInjectVariablesInBox } from './variables';

export const mutateInjectSection = ({
  draft,
  sectionContent,
  boxId,
  sectionId,
}) => {
  mutateSortVariableGroups(draft);
  const box = draft.boxes.find((b) => b.id === boxId);
  const containerIdx = draft.containers.findIndex((c) =>
    c.columnsIds.includes(box.columnId)
  );
  const columnsIdDict = {};
  const columnsToAdd = sectionContent.columns.map((c) => {
    const newId = uuid();
    columnsIdDict[c.id] = newId;
    return {
      ...c,
      id: newId,
    };
  });
  const dateTimestamp = Date.now();
  const containersToAdd = sectionContent.containers.map((c) => {
    let containerEditability = true;
    if (typeof c?.editable !== 'boolean' || c?.editable)
      containerEditability = true;
    else containerEditability = false;
    let baseContainer = {
      ...c,
      id: uuid(),
      sectionId: sectionId,
      dateTimestamp: dateTimestamp,
      editable: draft.configuration.editableTemplate && containerEditability,
      columnsIds: c.columnsIds.map((cId) => columnsIdDict[cId]),
    };
    if (box.type === ItemTypes.SECTION_VARIABLE) {
      return {
        ...baseContainer,
        sectionVariableBoxId: boxId,
      };
    }
    return baseContainer;
  });
  const boxesToAdd = sectionContent.boxes.map((b) => {
    let baseBox = {
      ...b,
      id: uuid(),
      columnId: columnsIdDict[b.columnId],
    };
    const column = columnsToAdd.find((c) => c.id === baseBox.columnId);
    if (column && !column.drawMode) {
      const container = containersToAdd.find((c) =>
        c.columnsIds.includes(column.id)
      );
      baseBox = reinitializeBoxWidthAndHeight({
        header: draft.masks.find(({ id }) => id === MASKS_TYPE.HEADER.id),
        footer: draft.masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id),
        box: baseBox,
        onResize: true,
        columnWidth: computeColumnWidth({
          landscape: draft.landscape,
          marginLeft: draft.configuration.margins.left * gridUnit,
          marginRight: draft.configuration.margins.right * gridUnit,
          containerColumnsLength: container.columnsIds.length,
          size: column.size,
        }),
      });
    }
    if (baseBox.type === ItemTypes.CHECKBOXS_VARIABLE) {
      baseBox = {
        ...baseBox,
        checkboxStyleId: 0,
      };
    }
    if (baseBox.type === ItemTypes.SELECTOR_VARIABLE) {
      baseBox = {
        ...baseBox,
        selectorStyleId: 0,
      };
    }
    if (baseBox.type === ItemTypes.TEXT_VARIABLE) {
      baseBox = {
        ...baseBox,
        textStyleId: 0,
      };
    }
    baseBox = mutateInjectVariablesInBox({
      variables: draft.variables,
      box: baseBox,
    });
    if (isATitleBox(baseBox.type)) {
      baseBox = indentTitles({
        box: baseBox,
        columns: columnsToAdd,
        margins: draft.configuration.margins,
        indentationTitles: draft.configuration.indentationTitles,
      });
    }
    return baseBox;
  });

  mutateSortVariableGroups(draft);

  draft.containers.splice(containerIdx + 1, 0, containersToAdd);
  draft.containers = draft.containers.flat();
  mutateAddColumns(draft, columnsToAdd);
  mutateAddBoxes({ draft, newBoxes: boxesToAdd });
  if (box.type !== ItemTypes.SECTION_VARIABLE)
    mutateRemoveContainer(draft, draft.containers[containerIdx].id);
};

export const mutateDeleteSection = ({ draft, boxId, optionId }) => {
  const containersToDelete = draft.containers.filter(
    (c) => c.sectionVariableBoxId === boxId
  );
  const sectionsBoxesToDelete = draft.boxes.filter(
    (b) =>
      containersToDelete.some((c) => c.columnsIds.includes(b.columnId)) &&
      b.type === ItemTypes.SECTION_VARIABLE
  );
  sectionsBoxesToDelete.forEach((sB) => {
    sB.answer = optionId;
    mutateDeleteSection({ draft, boxId: sB.id, optionId });
  });
  mutateRemoveContainers(
    draft,
    containersToDelete.map((c) => c.id)
  );
};
