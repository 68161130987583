import React, { useMemo, useState } from 'react'
import { VARIABLE_COLOR, variableHandlingType } from '../../constants/constants'
import { Divider } from 'antd'
import { useTranslation } from 'react-i18next'
import './VariableSide.less'
import VariableLineSelector from './VariableLineSelector'
import VariableGroupCollapse from './VariableGroupCollapse'
import { useStore } from '../../store/store';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPenToSquare } from '@fortawesome/pro-duotone-svg-icons'

const VariableDivider = ({ setIsHandlingVariables }) => {
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState();
  const currentDocument = useStore(({ currentDocument }) => currentDocument)
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const handleUnSelectBox = useStore(({ handleUnSelectBox }) => handleUnSelectBox)

  return (
    <Divider
      style={{
        color: isHovered && currentDocument.type === "template" ? VARIABLE_COLOR : 'white',
        cursor: currentDocument.type === "template" ? 'pointer' : 'default',
        textDecoration: isHovered && currentDocument.type === "template" ? `underline ${VARIABLE_COLOR}` : undefined,
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => {
        if (currentDocument.type === "template") {
          setIsHandlingVariables(true);
          handleBoxVariableSelectedId();
          handleUnSelectBox();
        }
      }}
    >
      {t('variables')}
      {currentDocument.type === 'template' && <FontAwesomeIcon
        icon={faPenToSquare}
        style={{
          marginLeft: 8
        }}
      />}
    </Divider>
  )
}

const VariablesSide = ({ variablesGroupedByContainer, setIsHandlingVariables }) => {
  const { t } = useTranslation();
  const [groupOpenId, setGroupOpenId] = useState();

  const variablesGroupsAndCustomText = useMemo(() => {
    const variables = variablesGroupedByContainer.filter((vb) => vb.type !== variableHandlingType.FORM);
    return variables
  }, [variablesGroupedByContainer])

  const variablesForm = useMemo(() => {
    return variablesGroupedByContainer.filter((vb) => vb.type === variableHandlingType.FORM)
      .map((vB) => vB.variablesBoxes)
      .flat()
  }, [variablesGroupedByContainer])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column'
      }}>
      <VariableDivider setIsHandlingVariables={setIsHandlingVariables} />
      {
        variablesGroupsAndCustomText.map(
          (vInfos) => vInfos.variableGroupBox ?
            <VariableGroupCollapse
              key={vInfos.variableGroupBox.id}
              variableGroupBox={vInfos.variableGroupBox}
              variablesBoxes={vInfos.variablesBoxes}
              variablesGroupedByContainer={variablesGroupedByContainer}
              groupOpenId={groupOpenId}
              setGroupOpenId={setGroupOpenId}
              setIsHandlingVariables={setIsHandlingVariables}
            /> :
            <VariableLineSelector
              key={vInfos.variablesBoxes[0].id}
              box={vInfos.variablesBoxes[0]}
              variablesGroupedByContainer={variablesGroupedByContainer}
              setIsHandlingVariables={setIsHandlingVariables}
            />
        )
      }
      <Divider style={{ color: 'white' }}>{t('form')}</Divider>
      {variablesForm.map((b) => <VariableLineSelector
        key={b.id}
        box={b}
        variablesGroupedByContainer={variablesGroupedByContainer}
        setIsHandlingVariables={setIsHandlingVariables}
      />)}
    </div>
  )
}

export default VariablesSide
