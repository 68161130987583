import { produce } from 'immer';
import {
  mutateAddVariableGroup,
  mutateDeleteVariableGroup,
} from '../utils/variableGroups';

export const variableGroupsActions = (set, get) => ({
  /* VARIABLE GROUPS */
  addVariableGroup: ({ name, remark, disposition, id }, callback) => {
    set(
      produce((draft) => {
        const newVariableGroup = mutateAddVariableGroup({
          draft,
          name,
          remark,
          disposition,
          id,
        });
        if (typeof callback === 'function') callback(newVariableGroup);
      }),
      false,
      `add new variable group ${name}`
    );
  },

  removeVariableGroup: (id) => {
    set(
      produce((draft) => {
        mutateDeleteVariableGroup({ draft, id });
      }),
      false,
      `remove variable group ${id}`
    );
  },

  updateVariableGroup: (id, callback) => {
    set(
      produce((draft) => {
        const vbIndex = draft.variableGroups.findIndex((vb) => vb.id === id);
        if (typeof callback === 'function')
          callback(draft.variableGroups[vbIndex], draft);
      }),
      false,
      `update Variable Group ${id}`
    );
  },

  reorderVariables: (variables) => {
    set(
      produce((draft) => {
        draft.variableGroups.sort((vA, vB) => {
          const vAIndex = variables.findIndex(({ id }) => id === vA.id);
          const vBIndex = variables.findIndex(({ id }) => id === vB.id);
          const vAIsUsed = vAIndex !== -1;
          const vBIsUsed = vBIndex !== -1;
          if (vAIsUsed && !vBIsUsed) return -1;
          if (!vAIsUsed && vBIsUsed) return 1;
          return vAIndex - vBIndex;
        });
      }),
      false,
      `Reorder variables`
    );
  },
});
