import React, { useCallback, useMemo, useState } from 'react';

// ANT Design
import { Button } from 'antd';

import './LeftSide.less';
import OverlaySider from '../Layout/OverlaySider';
import { navbarHeight } from '../../constants/gridConfig';
import SummarySide from './SummarySide';
import VariablesSide from './VariablesSide';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDoubleRight } from '@fortawesome/pro-solid-svg-icons';
import { useStore } from '../../store/store';
import { antdFormError, pageBackground, variableHandlingType } from '../../constants/constants';
import VariableHandler from '../VariableHandler/VariableHandler';
import { useOverlay } from '../../contexts/OverlayContext';
import { hasVariableBoxError } from '../../utils/boxes';
import { getVariablesGroupedByContainer } from '../../utils/variables';

const LeftSide = () => {
  const currentDocument = useStore((state) => state.currentDocument)
  const currentProposal = useStore((state) => state.currentProposal)
  const columns = useStore((state) => state.columns)
  const containers = useStore((state) => state.containers)
  const boxVariableSelectedId = useStore((state) => state.boxVariableSelectedId)
  const variables = useStore((state) => state.variables)
  const boxes = useStore((state) => state.boxes)
  const masks = useStore((state) => state.masks)
  const variableGroups = useStore((state) => state.variableGroups)

  const [isHandlingVariables, setIsHandlingVariables] = useState(false)

  const variablesGroupedByContainer = useMemo(() => {
    const v = getVariablesGroupedByContainer({
      variableGroups,
      variables,
      boxes: [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ],
      containers,
      columns,
    })
    v.sort((vA, vB) => {
      if (vA.type === variableHandlingType.FORM && vB.type !== variableHandlingType.FORM) return 1
      if (vA.type === variableHandlingType.FORM && vB.type === variableHandlingType.FORM) return 0
      if (vA.type !== variableHandlingType.FORM && vB.type === variableHandlingType.FORM) return -1
      if (vA.variableGroupBox && !vB.variableGroupBox) return -1
      if (vA.variableGroupBox && vB.variableGroupBox) return 0
      if (!vA.variableGroupBox && vB.variableGroupBox) return 1
      return 0
    });
    return v
  }, [boxes, columns, containers, masks, variables, variableGroups]);

  const hasVariableErrors = useMemo(() => {
    return currentProposal?.id && variablesGroupedByContainer
      .map((vB) => vB.variablesBoxes.filter((v) => !v.notUsed)).flat()
      .some((b) => hasVariableBoxError(b).error)
  }, [currentProposal?.id, variablesGroupedByContainer])

  const { overlayProps, updateLeftShownStatus } = useOverlay();
  const shownStatus = overlayProps.left.shownStatus

  const changeStatus = () => {
    const newStatus = shownStatus === "summary" ? "variables" : "summary"
    const usedVariablesGroupedByContainer = variablesGroupedByContainer.filter((vInfos) => {
      if (vInfos.type === variableHandlingType.FORM) return true
      if (vInfos.variableGroupBox)
        return vInfos.variablesBoxes.some((v) => !v.notUsed)
      return !vInfos.variablesBoxes[0].notUsed
    })
    const boxId = newStatus === "summary" || currentDocument.type === "template" || usedVariablesGroupedByContainer.length < 1 ?
      undefined : usedVariablesGroupedByContainer[0].variablesBoxes[0].id
    updateLeftShownStatus(newStatus, boxId);
    if (newStatus === "summary") {
      setIsHandlingVariables(false)
    }
  }

  const buttonColor = hasVariableErrors && shownStatus === "summary" ? antdFormError : "white"

  const onCollapse = useCallback((collapse) => {
    if (collapse) {
      setIsHandlingVariables(false)
    }
  }, [])

  return (
    <>
      <OverlaySider
        side='left'
        zIndex={18}
        onCollapse={onCollapse}
      >
        <div
          className='left-side__summary'
          style={{
            height: `calc(100vh - ${navbarHeight}px)`,
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
          data-role={pageBackground}
        >
          <div style={{
            position: 'sticky',
            top: 0,
            backgroundColor: "#0b2239",
            zIndex: 999,
            display: 'flex',
          }}>
            {currentDocument.type !== "template" && variablesGroupedByContainer.length === 0 ? null : <Button
              style={{
                margin: '10px 0',
                width: "100%",
                backgroundColor: "transparent",
                color: buttonColor,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                borderColor: buttonColor,
                transition: 'color 0.3s ease-in-out, border-color 0.3s ease-in-out',
              }}
              onClick={changeStatus}
            >
              {shownStatus === "summary" ? "Données à compléter" : "Sommaire"}
              <FontAwesomeIcon icon={faChevronDoubleRight}
                style={{
                  color: "white",
                  transform: `rotate(${shownStatus === "summary" ? 0 : 180}deg)`,
                  transition: 'transform 0.5s',
                }} />
            </Button>}
          </div>
          {shownStatus === "summary" &&
            <div className='left-side__wrapper'>
              <SummarySide />
            </div>
          }
          {shownStatus === "variables" &&
            <div className='left-side__wrapper'>
              <VariablesSide
                variablesGroupedByContainer={variablesGroupedByContainer}
                setIsHandlingVariables={setIsHandlingVariables}
              />
            </div>
          }
        </div>
      </OverlaySider >
      {shownStatus === "variables" && (boxVariableSelectedId || isHandlingVariables) &&
        <VariableHandler
          variablesGroupedByContainer={variablesGroupedByContainer}
          isHandlingVariables={isHandlingVariables}
        />
      }
    </>
  );
};

export default React.memo(LeftSide);
