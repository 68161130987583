// ANT Design
import { Divider, Form, Tabs, Modal, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/store';
import { TabsMarges } from './marginTab';
import { TabsFonts } from './FontTab';
import { TabsColors } from './colorTab';
import { TabsTitles } from './titleTab';
import { TabsSummary } from './summaryTab';
import { TabsTables } from './tableTab';
import { TabsPages } from './pageTab';
import { formLabelWidth } from '../../../constants/gridConfig';
import { TabsMasks } from './masksTab';
import { TabsVariable } from './variableTab';
import { collapseConfigPoint } from '../../../constants/constants';

const selector = ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
}) => ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
});

export const RemoveColor = ({ colorSelect, onclick }) => {
  const { configuration } = useStore(selector);
  return (
    <a href="/" key='1' onMouseDown={() => onclick()}>
      {configuration.colorEditionPerm
        ? 'Vous ne pouvez pas supprimer de couleurs'
        : colorSelect === 'white' ||
          colorSelect === 'black' ||
          colorSelect === 'transparent'
          ? 'Couleur non supprimable'
          : 'Supprimer'}
    </a>
  );
};

const ConfigurationModal = ({ open, handleOk, configurationTab, setConfigurationTab }) => {
  // const [form] = Form.useForm();
  const {
    // configuration,
    currentTemplate,
  } = useStore(selector);
  const { t } = useTranslation();

  const tabItems = [
    {
      label: 'Couleurs',
      key: 'Couleurs',
      children: <TabsColors />,
    },
    {
      label: 'Marges',
      key: 'Marges',
      children: <TabsMarges />,
    },
    {
      label: 'Police',
      key: 'Police',
      children: <TabsFonts />,
    },
    {
      label: 'Titres',
      key: 'Titres',
      children: <TabsTitles />,
    },
    {
      label: 'Sommaire',
      key: 'Sommaire',
      children: <TabsSummary />,
    },
    {
      label: 'Tableaux',
      key: 'Tableaux',
      children: <TabsTables />,

    },
    {
      label: 'N° page',
      key: 'N° page',
      children: <TabsPages />,
    },
    {
      label: 'Masques',
      key: 'masks',
      children: <TabsMasks closeConfiguration={handleOk} />,
    },
    {
      label: 'Données à compléter',
      key: 'variables',
      children: <TabsVariable />,
    }
  ]

  return (
    <Modal
      open={open}
      width={window.innerWidth <= collapseConfigPoint ? '100%' : collapseConfigPoint}
      onCancel={handleOk}
      footer={[
        <Button
          key="done"
          type="primary"
          onClick={handleOk}
        >
          {t('close')}
        </Button>,
      ]}
      okText={t('OK')}
      onOk={handleOk}
    >
      <Form
        labelCol={{
          flex: `${formLabelWidth}px`,
        }}
        wrapperCol={{ flex: 1 }}
        labelWrap
        colon={false}
        // form={form}
        layout='horizontal'
        name='form_in_modal'
      // initialValues={configuration}
      >
        <div
          style={{
            display: 'flex',
            fontSize: 18,
          }}
        >
          <div>Style du template: </div>
          <div
            style={{
              fontWeight: 'bold',
              marginLeft: '1%',
            }}
          >
            {currentTemplate?.name}
          </div>
        </div>
        <Divider style={{ marginTop: '1%' }} />
        <Tabs
          items={tabItems}
          destroyInactiveTabPane
          activeKey={configurationTab}
          onChange={(key) => setConfigurationTab(key)}
          size='large'
        />
      </Form>
      {/* <Form
        form={form2}
        layout='vertical'
        name='form_in_modal'
        initialValues={summaryConfiguration}
        onValuesChange={(changedValue) => {
          setSummaryConfiguration({ ...summaryConfiguration, ...changedValue });
        }}
      /> */}
    </Modal>
  );
};

export default ConfigurationModal;
