const pageWidth = 825; //829;
const pageHeight = 1160; //1173;
const masksDefaultHeight = {
  header: 100,
  footer: 100,
};
const pageActionsRightWidth = 200;
const casesInGrid = 256;
const gridWidth = pageWidth / casesInGrid;
const gridHeight = pageHeight / casesInGrid;
const gridUnit = 5;
const addPageButtonHeight = 32;
const marginOnPageButton = 2;
const leftSliderWidth = 60;
const leftSliderMarginRight = 20;
const selectorMaxWidth = Math.trunc(pageWidth / 5);
const selectorMinWidth = Math.trunc(pageWidth / 5);
const selectorMinHeight = 32;
const maxFieldWidth = Math.trunc(pageWidth / 1.5);
const pageContainerHeight =
  pageHeight + 2 * marginOnPageButton + addPageButtonHeight;
const pageContainerWidth = pageWidth + pageActionsRightWidth + leftSliderWidth;
const containerMargin = 20;
const moveHandlerWidth = 20;

export const minFontSize = 8;
export const maxFontSize = 50;

export const formLabelWidth = 250;
export const unitName = 'unités';
export const searchInputWidth = 220;

export const navbarLogoHeight = 50;
export const navbarPadding = 2;
export const navbarHeight = navbarLogoHeight + navbarPadding * 2;
export const contentPadding = 10;

export const maxLeftBounds = 1;
export const maxTopBounds = 1;
export const maxBottomBounds = pageHeight - gridHeight;
export const maxRightBounds = pageWidth - gridWidth;

export const maxBottomBoundsLandscape = pageWidth - gridWidth;
export const maxRightBoundsLandscape = pageHeight - gridHeight;

export const lineSpacing = 13;

const masksPosition = (id, header, footer) => {
  if (id === 'header') {
    return {
      top: 0,
      left: 0,
      height: header.height ?? masksDefaultHeight.header,
    };
  } else if (id === 'footer') {
    return {
      bottom: 0,
      left: 0,
      height: footer.height ?? masksDefaultHeight.footer,
    };
  } else {
    return {};
  }
};

export const marginsObject = (configuration) => ({
  left: configuration.margins.left * Math.round(gridUnit),
  right: configuration.margins.right * Math.round(gridUnit),
  top: masksDefaultHeight.header,
  bottom: masksDefaultHeight.footer,
});

export const getMarginBackground = ({
  display,
  configuration,
  landscape,
  showVerticalBorder,
}) => {
  if (!display) {
    return {};
  }
  const margins = marginsObject(configuration);
  const width = landscape ? pageHeight : pageWidth;
  const height = landscape ? pageWidth : pageHeight;
  const verticalBorder = `
  ,
        linear-gradient(
          to bottom,
          transparent,
          transparent ${margins.top}px,
          black ${margins.top}px,
          transparent ${margins.top + 1}px,
          transparent ${height - margins.bottom}px,
          black ${height - margins.bottom}px,
          transparent ${height - margins.bottom + 1}px,
          transparent ${height}px
        )`;
  return {
    background: `
        linear-gradient(to right,
          transparent,
          transparent ${margins.left}px,
          black ${margins.left}px,
          transparent ${margins.left + 1}px,
          transparent ${width - margins.right}px,
          black ${width - margins.right}px,
          transparent ${width - margins.right + 1}px,
          transparent ${width}px
        )${showVerticalBorder ? verticalBorder : ''}
      `,
  };
};

const calculateWidth = (margins, indentation, indentationFactor) => {
  return (
    pageWidth -
    gridUnit * margins.left -
    gridUnit * margins.right -
    gridUnit * indentation * indentationFactor
  );
};

const calculateHeight = () => {
  const factory = casesInGrid / 32;
  return gridHeight * factory;
};

const calculateLeftPadding = (margins, indentation, indentationFactor) => {
  return gridUnit * margins.left + gridUnit * indentation * indentationFactor;
};

export {
  pageWidth,
  pageHeight,
  masksDefaultHeight,
  masksPosition,
  gridWidth,
  gridHeight,
  gridUnit,
  casesInGrid,
  calculateWidth,
  calculateHeight,
  calculateLeftPadding,
  addPageButtonHeight,
  marginOnPageButton,
  selectorMaxWidth,
  selectorMinWidth,
  selectorMinHeight,
  maxFieldWidth,
  pageContainerHeight,
  pageContainerWidth,
  containerMargin,
  pageActionsRightWidth,
  leftSliderWidth,
  leftSliderMarginRight,
  moveHandlerWidth,
};
