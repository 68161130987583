import React from 'react'
import { useTranslation } from 'react-i18next';
// ANT Design
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

// RichEditor
import { handleAlignmentChange, toggleInlineStyle, removeInlineStyle } from '../RichEditor/utils';
import FontSizeControls from './FontSizeControls';
import FontFamilyControls from './FontFamilyControls';

import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  EditorState, RichUtils,
} from 'draft-js';
import { useStore } from '../../store/store';
import {
  FONT_SIZES,
} from '../../constants/constants';

// common components
import '../common/Utils.css';

import { iconButtonStyle } from '../../utils/styles';
import { Separator } from '../Layout/Navbar';
import { NavbarButton } from '../Layout/NavbarButton';
import ColorControl from './ColorControl';
import HighlightControl from './HighlightControl';

export const setDefaultInlineStyleIfNeeded = (
  currentEditorState,
  removedProperty,
  defaultProperty,
  prefix = ''
) => {
  let nextContentState = currentEditorState.getCurrentContent();
  const blocks = [];
  nextContentState.getBlockMap().forEach((block) => {
    const characterList = block.getCharacterList().map((character) => {
      if (character.hasStyle(`${prefix}${removedProperty}`)) {
        return CharacterMetadata.removeStyle(
          character,
          `${prefix}${removedProperty}`
        );
      } else {
        return character;
      }
    });
    blocks.push(
      new ContentBlock({
        key: block.getKey(),
        text: block.getText(),
        type: block.getType(),
        depth: block.getDepth(),
        data: block.getData(),
        characterList,
      })
    );
  });

  return EditorState.push(
    currentEditorState,
    ContentState.createFromBlockArray(blocks),
    'change-inline-style'
  );
};

const TextStylingComponent = ({
  boxId,
  boxAlignement,
  editorState,
  setEditorState,
  updateBoxOnStylingChange,
  defaultFontSize,
  defaultFontFamily,
  defaultColor,
  hasAlignement = false,
  hasOptionsAfter = true,
}) => {
  const { t } = useTranslation();
  const configuration = useStore((state) => state.configuration);
  const updateBox = useStore((state) => state.updateBox);
  const colors = useStore((state) => state.configuration.colors);

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const textStyleButtons = [
    {
      title: t('bold'),
      textContent: 'G',
      style: { fontWeight: 'bold', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('BOLD'),
      inlineStyleName: 'BOLD'
    },
    {
      title: t('italic'),
      textContent: 'I',
      style: { fontStyle: 'italic', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('ITALIC'),
      inlineStyleName: 'ITALIC'
    },
    {
      title: t('underline'),
      textContent: 'S',
      style: { textDecoration: 'underline', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('UNDERLINE'),
      inlineStyleName: 'UNDERLINE'
    }
  ];

  const toggleInlineState = (inlineStyleName) => (_e) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyleName))
  }

  const applyFontSize = (toggledFontSize) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `FONT_SIZE_${toggledFontSize}`,
      prefix: 'FONT_SIZE_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };
  const applyColor = (toggledColor) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `${toggledColor}`,
      prefix: 'color_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };
  const applyHighlightcolor = (toggledHighlightColor) => {
    const newEditorState = toggledHighlightColor === "transparent"
      ? removeInlineStyle({
        prefix: 'HIGHLIGHT_',
        editor: editorState
      })
      : toggleInlineStyle({
        selectedProperty: `HIGHLIGHT_${toggledHighlightColor}`,
        prefix: 'HIGHLIGHT_',
        editor: editorState
      })
    setEditorState(newEditorState)
    updateBoxOnStylingChange(newEditorState)
  };
  const applyFontFamily = (toggledFontFamily) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `FONT_FAMILY_${toggledFontFamily.replace(/\s+/g, "_")}`,
      prefix: 'FONT_FAMILY_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };

  return (
    <div className='text-styling-component'
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} tabIndex="0">
      {hasAlignement && <>
        <NavbarButton
          tooltipContent={t('left-alignment')}
          onClick={() => handleAlignmentChange(boxId, updateBox, 'left')}
          icon={< FontAwesomeIcon icon={icon({ name: 'align-left', style: 'light' })} style={iconButtonStyle(boxAlignement === 'left')} />}
        />
        <NavbarButton
          tooltipContent={t('center-alignment')}
          onClick={() => handleAlignmentChange(boxId, updateBox, 'center')}
          icon={< FontAwesomeIcon icon={icon({ name: 'align-center', style: 'light' })} style={iconButtonStyle(boxAlignement === 'center')} />}
        />
        <NavbarButton
          tooltipContent={t('right-alignment')}
          onClick={() => handleAlignmentChange(boxId, updateBox, 'right')}
          icon={< FontAwesomeIcon icon={icon({ name: 'align-right', style: 'light' })} style={iconButtonStyle(boxAlignement === 'right')} />}
        />
        <Separator />
      </>}
      <NavbarButton
        tooltipContent={t('LISTE_A_PUCE')}
        onClick={() => toggleBlockType('unordered-list-item')}

        icon={< FontAwesomeIcon icon={icon({ name: 'list-ul', style: 'light' })} style={iconButtonStyle(RichUtils.getCurrentBlockType(editorState) === "unordered-list-item")} />}
      />
      <NavbarButton
        tooltipContent={t('LISTE_NUMEROTEE')}
        onClick={() => {
          toggleBlockType('ordered-list-item');
        }}
        icon={< FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={iconButtonStyle(RichUtils.getCurrentBlockType(editorState) === "ordered-list-item")} />}
      />
      <Separator />
      {configuration.fontFamilyList.length > 1 && (
        <FontFamilyControls
          applyFontFamily={applyFontFamily}
          editorState={editorState}
          fontFamily={defaultFontFamily}
        />
      )}
      <FontSizeControls
        applyFontSize={applyFontSize}
        defaultFontSize={defaultFontSize ?? configuration.fontSize}
        editorState={editorState}
        fontSizes={FONT_SIZES}
      />
      {textStyleButtons.map((button) => {
        return (
          <NavbarButton
            key={button.title}
            tooltipContent={button.title}
            onClick={toggleInlineState(button.inlineStyleName)}
            icon={
              <p style={{ ...iconButtonStyle(button.active), ...button.style }}>
                {button.textContent}
              </p>}
          />
        )
      })}
      <ColorControl
        editorState={editorState}
        onToggle={applyColor}
        colors={colors}
        defaultColor={defaultColor}
      />
      <HighlightControl
        editorState={editorState}
        onToggle={applyHighlightcolor}
        colors={configuration.highlightColors}
      />
      {hasOptionsAfter && <Separator />}
    </div>
  );
};

export default TextStylingComponent;
