import React, { useCallback, useEffect, useMemo, useState } from 'react'
import TableWithDrag from '../common/TableWithDrag'
import InputHideable from '../common/InputHideable'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { tableColor, tableHeaderSecondaryBgColor, variablesIcons } from '../../constants/constants'
import { Checkbox } from 'antd'
import { useStore } from '../../store/store'
import { useTranslation } from 'react-i18next'
import { constructVariableIds, mutateHandleLinkedVariable } from '../../utils/variableGroups'
import { useUpdateVariableGroup } from '../../hooks'

const sortAndFormatVariablesText = ({ variablesGroupedVariables, variableGroupId, t }) => {
  const variablesText = variablesGroupedVariables.map((vInfos) => {
    if (vInfos.variableGroupBox) {
      if (vInfos.variableGroupBox.id === variableGroupId) {
        return vInfos.variablesBoxes.map((vb, idx) => ({
          ...vb,
          linked: true,
          position: idx + 1,
          disabled: vInfos.variablesBoxes.length === 1
        }))
      }
      return []
    }

    return vInfos.variablesBoxes.map((vb) => ({
      ...vb,
      canDrag: false,
      canDrop: false,
      dragIconTitle: t('unmovable-not-linked')
    }))
  }).flat();

  variablesText.sort((vA, vB) => {
    if (vA.linked && !vB.linked) {
      return -1
    }
    if (!vA.linked && vB.linked) {
      return 1
    }
    return 0;
  })
  return variablesText
}

const VariableGroupChildren = ({ box, variablesGroupedVariables }) => {
  const { t } = useTranslation()
  const [searchValue, setSearchValue] = useState('')
  const [dataSource, setDataSource] = useState(sortAndFormatVariablesText({
    variablesGroupedVariables,
    variableGroupId: box.id,
    t
  }));
  const variableGroups = useStore(({ variableGroups }) => variableGroups)

  useEffect(() => {
    const searchCaseDiatricsInsensitive = (search, text) =>
      text
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .includes(
          search
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
        );

    const newData = sortAndFormatVariablesText({
      variablesGroupedVariables,
      variableGroupId: box.id,
      t
    })
    if (searchValue) {
      setDataSource(
        newData
          .filter((record) => searchCaseDiatricsInsensitive(searchValue, record.name))
          .map((record) => ({
            ...record,
            canDrag: false,
            dragIconTitle: t('unmovable-search-active')
          })))
    } else {
      setDataSource(sortAndFormatVariablesText({
        variablesGroupedVariables,
        variableGroupId: box.id,
        t
      }))
    }
  }, [box.id, searchValue, variablesGroupedVariables, t])

  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)
  const {
    mutate: updateVariableGroupMutation,
  } = useUpdateVariableGroup();

  const handleOnCheck = useCallback((variableId) => {
    const variableGroup = variableGroups.find(({ id }) => id === box.id)
    const variableIds = constructVariableIds({ variableGroup, variableId })
    updateVariableGroupMutation({ id: box.id, variableIds }, {
      onSuccess: (updatedVariableGroup) => {
        updateVariableGroup(box.id,
          (variableGroup, draft) => {
            mutateHandleLinkedVariable({
              variableGroup,
              variableId,
              variables: draft.variables,
              variableIds: updatedVariableGroup.variable_ids
            })
          })
      }
    })

  }, [box.id, updateVariableGroup, updateVariableGroupMutation, variableGroups])

  const columns = useMemo(() => {
    return [
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('name')}
          <InputHideable
            setValue={setSearchValue}
            value={searchValue}
          />
        </div>,
        dataIndex: 'name',
        render: (name, record) => <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}>
          <FontAwesomeIcon icon={variablesIcons[record.type]} />
          {name}
        </div>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      },
      {
        title: t('linked'),
        dataIndex: 'linked',
        key: 'linked',
        width: 50,
        render: (linked, record) => <div style={{
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Checkbox checked={linked} disabled={record.disabled} onChange={() => handleOnCheck(record.id)} />
        </div>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
      }
    ]
  }, [t, searchValue, handleOnCheck]);

  const handleOnDrop = useCallback(() => {
    const variableIds = dataSource.filter((r) => r.linked).map(({ id }) => id)
    updateVariableGroupMutation({ id: box.id, variableIds }, {
      onSuccess: (updatedVariableGroup) => {
        updateVariableGroup(box.id,
          (variableGroup) => {
            variableGroup.variableIds = updatedVariableGroup.variable_ids

          })
      }
    })

  }, [box.id, dataSource, updateVariableGroup, updateVariableGroupMutation])

  return (
    <TableWithDrag
      columns={columns}
      dataSource={dataSource}
      setDataSource={setDataSource}
      backgroundColor={tableHeaderSecondaryBgColor}
      color={tableColor}
      onDrop={handleOnDrop}
      scroll={{ y: 'calc(100vh - 54px - 16px - 32px - 16px - 21px - 16px)' }}
    />
  )
}

export default VariableGroupChildren
