import { ItemTypes, colId, rowId } from '../constants/constants';
import { getTable } from '../requests/documentDatas';
import { isAnImageUrl } from './browser';
import { buildUniqueContentData } from './dataSync';

const getAlign = ({ columnsInformations, columnIndex }) => {
  if (!columnsInformations || columnIndex >= columnsInformations.length)
    return 'start';
  return columnsInformations[columnIndex].align;
};

const getWidth = ({
  columnNames,
  columnsInformations,
  columnIndex,
  boxWidth,
}) => {
  if (!columnsInformations || columnNames.length === 1)
    return boxWidth / columnNames.length;

  if (columnIndex >= columnsInformations.length) {
    const perc =
      (100 - columnsInformations.reduce((acc, { width }) => acc + width, 0)) /
      (columnNames.length - columnsInformations.length);
    return (perc * boxWidth) / 100;
  }

  return (columnsInformations[columnIndex].width * boxWidth) / 100;
};

export const getTableColumnsData = (table, boxWidth) => {
  const { rows, columns_informations: columnsInformations } = table;
  const columnNames = Object.keys(rows[0]);
  let totalWidth = 0;
  const columns = columnNames.map((columnName, columnIndex) => {
    const nonEmptyCellValues = rows
      .map((row) => row?.[columnName])
      .filter((n) => n || n === 0);
    const isImage =
      nonEmptyCellValues.length > 0 && nonEmptyCellValues.every(isAnImageUrl);
    let width = getWidth({
      columnNames,
      columnsInformations,
      columnIndex,
      boxWidth,
    });
    totalWidth += width;
    if (columnIndex === columnNames.length - 1 && totalWidth < boxWidth) {
      width = boxWidth - totalWidth + width;
    }

    const column = {
      title: columnName,
      key: colId(),
      width,
      type: isImage ? 'image' : 'text',
      textAlign: getAlign({ columnsInformations, columnIndex }),
    };
    return column;
  });
  return {
    columns,
    data: rows.filter((row) => row).map((row) => ({ ...row, key: rowId() })),
  };
};

export const fetchTableData = async ({
  fetchStoreFunc,
  updateBoxFunc,
  box,
  isNewBox = false,
}) => {
  const { tableName, proposal, width, id } = box;
  let table = await fetchStoreFunc(tableName, proposal);
  if (!table) {
    console.warn(`Table ${tableName} does not exist on the server`);
  }
  if (table.rows.length === 0) {
    console.warn(`Table ${tableName} is empty`);
    updateBoxFunc(id, (boxToUpdate) => {
      if (isNewBox) {
        return {
          ...boxToUpdate,
          content: {
            ...boxToUpdate.content,
            columns: [],
            data: [],
          },
          tableName,
          proposal,
        };
      } else {
        boxToUpdate.content.columns = [];
        boxToUpdate.content.data = [];
        boxToUpdate.tableName = tableName;
        boxToUpdate.proposal = proposal;
      }
    });
  } else {
    const nextBoxContent = getTableColumnsData(table, width);
    const {
      content: { columns, data },
    } = buildUniqueContentData({ content: nextBoxContent });
    updateBoxFunc(id, (boxToUpdate) => {
      if (isNewBox) {
        return {
          ...boxToUpdate,
          content: {
            ...boxToUpdate.content,
            columns,
            data,
          },
          tableName,
          proposal,
        };
      } else {
        boxToUpdate.content.columns = columns;
        boxToUpdate.content.data = data;
        boxToUpdate.tableName = tableName;
        boxToUpdate.proposal = proposal;
      }
    });
  }
};

export const tableBorderStyle = (tableProperties) => {
  const hasBorders =
    tableProperties.bordered && tableProperties.customBorderColor;
  return {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: hasBorders
      ? tableProperties.customBorderColor?.style
      : 'transparent',
  };
};

export const convertPadding = (size) => {
  let paddingInfos = {};
  if (size === 'middle') {
    paddingInfos = { horizontal: 8, vertical: 8 };
  } else if (size === 'small') {
    paddingInfos = { horizontal: 5, vertical: 4 };
  } else paddingInfos = { horizontal: 8, vertical: 12 };
  paddingInfos.padding = `${paddingInfos.vertical}px ${paddingInfos.horizontal}px`;
  return paddingInfos;
};

export const getCellStyle = ({
  tableStyle,
  isEven,
  configuration,
  columnKey,
  width,
}) => {
  return {
    position: 'relative',
    padding: 0,
    backgroundColor: tableStyle?.stripped1
      ? isEven
        ? tableStyle.strippedCustomClr1.style
        : tableStyle.strippedCustomClr2.style
      : tableStyle.customCellColor.style,
    color: tableStyle.customTextColor1.style,
    fontSize: tableStyle?.fontSizeCells || configuration.fontSize,
    fontFamily: tableStyle?.fontFamilyCells || configuration.fontFamily,
    overflowWrap: 'break-word',
    width: width ?? `calc(var(--${columnKey}-size) * 1px - 1px)`,
    ...tableBorderStyle(tableStyle),
  };
};

export const getHeaderStyle = ({
  tableStyle,
  configuration,
  columnKey,
  width,
}) => {
  return {
    backgroundColor: tableStyle.customHeaderColor.style ?? '#fafafa',
    color: tableStyle.customHeaderTextColor.style,
    fontSize: tableStyle?.fontSizeHeader || configuration.fontSize,
    fontFamily: tableStyle?.fontFamilyHeader || configuration.fontFamily,
    overflowWrap: 'break-word',
    position: 'relative',
    padding: 0,
    width: width ?? `calc(var(--${columnKey}-size) * 1px - 1px)`,
    ...tableBorderStyle(tableStyle),
  };
};

export const fillEmptyAdminTablesBoxes = async ({
  boxes,
  proposable_id,
  type,
}) =>
  await Promise.all(
    boxes?.map(async (b) => {
      if (b.type !== ItemTypes.TABLE || b.content?.data?.length !== 0) return b;

      let newBox = { ...b };
      await fetchTableData({
        fetchStoreFunc: async (tableName) => {
          const table = await getTable({ id: proposable_id, type }, tableName);
          return table;
        },
        updateBoxFunc: (id, callback) => {
          newBox = callback(newBox);
        },
        box: newBox,
        isNewBox: true,
      });
      return newBox;
    })
  );
