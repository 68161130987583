import React from 'react'
import { variableStyle } from '../../utils/styles'
import VariableGroupLeftForm from './VariableGroupLeftForm'
import VariableGroupChildren from './VariableGroupChildren'

const VariableGroup = ({ box, validateFormAndRetrieveValues, form, variablesGroupedVariables }) => {
  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableGroupLeftForm box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} />
      </div>
      <div className='variable-editor__middle' style={variableStyle["middle"]}>
        <VariableGroupChildren box={box} variablesGroupedVariables={variablesGroupedVariables} />
      </div>
      <div style={variableStyle["right"]}>
      </div>
    </>
  )
}

export default VariableGroup
