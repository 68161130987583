import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { variableStyle } from '../../utils/styles'
import TableWithDrag from '../common/TableWithDrag'
import InputHideable from '../common/InputHideable'
import { ItemTypes, antdFormError, tableColor, tableHeaderSecondaryBgColor, variablesIcons } from '../../constants/constants'
import { useStore } from '../../store/store'
import ButtonCommon from '../common/ButtonCommon'
import { convertContentToRawText } from '../RichEditor/utils'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useTranslation } from 'react-i18next'
import { faTrashXmark } from '@fortawesome/pro-duotone-svg-icons'
import { Modal } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'

const { confirm } = Modal;

const sortAndFormatVariables = (variablesGroupedVariables) => {
  return variablesGroupedVariables.filter((vInfos) => vInfos.variableGroupBox && vInfos.variablesBoxes.some((b) => !b.notUsed)).map(({ variableGroupBox }) => variableGroupBox)
}

const VariableGroupTable = ({
  formInfos,
  setFormInfos,
  variablesGroupedVariables
}) => {
  const [searchValue, setSearchValue] = useState("");
  const [dataSource, setDataSource] = useState(sortAndFormatVariables(variablesGroupedVariables));
  const reorderVariables = useStore(({ reorderVariables }) => reorderVariables)
  const removeVariableGroup = useStore(({ removeVariableGroup }) => removeVariableGroup)
  const { t } = useTranslation()

  useEffect(() => {
    const searchCaseDiatricsInsensitive = (search, text) =>
      text
        ?.toLowerCase()
        .normalize("NFD")
        .replace(/\p{Diacritic}/gu, "")
        .includes(
          search
            .toLowerCase()
            .normalize("NFD")
            .replace(/\p{Diacritic}/gu, "")
        );
    const newData = sortAndFormatVariables(variablesGroupedVariables)
    if (searchValue) {
      setDataSource(newData
        .filter((record) => searchCaseDiatricsInsensitive(searchValue, typeof record.name === "string" ? record.name : convertContentToRawText(record.name)))
        .map((record) => ({
          ...record,
          canDrag: false,
          dragIconTitle: t('unmovable-search-active')
        })))
    } else {
      setDataSource(newData)
    }
  }, [searchValue, variablesGroupedVariables, t])

  const onClick = useCallback(() => {
    setFormInfos({ item: null, isOpen: true })
  }, [setFormInfos]);

  const onDrop = useCallback(() => {
    reorderVariables(dataSource)
  }, [dataSource, reorderVariables])

  const columns = useMemo(() => {
    return [
      {
        title: <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          gap: 16
        }}>
          {t('name')}
          <InputHideable
            setValue={setSearchValue}
            value={searchValue}
          />
        </div>,
        dataIndex: 'name',
        render: (name, record) => <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: 6,
        }}>
          <FontAwesomeIcon icon={variablesIcons[record.type]} />
          {typeof name === "string" ? name : convertContentToRawText(name)}
        </div>
        ,
        onHeaderCell: () => ({
          style: {
            backgroundColor: tableHeaderSecondaryBgColor,
            color: tableColor
          },
        }),
        onCell: (record) => ({
          onClick: () => setFormInfos({
            isOpen: true,
            item: record
          }),
          style: {
            cursor: 'pointer'
          }
        })
      },
      // {
      //   title: <></>,
      //   dataIndex: 'delete',
      //   width: 30,
      //   render: (_, record) => record.type === ItemTypes.VARIABLE_GROUP ? <div style={{
      //     display: 'flex',
      //     alignItems: 'center',
      //     justifyContent: 'center'
      //   }}
      //     onClick={() => {
      //       confirm({
      //         title: t('delete-variable-group', { name: convertContentToRawText(record.name) }),
      //         icon: <ExclamationCircleFilled />,
      //         okText: t('delete'),
      //         okType: "danger",
      //         cancelText: t('cancel'),
      //         onOk() {
      //           setFormInfos((old) => {
      //             if (old.item?.id === record.id)
      //               return {
      //                 isOpen: false,
      //                 item: null
      //               }
      //             return old;
      //           })
      //           removeVariableGroup(record.id)
      //           return false;
      //         },
      //         onCancel() {
      //           return false;
      //         },
      //       });
      //     }}
      //   >
      //     <FontAwesomeIcon className='scale-on-hover' icon={faTrashXmark} style={{ color: antdFormError }} />
      //   </div> : null
      //   ,
      //   onHeaderCell: () => ({
      //     style: {
      //       backgroundColor: tableHeaderSecondaryBgColor,
      //       color: tableColor
      //     },
      //   }),
      //   onCell: (record) => ({
      //     onClick: () => setFormInfos({
      //       isOpen: true,
      //       item: record
      //     }),
      //     style: {
      //       cursor: 'pointer'
      //     }
      //   })
      // }
    ]
  }, [searchValue, setFormInfos, t]);

  return (
    <div style={variableStyle["left"]}>
      <ButtonCommon
        type="add"
        style={{
          marginBottom: 16,
          marginLeft: 'auto'
        }}
        disabled={formInfos.isOpen && !formInfos.item}
        onClick={onClick}
      >
        {t('variables-group')}
      </ButtonCommon>
      <TableWithDrag
        columns={columns}
        dataSource={dataSource}
        setDataSource={setDataSource}
        backgroundColor={tableHeaderSecondaryBgColor}
        color={tableColor}
        onDrop={onDrop}
        scroll={{ y: 'calc(100vh - 54px - 16px - 32px - 16px - 21px - 16px - 21px - 8px - 3px - 16px)' }}
      />
    </div>
  )
}

export default VariableGroupTable
