import React from 'react';
import _ from 'lodash';

// ANT Design
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { PRIMARY_DARK } from '../../constants/constants';
import { navbarHeight } from '../../constants/gridConfig';
import { useOverlay } from '../../contexts/OverlayContext';

const OverlaySider = ({
  side = 'left',
  className,
  width = 220,
  children,
  zIndex = 15,
  onCollapse
}) => {
  const { overlayProps, handleOverlayCollapse } = useOverlay();
  const collapsed = overlayProps[side].collapsed

  const radiusSide = side === 'left' ? 'borderBottomRightRadius' : 'borderBottomLeftRadius';

  const handleSliderStatusChange = () => {
    const newCollapsed = !collapsed;
    handleOverlayCollapse(side, newCollapsed)
    if (typeof onCollapse === "function") onCollapse(newCollapsed)
  }

  return (
    <>
      <div
        style={{
          position: 'fixed',
          top: 0,
          [`${side}`]: 0,
          [`margin${_.capitalize(side)}`]: collapsed ? - width : 0,
          minHeight: `calc(100vh - ${navbarHeight}px)`,
          marginTop: navbarHeight,
          backgroundColor: PRIMARY_DARK,
          transition: 'margin 0.3s',
          zIndex,
          width,
        }}
      >
        {children}
      </div>
      <div
        style={{
          position: 'fixed',
          top: navbarHeight,
          color: 'white',
          backgroundColor: PRIMARY_DARK,
          padding: '20px 10px',
          borderRadius: 0,
          [radiusSide]: 10,
          [side]: 0,
          // [`margin${_.capitalize(side)}`]: width,
          [`margin${_.capitalize(side)}`]: collapsed ? 0 : width,
          transition: 'margin 0.3s',
          zIndex: zIndex - 1,
          ...(side === 'left' ? { borderRight: '1px solid white' } :
            { borderLeft: '1px solid white' }),
          borderBottom: '1px solid white'
        }}
        onClick={handleSliderStatusChange}
      >
        <div
          style={{
            transform: `rotate(${collapsed ? 0 : 180}deg)`,
            transition: 'transform 0.3s',
          }}
        >
          {side === 'left' ? <CaretRightOutlined /> : <CaretLeftOutlined />}
        </div>
      </div>

    </>
  );
};

export default React.memo(OverlaySider);
