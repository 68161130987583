import React, { useCallback, useRef, useState } from 'react'
import { gridUnit, masksDefaultHeight, pageHeight, pageWidth } from '../../constants/gridConfig'
import { useStore } from '../../store/store';
import './Container.less'
import { borderColumnColor, containerBackground, minContainerHeight, pageBackground, roundButtonWidth } from '../../constants/constants';
import DeleteContainerButton from './DeleteContainerButton';
import RearangeColumnsButton from './RearangeColumnsButton';
import { useOutsideAlerter } from '../../hooks';
import ColumnWrapper from '../Column/ColumnWrapper';
import EditableContainerButton from './EditableContainerButton';

const selector = (id, fromViewer) => ({
  configuration,
  containers,
  columns,
  maskEditing,
  masks,
  boxes,
  landscape,
  fromPdf,
  selectedContainer,
  handleSelectContainer,
  boxVariableSelectedId
}) => {
  const container = containers.find((c) => c.id === id);
  const containerIndex = containers.findIndex((c) => c.id === id);
  const mask = !container && (fromViewer || fromPdf || (maskEditing.length > 0
    && maskEditing.includes(id)))
    && masks.find((m) => m.id === id);
  let height;
  if (mask) {
    if (masksDefaultHeight.hasOwnProperty(mask.id)) {
      height = mask.height ?? masksDefaultHeight[mask.id]
    } else {
      height = landscape ? pageWidth : pageHeight
    }
  }
  else height = container.height;
  return {
    height,
    mask,
    columnsIds: container?.columnsIds || [],
    marginLeft: configuration.margins.left * gridUnit,
    marginRight: configuration.margins.right * gridUnit,
    drawMode: mask || columns.some((c) => container.columnsIds.includes(c.id) && c.drawMode),
    containerIndex,
    containerLength: containers.length,
    hasBox: !mask && boxes.some((b) => container.columnsIds.includes(b.columnId)),
    landscape,
    fontFamily: configuration.fontFamily,
    fontSize: configuration.fontSize,
    selectedContainer,
    handleSelectContainer,
    container: container,
    fromPdf,
    boxVariableSelectedId
  }
};

const Container = React.memo(({ id, fromViewer }) => {
  const {
    columnsIds,
    marginLeft,
    marginRight,
    drawMode,
    height,
    mask,
    containerIndex,
    containerLength,
    hasBox,
    landscape,
    fontFamily,
    fontSize,
    selectedContainer,
    handleSelectContainer,
    container,
    fromPdf,
    boxVariableSelectedId
  } = useStore(selector(id, fromViewer));
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [isResizingContainer, setIsResizingContainer] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setMenuIsOpen);

  const renderContent = () => {
    if (mask) return <ColumnWrapper
      id={mask.id}
      isContainerHovered={false}
      setIsResizingContainer={setIsResizingContainer}
      fromViewer={fromViewer}
    />
    return columnsIds.map((colId) => (
      <ColumnWrapper
        data-parent={containerBackground}
        id={colId}
        key={colId}
        isContainerHovered={isHovered}
        containerColumnsLength={columnsIds.length}
        setIsResizingContainer={setIsResizingContainer}
        fromViewer={fromViewer}
      />
    ))
  }

  const stylecontainerSelected = useCallback(() => {
    if (fromPdf || fromViewer) {
      return {}
    }

    if (container?.sectionId && selectedContainer?.sectionId) {
      if (selectedContainer?.id === id) {
        return {
          outline: `2px #d7d9db ${drawMode ? "dotted" : "dashed"}`
        }
      }
      if (container.sectionId === selectedContainer.sectionId && container.dateTimestamp === selectedContainer.dateTimestamp) {
        return {
          outline: `2px #fce8d4 ${drawMode ? "dotted" : "dashed"}`
        }
      }
    }
    if (drawMode && selectedContainer?.id !== id) {
      return {
        outline: `2px ${borderColumnColor} dotted`
      }
    }
    if (selectedContainer?.id === id || drawMode) {
      return {
        outline: `2px #d7d9db ${drawMode ? "dotted" : "dashed"}`
      }
    }
  }, [selectedContainer, id, container, drawMode, fromPdf, fromViewer])

  const hasContainerOptions = (menuIsOpen || (!mask && isHovered)) && !boxVariableSelectedId

  const containerExtraWidth = hasContainerOptions ? roundButtonWidth + 16 : 0

  return <div id={id}
    className='container'
    onMouseOver={() => setIsHovered(true)}
    onMouseLeave={() => setIsHovered(false)}
    data-role={pageBackground}
    data-parent={containerBackground}
    style={{
      width: (landscape ? pageHeight : pageWidth) + containerExtraWidth,
      minHeight: hasBox || isResizingContainer ? "auto" : minContainerHeight,
      height: isResizingContainer ? "auto" : height,
      position: 'relative',
      zIndex: containerLength - containerIndex,
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
    }}>
    <div
      data-role={pageBackground}
      data-parent={containerBackground}
      onClick={() => handleSelectContainer(id)}
      style={{
        width: (landscape ? pageHeight : pageWidth),
        minHeight: "inherit",
        height: "100%",
        display: "flex",
        paddingLeft: drawMode ? 0 : marginLeft,
        paddingRight: drawMode ? 0 : marginRight,
        columnGap: 4,
        ...(stylecontainerSelected())
      }}>
      {renderContent()}
    </div>
    {hasContainerOptions &&
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        top: 0,
        right: 0,
        gap: 8
      }}
        ref={wrapperRef}>
        <RearangeColumnsButton id={id} menuIsOpen={menuIsOpen} setMenuIsOpen={setMenuIsOpen} />
        <DeleteContainerButton id={id} />
        <EditableContainerButton id={id} />
      </div>
    }
  </div>
})

export default Container
