import React, { useEffect, useRef } from 'react'

const MenuLine = ({
  customClassName = '',
  optionKey,
  optionName,
  selectedOptionKey,
  onSelect,
  setSelectedOptionKey,
  customButtonStyle
}) => {
  const lineRef = useRef();

  useEffect(() => {
    if (selectedOptionKey === optionKey) {
      lineRef.current.focus()
      lineRef.current.scrollIntoView({
        behavior: 'auto',
        block: 'nearest',
      })
    }
  }, [selectedOptionKey, optionKey]);

  const handleOnClick = (event) => {
    onSelect(event, optionKey)
  }

  return (
    <li style={{
      display: "flex",
      alignItems: "center",
      listStyleType: "none",
      borderRadius: 8,
      position: 'relative',
      ...(optionKey === selectedOptionKey ? {
        backgroundColor: 'rgba(0, 0, 0, 0.06)'
      } : {})
    }}
      className={`variable-selection-line-wrapper ${customClassName}`}
      ref={lineRef}
      onMouseMove={() => {
        setSelectedOptionKey(optionKey)
      }}
    >
      <button
        className='variable-selection-line'
        onClick={handleOnClick}
        style={{
          width: '100%',
          border: 'none',
          backgroundColor: 'transparent',
          display: 'flex',
          height: '100%',
          padding: 8,
          cursor: 'pointer',
          alignItems: 'center',
          ...customButtonStyle
        }}
      >
        {optionName}
      </button>
    </li>
  )
}

export default MenuLine;
