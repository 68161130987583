import React from 'react'
import VariableName from './VariableName'
import { variableStyle } from '../../utils/styles'
import { Checkbox, Form, Input } from 'antd'
import { useStore } from '../../store/store';
import VariableRemark from './VariableRemark';

const selector = (id) =>
  ({ updateBox, selectedBoxId, configuration }) => {
    return {
      isSelected: selectedBoxId === id,
      updateBox,
      configuration
    };
  };

const VariableText = ({ box, onBlur, form }) => {

  const { updateBox } = useStore(selector(box.id));
  const handleChecked = () => {
    updateBox(box.id, (box) => {
      box.value_required = !box.value_required
    })
  }

  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableName box={box} onBlur={onBlur} form={form} />
        <VariableRemark box={box} onBlur={onBlur} form={form} />
      </div>
      <div style={variableStyle["middle"]}>


        <Form.Item
          label="Valeur requise"
          valuePropName="checked"
          rules={[{ required: true }]}
        >
          <Checkbox defaultChecked={box.value_required} onClick={() => handleChecked()} />
        </Form.Item>

      </div>


      <div style={variableStyle["right"]}>

      </div>
    </>
  )
}

export default VariableText
