import { useStore } from '../../store/store';
import ModalImage from 'react-modal-image';
import { Button, Input, Modal, Popconfirm, Table, Tooltip } from 'antd';
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  FileOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { useState } from 'react';
import { emptyUrl, updateUrl } from '../../utils/browser';
import { useTranslation } from 'react-i18next';
import TemplateList from './TemplateList';
import {
  createProposal,
  deleteProposal,
  duplicateProposal,
  renameProposal,
} from './requests';
import { fetchProposal } from '../../requests/proposals';
import { useModalOpener, useQueryDocumentTypes } from '../../hooks';
import { textToRaw, } from '../../utils/dataSync';

const { Search } = Input;

const selector = (state) => ({
  handleProposalList: state.handleProposalList,
  currentDocument: state.currentDocument,
  proposals: state.proposals,
  applyProposal: state.applyProposal,
  setProposalId: state.setProposalId,
  applyTemplate: state.applyTemplate,
  user: state.user,
  getTemplate: state.getTemplate,
  fetchTables: state.fetchTables,
  defaultVariablesApi: state.defaultVariablesApi,
  deleteProposalStore: state.deleteProposalStore,
  createProposalStore: state.createProposalStore,
  renameProposalStore: state.renameProposalStore,
  duplicateProposalStore: state.duplicateProposalStore,
});

export const ProposalList = () => {
  const {
    currentDocument,
    proposals,
    getTemplate,
    handleProposalList,
    applyProposal,
    setProposalId,
    deleteProposalStore,
    createProposalStore,
    renameProposalStore,
    duplicateProposalStore,
    defaultVariablesApi
  } = useStore(selector);
  const { t } = useTranslation();
  const [nameSearch, setNameSearch] = useState('');
  const { data: documentTypes } = useQueryDocumentTypes()
  const cols = [
    {
      title: 'Nom',
      key: 'name',
      //editable: true,
      align: 'center',
      className: 'nameColumn',
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
        },
      }),
      onCell: (record) => {
        return {
          style: {
            wordWrap: 'anywhere',
            wordBreak: 'normal',
          },
          onClick: async () => {
            if (currentDocument.type === 'opportunity') {
              emptyUrl();
              updateUrl({ oid: currentDocument.id, pid: record.id });
              setProposalId(record.id);
              Promise.resolve(
                fetchProposal({ id: record.id, type: currentDocument.type }, defaultVariablesApi)
              ).then((fetchedProposal) => {
                applyProposal(fetchedProposal, currentDocument.type);
              });
            } else if (currentDocument.type === 'offer') {
              emptyUrl();
              updateUrl({ ofid: currentDocument.id, pid: record.id });
              setProposalId(record.id);
              Promise.resolve(
                fetchProposal({ id: record.id, type: currentDocument.type }, defaultVariablesApi)
              ).then((fetchedProposal) => {
                applyProposal(fetchedProposal, currentDocument.type);
              });
            }
            handleProposalList(false);
          },
        };
      },
      filteredValue: [nameSearch],
      onFilter: (value, record) => {
        return record.name
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
          .includes(
            value
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase()
          );
      },
      render: (record) => <div>{record.name}</div>,
      width: 300,
    },
  ];
  cols.push({
    title: 'Format',
    key: 'format',
    align: 'center',
    onHeaderCell: () => ({
      style: {
        backgroundColor: '#0B2239',
        color: 'white',
      },
    }),
    render: (record) => (
      <p style={{ textAlign: 'center', margin: 0 }}>
        {record.landscape ? (
          <Tooltip title='Format paysage'>
            <FileOutlined
              style={{
                transform: 'rotate(-90deg)',
                fontSize: 18,
                margin: 0,
                marginRight: 8,
              }}
            />
          </Tooltip>
        ) : (
          <Tooltip title='Format portrait'>
            <FileOutlined
              style={{
                transform: 'scaleX(-1)',
                fontSize: 18,
                margin: 0,
                marginRight: 8,
              }}
            />
          </Tooltip>
        )}
      </p>
    ),
  });
  // cols.push({
  //   title: 'Aperçu',
  //   key: 'preview',
  //   align: 'center',
  //   width: 300,
  //   onHeaderCell: () => ({
  //     style: {
  //       backgroundColor: '#0B2239',
  //       color: 'white',
  //     },
  //   }),
  //   render: (record) => (
  //     <p style={{ textAlign: 'center', margin: 0 }}>
  //       {record.preview ? (
  //         <center>
  //           <div style={{ width: '40%' }}>
  //             <ModalImage
  //               small={record.preview}
  //               large={record.preview}
  //               alt='Aperçu'
  //             />
  //           </div>
  //         </center>
  //       ) : (
  //         'Aperçu non disponible'
  //       )}
  //     </p>
  //   ),
  // });
  cols.push({
    title: 'Tags',
    key: 'tags',
    align: 'center',
    width: 100,
    onHeaderCell: () => ({
      style: {
        backgroundColor: '#0B2239',
        color: 'white',
      },
    }),
    render: (record) => (
      <p style={{ textAlign: 'center', margin: 0 }}>
        {documentTypes?.filter((dT) => record.document_type_ids.includes(dT.id))
          .map((dT) => dT.name).join(", ")}
      </p>
    ),
  });
  cols.push({
    title: 'Actions',
    key: 'action',
    align: 'center',
    render: (text, record) => (
      <div
        style={{
          display: 'flex',
          fontSize: '1vw',
          justifyContent: 'space-evenly',
        }}
      >
        <Tooltip title='Supprimer'>
          <Popconfirm
            title={'Supprimer ce document ?'}
            onConfirm={() => {
              deleteProposal(record.id).then(() => {
                deleteProposalStore(record.id);
              });
            }}
            okText={t('yes')}
            cancelText={t('cancel')}
          >
            <DeleteOutlined style={{ color: 'red' }} />
          </Popconfirm>
        </Tooltip>
        <Tooltip title='Renommer'>
          <EditOutlined
            onClick={() => {
              showModal('renameProposal', record);
              setEditNewName(record.name);
            }}
          />
        </Tooltip>
        <Tooltip title='Dupliquer'>
          <div style={{ transform: 'rotate(180deg)' }}>
            <CopyOutlined
              style={{ transform: 'scaleX(-1)' }}
              onClick={() => showModal('duplicateProposal', record)}
            />
          </div>
        </Tooltip>
      </div>
    ),
    onHeaderCell: () => ({
      style: {
        backgroundColor: '#0B2239',
        color: 'white',
      },
    }),
    width: 150,
  });

  const columns = cols.map((c) => {
    if (!c.editable) return c;
    return {
      ...c,
      onCell: (record) => ({
        record,
        editable: c.editable,
        dataIndex: c.dataIndex,
        title: c.title,
      }),
      onHeaderCell: () => ({
        style: {
          backgroundColor: '#0B2239',
          color: 'white',
        },
      }),
    };
  });
  const [isOpen, setIsOpen] = useModalOpener(false);
  const [modalSelection, setModalSelection] = useState('');
  const [selectedProposal, setSelectedProposal] = useState([]);

  const showModal = (origin, proposal) => {
    setIsOpen(true);
    setModalSelection(origin);
    setSelectedProposal(proposal)
    // setIsModalOpen({
    //   isOpen: true,
    //   modalSelection: origin,
    //   selectedProposal,
    // });
  };

  const [editNewName, setEditNewName] = useState('');
  const [duplicateNewName, setDuplicateNewName] = useState('');
  const [proposalName, setProposalName] = useState('');
  const [newProposalTemplateId, setNewProposalTemplateId] = useState();

  const handleOk = (e) => {
    if (modalSelection === 'renameProposal') {
      renameProposal(selectedProposal.id, editNewName).then((e) => {
        renameProposalStore(e);
      });
    } else if (modalSelection === 'createProposal') {
      let proposalContent = {};
      Promise.resolve(getTemplate(newProposalTemplateId)).then(
        async (fetchedTemplate) => {
          proposalContent.content = {
            ...fetchedTemplate.content,
            boxes: textToRaw(fetchedTemplate.content.boxes),
          };
          proposalContent.landscape = fetchedTemplate.landscape;
          proposalContent.work = null;
          proposalContent.name = proposalName.name;
          proposalContent.document_type_ids = fetchedTemplate.document_type_ids;
          createProposal(
            currentDocument.id,
            proposalContent,
            currentDocument.type
          ).then((e) => {
            createProposalStore(e);
          });
        }
      );
    } else if (modalSelection === 'duplicateProposal') {
      duplicateProposal(selectedProposal.id, duplicateNewName, selectedProposal.name).then(
        (e) => {
          duplicateProposalStore(e, selectedProposal.document_type_ids);
        }
      );
    }
    setIsOpen(false)
    // setIsModalOpen({ ...isModalOpen, isOpen: false });
  };

  const handleCancel = () => {
    setIsOpen(false);
    // setIsModalOpen({ ...isModalOpen, isOpen: false });
    setEditNewName('');
    setDuplicateNewName('');
  };

  const handleTemplateSelection = (templateName) => {
    // Vérifier si le champ createNameInput est vide
    if (!proposalName.name) {
      // Mettre à jour le champ createNameInput avec le nom du modèle sélectionné
      setProposalName({
        ...proposalName,
        name: templateName,
      });
    }
  };

  const [isCreateButtonHovered, setIsCreateButtonHovered] = useState(false);
  return (
    <div name='mainDiv'>
      <div style={{ fontSize: '3vw', textAlign: 'center', marginBottom: '5%' }}>
        Liste des documents
      </div>
      {proposals?.length !== 0 ? (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              ghost
              size='large'
              icon={<PlusCircleOutlined />}
              onClick={() => showModal('createProposal')}
              onMouseEnter={() => {
                setIsCreateButtonHovered(true);
              }}
              onMouseLeave={() => {
                setIsCreateButtonHovered(false);
              }}
              style={{
                backgroundColor: isCreateButtonHovered ? '#2166ab' : 'white',
                color: isCreateButtonHovered ? 'white' : '#2166ab',
                marginBottom: '3%',
                borderColor: '#2166ab',
                fontSize: '0.9vw',
                // fontWeight: 'bold',
                borderRadius: '30px',
                border: '1.5px solid ',
              }}
            >
              Créer un nouveau document
            </Button>
          </div>
          <div
            style={{
              display: 'flex',
              width: '70%',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '1%',
            }}
          >
            <div style={{ width: '40%' }}>Recherche par nom:</div>
            <Search
              key='nameSearch'
              placeholder='Rechercher un document par nom'
              value={nameSearch}
              style={{
                width: '60%',
                marginBottom: '2%',
                marginTop: '2%',
                marginLeft: '2%',
              }}
              onChange={(e) => {
                setNameSearch(e.target.value);
              }}
            />
          </div>
          <Table
            pagination={false}
            columns={columns}
            dataSource={proposals}
            bordered
          />
        </div>
      ) : (
        <div style={{ textAlign: 'center' }}>
          <div style={{ fontSize: '1.5vw' }}>Aucun document disponible</div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Button
              ghost
              size='large'
              icon={<PlusCircleOutlined />}
              onClick={() => showModal('createProposal')}
              onMouseEnter={() => {
                setIsCreateButtonHovered(true);
              }}
              onMouseLeave={() => {
                setIsCreateButtonHovered(false);
              }}
              style={{
                backgroundColor: isCreateButtonHovered ? '#2166ab' : 'white',
                color: isCreateButtonHovered ? 'white' : '#2166ab',
                marginBottom: '3%',
                borderColor: '#2166ab',
                fontSize: '0.9vw',
                // fontWeight: 'bold',
                borderRadius: '30px',
                border: '1.5px solid ',
              }}
            >
              Créer un nouveau document
            </Button>
          </div>
        </div>
      )}
      <Modal
        title={
          modalSelection === 'renameProposal'
            ? 'Edition du document'
            : modalSelection === 'createProposal'
              ? 'Creation du document'
              : modalSelection === 'duplicateProposal'
                ? 'Duplication du document'
                : null
        }
        open={isOpen}
        onOk={(e) => {
          if (
            proposalName !== '' ||
            editNewName !== '' ||
            duplicateNewName !== ''
          ) {
            handleOk(e);
          } else {
            alert('Le nom du document ne peut pas être vide');
          }
        }}
        onCancel={handleCancel}
        width='50%'
        cancelText={t('cancel')}
      >
        {modalSelection === 'renameProposal' ? (
          <div name='RenameProposalComponent-MainDiv'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '30%' }}>Nouveau nom:</div>proposal
              <Input
                key='renameInput'
                placeholder='Nouveau nom'
                value={editNewName}
                size='middle'
                onChange={(e) => {
                  setEditNewName(e.target.value);
                }}
              />
            </div>
          </div>
        ) : modalSelection === 'createProposal' ? (
          // CREATE PROPOSAL MODAL
          <div name='proposalNameComponent-MainDiv'>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div style={{ width: '30%' }}>Nom du nouveau document:</div>
              <Input
                key='createNameInput'
                placeholder='Nom du nouveau document'
                value={proposalName.name}
                size='middle'
                onChange={(e) => {
                  setProposalName({
                    ...proposalName,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div
              style={{ textAlign: 'center', fontSize: '1vw', marginTop: '3%' }}
            >
              Choisissez un modèle
            </div>

            <TemplateList
              onTemplateClick={(e, name) => {
                setNewProposalTemplateId(e);
                handleTemplateSelection(name);
              }}
            />
          </div>
        ) : modalSelection === 'duplicateProposal' ? (
          <div name='DuplicateProposalComponent-MainDiv'>
            <div style={{ marginBottom: '3%', display: 'flex' }}>
              Duplication du document:
              <div style={{ fontWeight: 'bold', marginLeft: '1%' }}>
                {selectedProposal.name}
              </div>
            </div>
            <div>Nom du template dupliqué:</div>
            <Input
              key='duplicateInput'
              placeholder='Nouveau nom du modèle dupliqué'
              value={duplicateNewName}
              size='middle'
              onChange={(e) => {
                setDuplicateNewName(e.target.value);
              }}
            />
          </div>
        ) : null}
      </Modal>
    </div>
  );
};
