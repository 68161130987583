import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { duotone, icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from 'antd';
// import Select from 'rc-select';
import { useTranslation } from 'react-i18next';
import { iconButtonStyle, navbarButtonStyle } from '../../utils/styles'
import { useStore } from '../../store/store';
import { Checkable } from '../common/Checkable';
import {
  handleBorderChangement,
  onChangeBorderColor,
  hasAllBordersOn,
  getBoxBorder,
  getBoxBorderColor
} from '../RichEditor/utils';
import { NavbarListButton } from './NavbarListButton';
import { useCallback } from 'react';
import { isAVariableBox } from '../../utils/boxes';
import { ItemTypes } from '../../constants/constants';

const selector = ({ updateBox, configuration, selectedBoxId, boxes, masks, containers, currentProposal }) => {
  const box = [
    ...boxes,
    ...masks.map((m) => m.boxes).flat(),
  ].find((box) => selectedBoxId && box.id === selectedBoxId)
  const container = containers.find(container => container.columnsIds.includes(box?.columnId))

  return {
    updateBox,
    configuration,
    selectedBox: box,
    container: container,
    currentProposal,
  }
};

const borderPositionButtons = (box) => {
  return [
    {
      key: "borderAll",
      icon: < FontAwesomeIcon icon={icon({ name: 'border-outer', style: 'light' })} style={iconButtonStyle()} />,
      sides: ['left', 'top', 'right', 'bottom'],
      active: hasAllBordersOn(box)
    },
    {
      key: "borderLeft",
      icon: < FontAwesomeIcon icon={icon({ name: 'border-left', style: 'light' })} style={iconButtonStyle()} />,
      sides: ['left'],
      active: getBoxBorder(box, "left")
    },
    {
      key: "borderTop",
      icon: < FontAwesomeIcon icon={icon({ name: 'border-top', style: 'light' })} style={iconButtonStyle()} />,
      sides: ['top'],
      active: getBoxBorder(box, "top")
    },
    {
      key: "borderRight",
      icon: < FontAwesomeIcon icon={icon({ name: 'border-right', style: 'light' })} style={iconButtonStyle()} />,
      sides: ['right'],
      active: getBoxBorder(box, "right")
    },
    {
      key: "borderBottom",
      icon: < FontAwesomeIcon icon={icon({ name: 'border-bottom', style: 'light' })} style={iconButtonStyle()} />,
      sides: ['bottom'],
      active: getBoxBorder(box, "bottom")
    },
  ]
};

export const BorderManager = () => {
  const { t } = useTranslation();
  const { updateBox, configuration, selectedBox, container, currentProposal } = useStore(selector);

  const borderColorDisabled =
    selectedBox &&
    [
      'title1',
      'title2',
      'title3',
      'title4',
      'summary',
      'table',
      'line',
      ItemTypes.TABLE_VARIABLE
    ].includes(selectedBox.type);
  // const borderColorsEnabled = true;
  const selectedBoxIsShape =
    selectedBox?.type === 'shape' || selectedBox?.type === 'line';

  const toggleBorderColor = (newColor) => {
    if (selectedBoxIsShape) {
      updateBox(selectedBox.id, (selectedBox) => {
        selectedBox.borders.color = configuration.colors.find(
          (color) => color.label === newColor
        );
      });
    } else {
      onChangeBorderColor(
        selectedBox.id,
        updateBox,
        configuration
      )(newColor);
    }
  }

  const unCommonBox =
    !selectedBox ||
    ['title1', 'title2', 'title3', 'title4', 'summary', 'table', 'line_break'].includes(selectedBox?.type) ||
    isAVariableBox(selectedBox?.type)

  const backgroundManagerDisabled = useCallback(() => {
    if (!container?.editable && container?.hasOwnProperty('editable') && currentProposal.id !== null) {
      return true
    } else return unCommonBox || borderColorDisabled
  }, [container, unCommonBox, borderColorDisabled])

  const borderManagerDisabled = useCallback(() => {
    if (!container?.editable && container?.hasOwnProperty('editable') && currentProposal.id !== null) {
      return true
    } else return unCommonBox || selectedBoxIsShape
  }, [container, unCommonBox, selectedBoxIsShape])

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarListButton
        key="borderPositionList"
        title={t('border')}
        disabled={borderManagerDisabled()}
        icon={< FontAwesomeIcon icon={icon({ name: 'border-bottom', style: 'light' })} style={iconButtonStyle()} />}
        selectedElement={selectedBox?.border?.color.label}
      >
        {selectedBox && !selectedBoxIsShape && borderPositionButtons(selectedBox).map((button) => {
          return (
            <Checkable key={`position${button.sides.join('')}`} checked={button.active}>
              <Button
                style={{
                  ...navbarButtonStyle,
                  marginBottom: '1px',
                }}
                icon={button.icon}
                onMouseDown={() => handleBorderChangement(selectedBox.id, updateBox, button.sides)}
              />
            </Checkable>
          )
        })}
      </NavbarListButton>
      {/* BORDER COLORS */}
      <NavbarListButton
        key="borderColorList"
        title={t("border's-color")}
        disabled={backgroundManagerDisabled()}
        icon={
          <FontAwesomeIcon
            icon={duotone("border-outer")}
            style={{
              "--fa-primary-color": "#f63c25",
              "--fa-secondary-color": "#311f51",
              fontSize: 20,
              margin: 0,
            }}
          />}
        onListElementClick={toggleBorderColor}
        listElements={configuration.colors.filter((c) => c.label !== 'transparent')}
        selectedElement={selectedBox && getBoxBorderColor(selectedBox)}
      />
    </div>
  );
};
