import React, { useState } from 'react'
import { useStore } from '../../store/store';
import { Button, Modal, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { pageBackground, roundButtonWidth } from '../../constants/constants';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

const { confirm } = Modal;

const showConfirm = ({ deleteContainer, deleteSectionContainers,
  deleteAllContainerFromPdfToImageWithSameKey, t, fromSection, KeyPdfToImage }) => {
  console.log(KeyPdfToImage);
  const flexDirectionButton = (fromSection || KeyPdfToImage) ? "column" : "row"
  const modal = confirm({
    title: t('container-deleteWarning'),
    icon: <ExclamationCircleFilled />,
    cancelText: t('cancel'),
    okText: t('delete'),
    okType: 'danger',
    onOk() {
      deleteContainer();
    },
    onCancel() {
      // Handle onCancel logic if needed
    },
    footer: (
      <div style={{ display: "flex", flexDirection: flexDirectionButton, justifyContent: "end", gap: 10 }}>
        <Button onClick={() => modal.destroy()}>{t('cancel')}</Button>
        {fromSection && <Button danger onClick={async () => {
          await deleteSectionContainers()
          modal.destroy()
        }
        }>
          {t("section-delete-all-container")}
        </Button>}
        {KeyPdfToImage && <Button danger onClick={async () => {
          await deleteAllContainerFromPdfToImageWithSameKey()
          modal.destroy()
        }
        }>
          {t("pdf-to-image-delete-all-container")}
        </Button>}
        <Button danger onClick={async () => {
          await deleteContainer()
          modal.destroy()
        }
        }>{t('delete')}</Button>
      </div>
    ),
  });
};


const selector = (id) => ({
  containers,
  removeContainer,
  removeSectionContainers,
  boxes,
  maskEditing,
  masks,
  deleteMask
}) => {
  const container = containers.find((c) => c.id === id);
  const mask = !container && maskEditing.length > 0
    && maskEditing.includes(id)
    && masks.find((m) => m.id === id);
  return {
    container: container,
    containers,
    deleteMask,
    removeContainer,
    removeSectionContainers,
    masks,
    hasBox: !mask && boxes.some((b) => container.columnsIds.includes(b.columnId)),
  }
};

const DeleteContainerButton = ({ id }) => {
  const {
    container,
    removeContainer,
    removeSectionContainers,
    hasBox,
    containers,
    masks,
    deleteMask,
  } = useStore(selector(id));
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')

  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const deleteContainer = () => {
    if (hasBox) {
      if (container?.sectionId) {
        showConfirm({
          deleteSectionContainers: () => removeSectionContainers(container?.sectionId, container?.dateTimestamp),
          deleteContainer: () => removeContainer(id),
          t,
          fromSection: true
        })
      }
      else if (container?.KeyPdfToImage) {
        const containerFromSamePdf = containers.filter(c => c.KeyPdfToImage === container.KeyPdfToImage);
        const masksFromSamePdf = masks.filter(m => m.KeyPdfToImage === container.KeyPdfToImage);

        showConfirm({
          deleteAllContainerFromPdfToImageWithSameKey: () => {
            containerFromSamePdf.forEach(c => removeContainer(c.id));
            if (masksFromSamePdf.length > 0) masksFromSamePdf.forEach(m => deleteMask(m.id));
          },
          deleteContainer: () => removeContainer(id),
          t,
          fromSection: false,
          KeyPdfToImage: true
        })
      }
      else {
        showConfirm({ deleteContainer: () => removeContainer(id), t, fromSection: false })
      }
    }
    else { removeContainer(id) }
  }
  if (!isTemplate && !unlockProposal) return null;

  return (
    <Tooltip
      open={isHovered}
      placement='bottom'
      mouseLeaveDelay='0'
      title={t('container-deleteTooltip')}
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Button shape="circle"
          //disabled={disableTrash}
          size={14}
          data-role={pageBackground}
          onClick={deleteContainer}
          style={{
            width: roundButtonWidth,
            height: roundButtonWidth,
            minWidth: roundButtonWidth,
            minHeight: roundButtonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: 'red',
          }}
          icon={<FontAwesomeIcon
            icon={icon({ name: 'trash', style: 'light' })}
            style={{ margin: 0, fontSize: 15 }} />}
        />
      </div>
    </Tooltip>)
}

export default DeleteContainerButton
