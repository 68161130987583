import { EditorState, convertToRaw } from 'draft-js';
import {
  gridWidth,
  gridHeight,
  casesInGrid,
  calculateWidth,
  calculateLeftPadding,
  calculateHeight,
  navbarHeight,
  contentPadding,
  masksDefaultHeight,
  lineSpacing,
} from './gridConfig';
import {
  faInputText,
  faCheckSquare,
  faListDropdown,
  faRectangleHistoryCirclePlus,
  faCheck as faCheckLight,
  faXmark as faXmarkLight,
  faText,
  faImage,
  faTable,
} from '@fortawesome/pro-light-svg-icons';
import {
  BorderBottomOutlined,
  BorderLeftOutlined,
  BorderRightOutlined,
  BorderTopOutlined,
} from '@ant-design/icons';
import {
  faCheck,
  faXmark,
  faSquare as faSquareRound,
} from '@fortawesome/pro-solid-svg-icons';
import { faSquare } from '@fortawesome/sharp-solid-svg-icons';
import { faListTree } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

export const GLOSSARY = {
  REMOVE_BOX: (maskName) => {
    if (maskName) {
      return `Supprimer ce bloc ? Il sera supprimé dans toutes les pages qui utilisent le masque (${maskName}).`;
    } else {
      return 'Supprimer ce bloc ?';
    }
  },
  GRAS: 'Gras',
  ITALIQUE: 'ITALIC',
  SOULIGNE: 'UNDERLINE',
  LISTE_A_PUCE: 'LISTE_A_PUCE',
  LISTE_NUMEROTEE: 'LISTE_NUMEROTEE',
};

export const colId = () => {
  return `col${uuid()}`;
};
export const rowId = () => {
  return `row${uuid()}`;
};

export const uuid = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9);
};

export const ROLES_COLORS = {
  superadmin: 'blue',
  admin: 'red',
  setup: 'orange',
  salesman: 'gold',
  manager: 'gold',
  supervisor: 'gold',
};

// export const CASE = {
//   height: 35.093976378369497,
//   width: 24.8031496063,
// };
// export const styleCheckbox = {
//    validate: "validate-box",
//    cross: "cross-box"
// }
export const styleCheckbox = {
  validate_checkbox: faCheck,
  validate_checkbox_light: faCheckLight,
  cross_checkbox: faXmark,
  cross_checkbox_light: faXmarkLight,
  square_checkbox: faSquare,
  square_checkbox_round: faSquareRound,
};

export const TitlesTypes = {
  TITLE: 'title1',
  TITLE_2: 'title2',
  TITLE_3: 'title3',
  TITLE_4: 'title4',
};

// A déplacer dans I18N
export const TitlesName = {
  title1: 'Titre 1',
  title2: 'Titre 2',
  title3: 'Titre 3',
  title4: 'Titre 4',
};

export const SHAPES = [
  { id: 'square', name: 'Square' },
  { id: 'round', name: 'Circle' },
  { id: 'rectangle', name: 'Rectangle' },
  { id: 'star', name: 'Star' },
  { id: 'ellipse', name: 'Ellipse' },
  { id: 'ring', name: 'Ring' },
  { id: 'triangle', name: 'Triangle' },
  { id: 'hexagone', name: 'Hexagone' },
  // { id: 'diamond', name: 'Diamond' },
  { id: 'arrow', name: 'Arrow' },
];

export const ItemTypes = {
  TEXT: 'text',
  IMG: 'img',
  SHAPE: 'shape',
  LINE: 'line',
  TABLE: 'table',
  FILE_PDF: 'file_pdf',
  ...TitlesTypes,
  SUMMARY: 'summary',
  MASK: 'mask',
  DRAW: 'draw',
  SECTION: 'section',
  LINE_BREAK: 'line_break',
  CHECKBOXS_VARIABLE: 'checkboxs_variable',
  IMG_VARIABLE: 'img_variable',
  TABLE_VARIABLE: 'table_variable',
  TEXT_VARIABLE: 'text_variable',
  SELECTOR_VARIABLE: 'selector_variable',
  SECTION_VARIABLE: 'section_variable',
  CUSTOM_TEXT_VARIABLE: 'custom_text_variable',
  VARIABLE_GROUP: 'variable_group',
};

export const ItemTypesForTraduction = {
  text: 'text',
  img: 'image',
  shape: 'shape',
  line: 'line',
  table: 'table',
  title1: 'title1',
  title2: 'title2',
  title3: 'title3',
  title4: 'title4',
  summary: 'summary',
  line_break: 'line_break',
  checkboxs_variable: 'checkboxs_variable',
  selector_variable: 'selector_variable',
  text_variable: 'text_variable',
  img_variable: 'img_variable',
  table_variable: 'table_variable',
};

//Custom overrides for "code" style.
export const COLOR_STYLE_MAP = {
  white: {
    color: 'rgba(255,255,255, 1.0)',
  },
  black: {
    color: 'rgba(0, 0, 0, 1.0)',
  },
  transparent: {
    color: 'transparent',
  },
};

export const HIGHTLIGHT_COLOR_STYLE_MAP = {
  yellow: {
    color: '#faed27',
  },
  blue: {
    color: 'rgba(40, 143, 227, 0.43)',
  },
  transparent: {
    color: 'transparent',
  },
};

export const COLORS = Object.keys(COLOR_STYLE_MAP).map((k) => ({
  label: k,
  style: COLOR_STYLE_MAP[k].color,
}));

export const hightlightColorList = Object.keys(HIGHTLIGHT_COLOR_STYLE_MAP).map(
  (k) => ({
    label: k,
    style: HIGHTLIGHT_COLOR_STYLE_MAP[k].color,
  })
);

export const defaultTableConfiguration = {
  id: 0,
  name: 'Défaut',
  bordered: false,
  footer: false,
  footerData: '',
  showHeader: true,
  size: 'default',
  stripped1: false,
  strippedCustomClrBool: false,
  strippedCustomClr1: 'white',
  strippedCustomClr2: 'white',
  customTextColorBool: false,
  customTextColor1: 'black',
  customTextColor2: 'black',
  customCellColorBool: false,
  customCellColor: 'white',
  customHeaderColor: '',
  customHeaderTextColor: '',
  customBorderBool: false,
  customBorderWidth: '',
  customBorderType: '',
  customBorderColor: '',
};
export const defaultBorderTextVariable = {
  color: {
    label: 'transparent',
    style: 'rgba(255, 255, 255, 0.0)',
  },
  radius: 0,
  size: 1,
  left: false,
  right: false,
  top: false,
  bottom: false,
};

export const defaultTextConfiguration = {
  id: 0,
  name: 'Défaut',
  positions: { horizontal: 'left', vertical: 'center' },
  zIndex: 0,
  paddinngLeftRight: 0,
  paddingTopBottom: 0,
  fontFamily: 'Montserrat',
  fontSize: 14,
  color: COLORS.find((color) => color.label === 'black'),
  fontWeight: '',
  fontStyle: '',
  textDecoration: '',
  width: gridWidth * 12 * (casesInGrid / 32),
  textStyleId: 0,
  border: defaultBorderTextVariable,
  backgroundColor: COLORS.find((color) => color.label === 'transparent'),
};

export const defaultSelectorConfiguration = {
  id: 0,
  name: 'Défaut',
  positions: { horizontal: 'start', vertical: 'center' },
  zIndex: 0,
  width: gridWidth * 12 * (casesInGrid / 32),
  content: null,
  fontFamily: 'Montserrat',
  height: 'auto',
  fontSize: 14,
  position: 'start',
  separator: '-',
  backgroundColor: COLORS.find((color) => color.label === 'transparent'),
  borderRadius: 0,
  borderColor: COLORS.find((color) => color.label === 'transparent'),
  color: COLORS.find((color) => color.label === 'black'),
  fontWeight: '',
  bordersSize: 1,
  paddingTopBottom: 0,
  paddingLeftRight: 0,
};

export const defaultCheckboxConfiguration = {
  id: 0,
  name: 'Défaut',
  fontWeight: '',
  fontStyle: '',
  textDecoration: '',
  fontSize: 14,
  fontFamily: 'Montserrat',
  styleBox: 'validate_checkbox',
  box: {
    width: 20,
    height: 20,
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
  },
  label: {
    margin: 8,
    direction: 'ltr',
  },
  check: {
    color: COLORS.find((color) => color.label === 'black'),
    width: 18,
    height: 18,
    weight: 400,
  },
  color: COLORS.find((color) => color.label === 'black'),
  backgroundColor: COLORS.find((color) => color.label === 'transparent'),
  borders: {
    radius: 1,
    size: 1,
    color: COLORS.find((color) => color.label === 'black'),
    top: false,
    bottom: false,
    left: false,
    right: false,
  },
  rowGap: lineSpacing,
  columnGap: 8,
  disposition: 2,
};

export const DefaultBorderType = ['dotted', 'dashed', 'solid', 'none'];

export const DefaultBorderTypeTranslation = {
  dotted: 'A pois',
  dashed: 'Pointillés',
  solid: 'Ligne',
  none: 'Aucune',
};

export const defaultConfiguration = {
  fontFamily: 'Montserrat', // should be the key of default font
  fontFamilyList: [{ key: 'Montserrat', familyName: 'Montserrat' }], // List of objects
  fontSize: 14,
  baseFontColor: { label: 'black', style: 'rgba(0,0,0,1)' },
  colors: COLORS,
  highlightColors: hightlightColorList,
  currentHighlightColor: {
    label: 'yellow',
    style: '#faed27',
  },
  margins: {
    left: 8,
    right: 8,
    top: 4,
    bottom: 4,
  },
  customBulletPadding: '0px 0px 0px 0px',
  indentationTitles: 8,
  editableTemplate: true,
  unlockProposal: true,
  separatorTitle: '.',
  shapeColor: COLORS.find((color) => color.label === 'black'),
  hideTitleIndex: false,
  pageNumberStyle: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    size: 14,
    color: COLORS.find((color) => color.label === 'black'),
    format: 'simple',
    position: {
      right: 17,
      bottom: 17,
    },
  },
  textStyling: {
    textItalic: false,
    textBold: false,
    textUnderline: false,
    textColor: {},
    textHighlightColor: '',
    textAlign: '',
    textListType: '',
  },
  text_variable: {
    textItalic: false,
    textBold: false,
    textUnderline: false,
    textColor: {},
    textHighlightColor: '',
    textAlign: '',
    textListType: '',
  },
  tableStyles: [{ ...defaultTableConfiguration }],
  checkboxStyles: [{ ...defaultCheckboxConfiguration }],
  selectorStyles: [{ ...defaultSelectorConfiguration }],
  textStyles: [{ ...defaultTextConfiguration }],
  tableStylesIdCount: 1,
  checkboxStylesIdCount: 1,
  selectorStylesIdCount: 1,
  textStylesIdCount: 1,
  unorderedListBulletValue0: 'Defaut',
  unorderedListBulletValue1: 'Defaut',
  unorderedListBulletValue2: 'Defaut',
  unorderedListBulletValue3: 'Defaut',
  unorderedListBulletValue4: 'Defaut',

  title1: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    fontSize: 22,
    fontFamily: '',
    color: COLORS.find((color) => color.label === 'black'),
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
    typeTitle: 'chiffre',
    indexTitle: '',
    borders: {
      size: 3,
      color: COLORS.find((color) => color.label === 'transparent'),
      top: false,
      bottom: false,
      left: false,
      right: false,
    },
  },
  title2: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    fontSize: 20,
    fontFamily: '',
    color: COLORS.find((color) => color.label === 'black'),
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
    typeTitle: 'chiffre',
    indexTitle: '',
    borders: {
      size: 3,
      color: COLORS.find((color) => color.label === 'transparent'),
      top: false,
      bottom: false,
      left: false,
      right: false,
    },
  },
  title3: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    fontSize: 18,
    fontFamily: '',
    color: COLORS.find((color) => color.label === 'black'),
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
    typeTitle: 'chiffre',
    indexTitle: '',
    borders: {
      size: 3,
      color: COLORS.find((color) => color.label === 'transparent'),
      top: false,
      bottom: false,
      left: false,
      right: false,
    },
  },
  title4: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    fontSize: 16,
    fontFamily: '',
    color: COLORS.find((color) => color.label === 'black'),
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
    typeTitle: 'chiffre',
    indexTitle: '',
    borders: {
      size: 3,
      color: COLORS.find((color) => color.label === 'transparent'),
      top: false,
      bottom: false,
      left: false,
      right: false,
    },
  },
  checkboxs_variable: {
    fontWeight: '',
    fontStyle: '',
    textDecoration: '',
    fontSize: 14,
    fontFamily: '',
    color: COLORS.find((color) => color.label === 'black'),
    backgroundColor: COLORS.find((color) => color.label === 'transparent'),
    borders: {
      size: 3,
      color: COLORS.find((color) => color.label === 'transparent'),
      top: false,
      bottom: false,
      left: false,
      right: false,
    },
    rowGap: lineSpacing,
    columnGap: 8,
    disposition: 2,
  },
};

export const defaultPageStructure = {
  id: '',
  masks: [],
  backgroundColor: {
    label: 'white',
    style: 'rgba(255, 255, 255, 1.0)',
  },
};

export const defaultBorder = {
  color: {
    label: 'transparent',
    style: 'rgba(255, 255, 255, 0.0)',
  },
  size: 3,
  left: false,
  right: false,
  top: false,
  bottom: false,
};

export const defaultBackground = {
  label: 'transparent',
  style: 'rgba(255, 255, 255, 0.0)',
};

export const defaultImgAspectRatio = 512 / 478.0; // from components/Image/placeholder-img.png

export const AUTO_HEIGHT_BOXES = [
  'text',
  'img',
  ...Object.values(TitlesTypes),
  'summary',
  'table',
  ItemTypes.SELECTOR_VARIABLE,
  ItemTypes.TEXT_VARIABLE,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE,
];

export const checkboxDispositions = {
  ONE_COLUMN: 1,
  TWO_COLUMNS: 2,
  THREE_COLUMNS: 3,
  FOUR_COLUMNS: 4,
};

const baseTable = {
  positions: { horizontal: 'center', vertical: 'center' },
  zIndex: 0,
  variablePath: '',
  width: calculateWidth(
    defaultConfiguration.margins,
    defaultConfiguration.indentationTitles,
    0
  ),
  height: 'auto',
  left: calculateLeftPadding(
    defaultConfiguration.margins,
    defaultConfiguration.indentationTitles,
    0
  ),
  content: {
    columns: [
      {
        key: colId(),
        title: 'Col A',
        width: 360,
      },
      {
        key: colId(),
        title: 'Col B',
      },
    ],
    data: [
      {
        key: rowId(),
      },
    ],
  },
};

const baseImg = {
  positions: { horizontal: 'center', vertical: 'center' },
  zIndex: 0,
  variablePath: '',
  width: gridWidth * 6 * (casesInGrid / 32),
  height: 'auto',
  content: {
    src: null,
  },
  border: defaultBorder,
  backgroundColor: defaultBackground,
};

export const defaultItemValues = {
  summary: {
    positions: { horizontal: 'center', vertical: 'center' },
    zIndex: 0,
    width: calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      0
    ),
    height: gridHeight * 12,
    left: calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      0
    ),
    paddingSummary: 12,
    content: {
      configuration: {
        fontFamily: '',
        fontSize: 14,
        colors: COLORS,
        lineSummaryStart: null,
        lineSummaryEnd: null,
        table: { ...defaultTableConfiguration },
        title1: {
          visibility: 'visible',
          fontSize: 22,
          fontFamily: '',
          fontWeight: '',
          fontStyle: '',
          textDecoration: '',
          color: COLORS.find((color) => color.label === 'black'),
        },
        title2: {
          visibility: 'visible',
          fontSize: 18,
          fontFamily: '',
          fontWeight: '',
          fontStyle: '',
          textDecoration: '',
          color: COLORS.find((color) => color.label === 'black'),
        },
        title3: {
          visibility: '',
          fontSize: 14,
          fontFamily: '',
          fontWeight: '',
          fontStyle: '',
          textDecoration: '',
          color: COLORS.find((color) => color.label === 'black'),
        },
        title4: {
          visibility: '',
          fontSize: 10,
          fontFamily: '',
          fontWeight: '',
          fontStyle: '',
          textDecoration: '',
          color: COLORS.find((color) => color.label === 'black'),
        },
      },
    },
  },
  title1: {
    positions: { horizontal: 'end', vertical: 'center' },
    zIndex: 0,
    paddingTitle: 12,
    width: calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      0
    ),
    height: calculateHeight(),
    left: calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      0
    ),
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
      ...defaultConfiguration.title1,
    },
    border: defaultBorder,
    backgroundColor: defaultBackground,
  },
  title2: {
    positions: { horizontal: 'end', vertical: 'center' },
    zIndex: 0,
    paddingTitle: 12,
    width: calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      1
    ),
    height: calculateHeight(),
    left: calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      1
    ),
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
      ...defaultConfiguration.title2,
    },
    border: defaultBorder,
    backgroundColor: defaultBackground,
  },
  title3: {
    positions: { horizontal: 'end', vertical: 'center' },
    zIndex: 0,
    paddingTitle: 12,
    width: calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      2
    ),
    height: calculateHeight(),
    left: calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      2
    ),
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
      ...defaultConfiguration.title3,
    },
    border: defaultBorder,
    backgroundColor: defaultBackground,
  },
  title4: {
    positions: { horizontal: 'end', vertical: 'center' },
    zIndex: 0,
    paddingTitle: 12,
    width: calculateWidth(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      3
    ),
    height: calculateHeight(),
    left: calculateLeftPadding(
      defaultConfiguration.margins,
      defaultConfiguration.indentationTitles,
      3
    ),
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
      ...defaultConfiguration.title4,
    },
    border: defaultBorder,
    backgroundColor: defaultBackground,
  },
  text: {
    positions: { horizontal: 'start', vertical: 'start' },
    zIndex: 0,
    width: gridWidth * 12 * (casesInGrid / 32),
    height: gridHeight * (casesInGrid / 32),
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
    },
    border: defaultBorder,
    backgroundColor: defaultBackground,
  },
  text_variable: {
    positions: { horizontal: 'start', vertical: 'start' },
    zIndex: 0,
    width: gridWidth * 12 * (casesInGrid / 32),
    height: gridHeight * (casesInGrid / 32),
    textStyleId: 0,
    content: {
      text: '',
      editorState: EditorState.createEmpty(),
    },
    value_required: false,
    remark: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
  },
  shape: {
    positions: { horizontal: 'center', vertical: 'center' },
    zIndex: 0,
    width: gridWidth * 3 * (casesInGrid / 32),
    height: gridWidth * 3 * (casesInGrid / 32),
    content: {
      color: 'rgba(26, 188, 156, 1)',
      shape: 'square',
    },
    rotation: 0,
    borders: {
      color: {
        label: 'transparent',
        style: 'rgba(255, 255, 255, 0.0)',
      },
      size: 0,
    },
    arrows: {
      right: true,
      left: false,
      stroke: 5,
    },
    stroke: 5,
  },
  line: {
    positions: { horizontal: 'center', vertical: 'center' },
    zIndex: 0,
    width: gridWidth * 24 * (casesInGrid / 32),
    height: 10,
    content: {
      color: 'rgba(26, 188, 156, 1)',
      style: 'solid',
      height: 10,
      side: 'Bottom',
    },
  },
  img: baseImg,
  table: baseTable,
  mask: {
    positions: { horizontal: 'center', vertical: 'center' },
    zIndex: 0,
    width: '100%',
    content: null,
    border: defaultBorder,
    backgroundColor: 'rgba(20, 20, 20, 0.3)',
  },
  line_break: {
    positions: { horizontal: 'center', vertical: 'center' },
    zIndex: 0,
    width: '100%',
    content: null,
    height: 'auto',
  },
  checkboxs_variable: {
    positions: { horizontal: 'start', vertical: 'center' },
    zIndex: 0,
    width: 200,
    content: null,
    height: 'auto',
    name: '',
    maxNumberOfAnwser: 1,
    minNumberOfAnwser: 1,
    onlyOneRequiredAnswer: true,
    checkboxStyleId: 0,
    checkboxs: [],
    remark: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
  },
  selector_variable: {
    positions: { horizontal: 'start', vertical: 'center' },
    zIndex: 0,
    width: 200,
    content: null,
    height: 'auto',
    name: '',
    maxNumberOfAnwser: 1,
    minNumberOfAnwser: 1,
    onlyOneRequiredAnswer: true,
    selectorStyleId: 0,
    options: [],
    remark: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
  },
  section_variable: {
    positions: { horizontal: 'start', vertical: 'center' },
    zIndex: 0,
    width: '100%',
    content: null,
    height: 'auto',
    name: '',
    answer: null,
    sectionId: null,
    questions: [
      {
        label: 'Oui',
        id: uuid(),
      },
      {
        label: 'Non',
        id: uuid(),
      },
    ],
    remark: JSON.stringify(
      convertToRaw(EditorState.createEmpty().getCurrentContent())
    ),
  },
  img_variable: baseImg,
  table_variable: baseTable,
};

export const variablesIcons = {
  [ItemTypes.CHECKBOXS_VARIABLE]: faCheckSquare,
  [ItemTypes.TEXT_VARIABLE]: faInputText,
  [ItemTypes.SELECTOR_VARIABLE]: faListDropdown,
  [ItemTypes.SECTION_VARIABLE]: faRectangleHistoryCirclePlus,
  [ItemTypes.CUSTOM_TEXT_VARIABLE]: faText,
  [ItemTypes.VARIABLE_GROUP]: faListTree,
  [ItemTypes.IMG_VARIABLE]: faImage,
  [ItemTypes.TABLE_VARIABLE]: faTable,
};

export const defaultColumnWidth = 50;

export const defaultSlider = {
  from: {
    value: null,
  },
  to: {
    value: null,
  },
  boxes: [],
};

export const UNMASKABLE_TYPES = [...Object.values(TitlesTypes), 'summary'];

export const canBeInMask = (boxType) => {
  return !UNMASKABLE_TYPES.includes(boxType);
};

export const MASKS_TYPE = {
  HEADER: {
    name: 'en-tete',
    id: 'header',
    boxes: [],
    editableMask: true,
    height: masksDefaultHeight.header,
  },
  FOOTER: {
    name: 'pied de page',
    id: 'footer',
    boxes: [],
    editableMask: true,
    height: masksDefaultHeight.footer,
  },
  SEPARATOR: {
    name: 'séparation de chapitres',
    id: 'break',
    boxes: [],
    editableMask: true,
  },
  COVER: { name: 'page de garde', id: 'cover', boxes: [], editableMask: true },
  END: { name: 'page de fin', id: 'end', boxes: [], editableMask: true },
  PDFTOIMAGE: {
    name: 'pdf to image',
    id: 'pdfToImage',
    boxes: [],
    editableMask: true,
  },
};

export const defaultMasks = [
  { ...MASKS_TYPE.HEADER },
  { ...MASKS_TYPE.FOOTER },
  { ...MASKS_TYPE.SEPARATOR },
  { ...MASKS_TYPE.COVER },
  { ...MASKS_TYPE.END },
];

export const PRIMARY_COLOR = '#f17024';
export const PRIMARY_DARK = '#0b2239';
export const VALID_GREEN = '#52c41a';
export const BG_BADGE = '#ffffff';
export const RESIZING_BORDER = PRIMARY_COLOR;
export const BORDER_GRAY = '#d9d9d9';
export const VARIABLE_COLOR = '#D8572A';

export const MENU_PARENT = {
  name: 'name',
  remark: 'remark',
  content: 'content',
  option: 'option',
  checkbox: 'checkbox',
  header: 'header',
  cell: 'cell',
};

const createAncestorKeys = (parentKeys, prefix, filterKeys = []) => {
  const result = {};
  Object.values(parentKeys).forEach((value) => {
    if (filterKeys.length === 0 || filterKeys.includes(value)) {
      result[value] = `${prefix}_${value}`;
    }
  });
  return result;
};

export const MENU_ANCESTORS = {
  BOX: createAncestorKeys(MENU_PARENT, 'box'),
  CUSTOM_TEXT_VARIABLE: createAncestorKeys(
    MENU_PARENT,
    'custom_text_variable',
    ['remark']
  ),
  VARIABLE_GROUP: createAncestorKeys(MENU_PARENT, 'variable_group', ['remark']),
};
/**
 * EDITOR
 */
export const INLINE_STYLES = [
  { label: GLOSSARY.GRAS, style: 'BOLD' },
  { label: GLOSSARY.ITALIQUE, style: 'ITALIC' },
  { label: GLOSSARY.SOULIGNE, style: 'UNDERLINE' },
];

export const BLOCK_TYPES = [
  { label: GLOSSARY.LISTE_A_PUCE, style: 'unordered-list-item' },
  { label: GLOSSARY.LISTE_NUMEROTEE, style: 'ordered-list-item' },
];

export const FONT_SIZES = [
  { value: 4, display: false },
  { value: 5, display: false },
  { value: 6, display: false },
  { value: 7, display: false },
  { value: 8, display: true },
  { value: 9, display: true },
  { value: 10, display: true },
  { value: 11, display: true },
  { value: 12, display: true },
  { value: 13, display: false },
  { value: 14, display: true },
  { value: 15, display: false },
  { value: 16, display: false },
  { value: 17, display: false },
  { value: 18, display: true },
  { value: 19, display: false },
  { value: 20, display: false },
  { value: 21, display: false },
  { value: 22, display: false },
  { value: 23, display: false },
  { value: 24, display: true },
  { value: 25, display: false },
  { value: 26, display: false },
  { value: 27, display: false },
  { value: 28, display: false },
  { value: 29, display: false },
  { value: 30, display: true },
  { value: 31, display: false },
  { value: 32, display: false },
  { value: 33, display: false },
  { value: 34, display: false },
  { value: 35, display: false },
  { value: 36, display: true },
  { value: 37, display: false },
  { value: 38, display: false },
  { value: 39, display: false },
  { value: 40, display: false },
  { value: 41, display: false },
  { value: 42, display: false },
  { value: 43, display: false },
  { value: 44, display: false },
  { value: 45, display: false },
  { value: 46, display: false },
  { value: 47, display: false },
  { value: 48, display: true },
  { value: 49, display: false },
  { value: 50, display: false },
  { value: 51, display: false },
  { value: 52, display: false },
  { value: 53, display: false },
  { value: 54, display: false },
  { value: 55, display: false },
  { value: 56, display: false },
  { value: 57, display: false },
  { value: 58, display: false },
  { value: 59, display: false },
  { value: 60, display: true },
  { value: 61, display: false },
  { value: 62, display: false },
  { value: 63, display: false },
  { value: 64, display: false },
  { value: 65, display: false },
  { value: 66, display: false },
  { value: 67, display: false },
  { value: 68, display: false },
  { value: 69, display: false },
  { value: 70, display: false },
  { value: 71, display: false },
  { value: 72, display: true },
  { value: 73, display: false },
  { value: 74, display: false },
  { value: 75, display: false },
  { value: 76, display: false },
  { value: 77, display: false },
  { value: 78, display: false },
  { value: 79, display: false },
  { value: 80, display: false },
  { value: 81, display: false },
  { value: 82, display: false },
  { value: 83, display: false },
  { value: 84, display: false },
  { value: 85, display: false },
  { value: 86, display: false },
  { value: 87, display: false },
  { value: 88, display: false },
  { value: 89, display: false },
  { value: 90, display: false },
  { value: 91, display: false },
  { value: 92, display: false },
  { value: 93, display: false },
  { value: 94, display: false },
  { value: 95, display: false },
  { value: 96, display: true },
  { value: 97, display: false },
  { value: 98, display: false },
  { value: 99, display: false },
];

export const generateFontSizesObject = () => {
  return FONT_SIZES.reduce((acc, cur) => {
    return {
      ...acc,
      [`FONT_SIZE_${cur.value}`]: { fontSize: `${cur.value}px` },
    };
  }, {});
};

export const generateHighlightObject = (highlightColors) => {
  return highlightColors.reduce((obj, color) => {
    return {
      ...obj,
      [`HIGHLIGHT_${color.label}`]: { backgroundColor: color.style },
    };
  }, {});
};

export const generateColorsObject = (colors) => {
  return colors.reduce((acc, cur) => {
    return { ...acc, [cur.label]: { color: cur.style } };
  }, {});
};

export const generateFamilyObject = (familyList, fromPdf) => {
  if (!fromPdf) applyFontsOnThePage(familyList);
  return familyList.reduce((acc, cur) => {
    return {
      ...acc,
      [`FONT_FAMILY_${cur.key}`]: { fontFamily: cur.familyName },
    };
  }, {});
};

export const fontsUrlBuilder = (fontsToApply) => {
  let postUrl = 'https://fonts.googleapis.com/css2?';
  let familyList = [];
  fontsToApply.forEach((fontToApply) => {
    familyList.push(
      `family=${fontToApply.familyName?.replace(/\s+/g, '+')}:wght@200;400;700`
    );
  });
  postUrl += familyList.join('&');
  return postUrl;
};

export const addStylesheetURLPreview = (url) => {
  const fontLinks = document
    .getElementsByTagName('head')[0]
    .querySelectorAll('link[href^="https://fonts.googleapis"]');
  fontLinks.forEach((node, index) => {
    if (index > 0) {
      node.remove();
    }
  });
  addStylesheetURL(url);
};

export const addStylesheetURL = (url) => {
  var link = document.createElement('link');
  link.rel = 'stylesheet';
  link.crossOrigin = 'anonymous';
  link.href = url;
  document.getElementsByTagName('head')[0].appendChild(link);
};

const removeAppliedFonts = () => {
  const fontLinks = document
    .getElementsByTagName('head')[0]
    .querySelectorAll('link[href^="https://fonts.googleapis"]');
  fontLinks.forEach((node) => node.remove());
};

export const applyFontsOnThePage = (fontsToApply) => {
  removeAppliedFonts();
  addStylesheetURL(fontsUrlBuilder(fontsToApply));
};

export const getEditorSelectionFontSize = (editorState, defaultSize) => {
  const currentSelectionStyle = editorState.getSelection();
  const inlineStyle = editorState.getCurrentInlineStyle(currentSelectionStyle);
  let fs;
  FONT_SIZES.forEach((size) => {
    if (inlineStyle.has('FONT_SIZE_' + size)) {
      fs = size;
    }
  });
  return fs || defaultSize;
};

export const letterMaj = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
];

export const letterMin = [
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
];

export const romanNumber = [
  '',
  'C',
  'CC',
  'CCC',
  'CD',
  'D',
  'DC',
  'DCC',
  'DCCC',
  'CM',
  '',
  'X',
  'XX',
  'XXX',
  'XL',
  'L',
  'LX',
  'LXX',
  'LXXX',
  'XC',
  '',
  'I',
  'II',
  'III',
  'IV',
  'V',
  'VI',
  'VII',
  'VIII',
  'IX',
];

/* Use this as data-role on an ELEMENT if you want to unselect Box when you click on this ELEMENT
   Unselect box logic is in InfinitePage.jsx
*/
export const pageBackground = 'pageBackground';

export const containerBackground = 'containerBackground';

export const roundButtonWidth = 30;

export const borderColumnColor = '#8ad2ff87';

export const borderColumnErrorColor = '#ffc2c1';

export const borderColumnWidth = 4;

export const contentHeight = window.innerHeight - navbarHeight - contentPadding;

export const minContainerHeight = 1.5715 * 12 * 3;

export const columnsDispositions = [
  {
    key: 0,
    title: '1 colonne',
    dispositions: [
      { key: 0, sizes: [1] },
      { key: 1, sizes: [1], isDraw: true },
    ],
  },
  {
    key: 1,
    title: '2 colonnes',
    dispositions: [
      { key: 0, sizes: [1 / 2, 1 / 2] },
      { key: 1, sizes: [2 / 3, 1 / 3] },
      { key: 2, sizes: [1 / 3, 2 / 3] },
      { key: 3, sizes: [3 / 4, 1 / 4] },
      { key: 4, sizes: [1 / 4, 3 / 4] },
    ],
  },
  {
    key: 2,
    title: '3 colonnes',
    dispositions: [
      { key: 0, sizes: [1 / 3, 1 / 3, 1 / 3] },
      { key: 1, sizes: [2 / 4, 1 / 4, 1 / 4] },
      { key: 2, sizes: [1 / 4, 2 / 4, 1 / 4] },
      { key: 3, sizes: [1 / 4, 1 / 4, 2 / 4] },
    ],
  },
  {
    key: 3,
    title: '4 colonnes',
    dispositions: [{ key: 0, sizes: [1 / 4, 1 / 4, 1 / 4, 1 / 4] }],
  },
];

export const borderIcons = {
  top: <BorderTopOutlined />,
  bottom: <BorderBottomOutlined />,
  left: <BorderLeftOutlined />,
  right: <BorderRightOutlined />,
};

export const antdFormError = '#ff4d4f';

export const collapseConfigPoint = 1400;

export const viewerTypes = {
  VARIABLES: 'variables',
  PAGENUMBER: 'pageNumber',
};

export const variableHandlingType = {
  OTHER: 'other',
  FORM: 'form',
};

export const tableHeaderSecondaryBgColor = '#1d5c9a';
export const tableColor = 'white';

export const dispostionButtons = {
  oneColumn: {
    icon: (
      <span className='fa-layers' style={{ width: '21px' }}>
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20 }}
        />
      </span>
    ),
    disposition: checkboxDispositions['ONE_COLUMN'],
  },
  twoColumns: {
    icon: (
      <span className='fa-layers' style={{ width: '21px' }}>
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20, position: 'absolute', right: '6px' }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20, position: 'absolute', left: '6px' }}
        />
      </span>
    ),
    disposition: checkboxDispositions['TWO_COLUMNS'],
  },
  threeColumns: {
    icon: (
      <span className='fa-layers' style={{ width: '21px' }}>
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20, position: 'absolute', right: '12px' }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20 }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 20, position: 'absolute', left: '12px' }}
        />
      </span>
    ),
    disposition: checkboxDispositions['THREE_COLUMNS'],
  },
  fourColumns: {
    icon: (
      <span
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          width: '21px',
        }}
      >
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 15 }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 15 }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 15 }}
        />
        <FontAwesomeIcon
          icon={icon({
            name: 'ellipsis-stroke-vertical',
            family: 'sharp',
            style: 'light',
          })}
          style={{ fontSize: 15 }}
        />
      </span>
    ),
    disposition: checkboxDispositions['FOUR_COLUMNS'],
  },
};
