import React, { useCallback, useMemo } from 'react'
import { ItemTypes, PRIMARY_DARK, pageBackground, variableHandlingType } from '../../constants/constants'
import { Form } from 'antd'
import { useStore } from '../../store/store'
import VariableCheckboxs from './VariableCheckboxs'
import VariableText from './VariableText'
import VariableSelector from './VariableSelector'
import VariableSection from './VariableSection'
import VariableCustomText from './VariableCustomText'
import { convertToRaw } from 'draft-js'
import VariableGroupHandler from './VariableGroupHandler'
import VariableEditorTitle from './VariableEditorTitle'
import VariableGroup from './VariableGroup'
import VariableImages from './VariableImages'
import VariableTables from './VariableTables'

const renderSpecificBoxEditor = (box, { form, onBlur, validateFormAndRetrieveValues, variablesGroupedVariables }) => {
  switch (box.type) {
    case ItemTypes.TABLE_VARIABLE:
      return <VariableTables box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.IMG_VARIABLE:
      return <VariableImages box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.CHECKBOXS_VARIABLE:
      return <VariableCheckboxs box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.TEXT_VARIABLE:
      return <VariableText box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.SELECTOR_VARIABLE:
      return <VariableSelector box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.SECTION_VARIABLE:
      return <VariableSection box={box} onBlur={onBlur} form={form} />;
    case ItemTypes.CUSTOM_TEXT_VARIABLE:
      return <VariableCustomText box={box} form={form} validateFormAndRetrieveValues={validateFormAndRetrieveValues} />
    case ItemTypes.VARIABLE_GROUP:
      return <VariableGroup box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} variablesGroupedVariables={variablesGroupedVariables} />

    default:
      return null;
  }
}

const VariableEditor = ({
  isHandlingVariables,
  box,
  variablesGroupedByContainerSelected,
  variablesGroupedByContainer
}) => {
  const updateBox = useStore(({ updateBox }) => updateBox)

  const [form] = Form.useForm();

  const onFinish = useCallback((values) => {
    updateBox(box.id, (b) => {
      Object.keys(values).forEach((key) => {
        if (Array.isArray(values[key])) {
          values[key].forEach((value, index) => {
            b[key][index] = {
              ...b[key][index],
              ...value
            }
          })
        }
        else {
          if (key === 'remark') {
            b[key] = JSON.stringify(convertToRaw(values[key].getCurrentContent()))
          } else {
            b[key] = values[key]
          }
        }
      })
    })
  }, [box?.id, updateBox])

  const validateFormAndRetrieveValues = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue()
      return values
    } catch (errors) {
      throw errors
    }
  }, [form])

  const onBlur = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues()
      onFinish(values)
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, onFinish])

  const variablesGroupedVariables = useMemo(() => {
    return variablesGroupedByContainer.filter((vb) => vb.type !== variableHandlingType.FORM);
  }, [variablesGroupedByContainer])

  return (
    <Form
      form={form}
      style={{
        flex: 1,
        backgroundColor: PRIMARY_DARK,
        width: "100%",
        padding: '0 16px 16px 50px',
        borderLeft: '1px solid white',
        display: 'flex',
        flexDirection: 'column',
        height: "100%",
        overflow: 'hidden'
      }}
      colon={false}
      // To override antd form style
      className='variable-editor__form'
      layout='horizontal'
      validateMessages={{ required: "Champ obligatoire." }}
    >
      <VariableEditorTitle
        isHandlingVariables={isHandlingVariables}
        variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
        box={box}
      />
      <div style={{
        display: 'grid',
        gridTemplateColumns: '33% 33% 33%',
        flex: 1,
        overflow: 'hidden'
      }}
        data-role={pageBackground}>
        {isHandlingVariables && <VariableGroupHandler
          form={form}
          variablesGroupedVariables={variablesGroupedVariables}
          validateFormAndRetrieveValues={validateFormAndRetrieveValues}
        />
        }
        {!isHandlingVariables && renderSpecificBoxEditor(box, { form, onBlur, validateFormAndRetrieveValues, variablesGroupedVariables })}
      </div>
    </Form>
  )
}

export default VariableEditor
