import { Form, Input } from 'antd'
import { Editor } from 'draft-js';
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactDOM from 'react-dom';
import { convertContentToRawText, getAncestorFromType, handleBeforeInput } from '../RichEditor/utils';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { MENU_PARENT } from '../../constants/constants';
import { EditorProvider } from '../../contexts/EditorContext';

const VariableName = ({ box, onBlur, disabled, form }) => {
  const { t } = useTranslation();
  const editorRef = useRef(null);
  const [focused, setFocused] = useState(false);
  const name = Form.useWatch('name')

  const setEditorState = useCallback((fn) => {
    if (!form) return;
    const newEditorState = fn(form.getFieldValue("name"));
    form.setFieldValue("name", newEditorState)
    if (typeof onBlur === 'function')
      onBlur()
  }, [form, onBlur])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={name}>
      <Form.Item
        className={`variable-editor__input name ${focused ? 'focused' : ''}`}
        label={t('name')}
        name="name"
        initialValue={box.name}
        onBlur={onBlur}
        valuePropName={typeof box.name === "string" ? undefined : 'editorState'}
        rules={[{
          required: true,
          validator: (_, value) => {
            const convertedValue = !value || typeof value === "string" ? value : convertContentToRawText(value).trim()
            if (convertedValue) {
              return Promise.resolve();
            } else {
              return Promise.reject('Champ obligatoire.');
            }
          }
        }]}
      >
        {typeof box.name === "string" && <Input
          style={{
            ...(disabled ? { backgroundColor: "#ffffff" } : {})
          }}
          disabled={disabled}
        />}
        {typeof box.name !== "string" &&
          <Editor
            stripPastedStyles={true}
            handleBeforeInput={(chars, editorState) => handleBeforeInput(
              {
                chars,
                editorState,
                setEditorState: (newEditorState) => form.setFieldValue("name", newEditorState)
              }
            )}
            ref={editorRef}
            onFocus={() => setFocused(true)} onBlur={() => setFocused(false)} />
        }
      </Form.Item>
      {typeof box.name !== "string" && !!name && ReactDOM.createPortal(
        <ContextualMenu
          editorState={name}
          setEditorState={setEditorState}
          menuAncestorType={getAncestorFromType(box.type, MENU_PARENT.name)}
          menuAncestorIds={menuAncestorIds}
        />,
        document.getElementById('SelectionVariablePortal')
      )}
    </EditorProvider>
  )
}

export default VariableName
