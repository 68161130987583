import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from '../../store/store';
import {
  boxActionColor,
} from '../../utils/styles';
// Common
import './Utils.css';
import { ItemTypes, MASKS_TYPE, viewerTypes } from '../../constants/constants';
import RichEditor from '../RichEditor';
import Image from '../Image/Image';
import Line from '../Line';
import Table from '../Table';
import Title from '../Title/Title';
import Shape from '../Shape';
import Summary from '../Summary';
import LineBreak from './LineBreak';
import { isAnUrl } from '../../utils/browser';
import CheckboxsVariable from './CheckboxsVariable';
import SelectorVariable from './SelectorVariable';
import { isAWritableBox, isAVariableBox, isAImgBox } from '../../utils/boxes';
import TextVariable from './TextVariable';
import { maxContainerHeight } from '../../utils/containers';

const getStyles = (drawMode, box, header, footer) => {
  if (drawMode || box.type === ItemTypes.TABLE)
    return {
    }
  return {
    maxHeight: maxContainerHeight({ header, footer }),
    overflow: isAWritableBox(box.type) ? 'hidden' : 'visible'
  }
}

const selectComponent = (box, drawMode) => {
  switch (box?.type) {
    case ItemTypes.TEXT:
      return <RichEditor box={box} drawMode={drawMode} />;
    case ItemTypes.TITLE:
    case ItemTypes.TITLE_2:
    case ItemTypes.TITLE_3:
    case ItemTypes.TITLE_4:
      return <Title box={box} drawMode={drawMode} />;
    case ItemTypes.IMG:
    case ItemTypes.IMG_VARIABLE:
      return <Image box={box} />;
    case ItemTypes.SHAPE:
      return <Shape box={box} />;
    case ItemTypes.LINE:
      return <Line box={box} />;
    case ItemTypes.TABLE:
    case ItemTypes.TABLE_VARIABLE:
      return <Table box={box} drawMode={drawMode} />;
    case ItemTypes.SUMMARY:
      return <Summary box={box} />;
    case ItemTypes.LINE_BREAK:
      return <LineBreak />;
    case ItemTypes.CHECKBOXS_VARIABLE:
      return <CheckboxsVariable box={box} />;
    case ItemTypes.TEXT_VARIABLE:
      return <TextVariable box={box} drawMode={drawMode} />;
    case ItemTypes.SELECTOR_VARIABLE:
      return <SelectorVariable box={box} />;
    default:
      return null;
  }
};

const selector =
  (id) =>
    ({
      handleSelectBox,
      selectedBoxId,
      groupSelection,
      setBoxMovable,
      slider,
      boxes,
      updateBox,
      masks,
      containers,
      currentProposal,
      fromPdf,
    }) => {
      const box = [
        ...boxes,
        ...masks.map((m) => m.boxes).flat()
      ].find((b) => b.id === id);
      const container = containers.find(container => container.columnsIds.includes(box.columnId))
      const maskType = masks.find((m) => m.id === MASKS_TYPE.HEADER.id)?.editableMask;
      const maskTypeEdiatble = typeof maskType !== 'boolean' ? true : maskType;

      return {
        handleSelectBox,
        isSelected: selectedBoxId === id,
        isInSelectedGroup: groupSelection.includes(id),
        setBoxMovable,
        isSlided: slider.boxes.includes(id),
        box: box,
        updateBox,
        container: container,
        currentProposal,
        fromPdf,
        maskTypeEdiatble: maskTypeEdiatble
      }
    };

const Box = ({ id, width, height, drawMode, fromViewer }) => {
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const {
    handleSelectBox,
    isSelected,
    isInSelectedGroup,
    isSlided,
    box,
    updateBox,
    container,
    currentProposal,
    fromPdf,
    maskTypeEdiatble
  } = useStore(selector(id));

  const boxRef = React.useRef(null);

  const [isHovered, setIsHovered] = useState(false);

  const measureBox = (suffix = 'from_measuring_effect') => {
    setTimeout(() => {
      if (boxRef.current) {
        // console.log('measuring Box top', box.top);
        // console.log('measuring Box Left', box.left);
        const { top, left } = boxRef.current?.getBoundingClientRect();
        const { width, height } = boxRef.current?.getBoundingClientRect();
        const { top: parentTop, left: parentLeft } = boxRef.current.parentElement.parentElement.getBoundingClientRect();
        const boxLeft = left - parentLeft;
        const boxTop = top - parentTop;
        // console.log('measuring Box clientLeft', left);
        // console.log('measuring Box clientTop', top);

        // console.log('measuring Box parentLeft', parentLeft);
        // console.log('measuring Box parentTop', parentTop);

        // console.log('measuring Box client position', box.id);
        // console.log('measuring Box client left', boxLeft);
        // console.log('measuring Box client top', boxTop);
        updateBox(
          box.id,
          (box) => {
            box.clientTop = boxTop;
            box.clientLeft = boxLeft;
            box.clientWidth = width;
            box.clientHeight = height;
          },
          { actionNameSuffix: suffix }
        );
      }
    }, 100);
  }

  // }, [box, box.top, box.left, updateBox])

  useEffect(() => {
    const shouldMeasure = isSelected || isInSelectedGroup || isSlided;
    if (shouldMeasure) {
      setTimeout(() => {
        measureBox();
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, isInSelectedGroup, isSlided]);

  useEffect(() => {
    if (box?.alignToken) {
      setTimeout(() => {
        measureBox('from_align_token_effect');
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [box?.alignToken]);

  const editableContainerRule = useCallback((event) => {
    const group = event.ctrlKey || event.metaKey;
    if (fromViewer === viewerTypes.PAGENUMBER) return;
    if ((box.maskId === "header" || box.maskId === "footer") && !maskTypeEdiatble && box.type !== "img") {
      return null
    }
    if (container?.editable || !container?.hasOwnProperty("editable") || currentProposal.id === null) {
      if (!isSelected) {
        handleSelectBox({ box, group });
      }
    } else {
      if (box.type === "img" || isAVariableBox(box.type)) {
        if (!isSelected) {
          handleSelectBox({ box, group });
        }
      }
    }
  }, [container, box, isSelected, handleSelectBox, fromViewer])

  const editableContainerHoverRule = useCallback(() => {
    if (fromViewer === viewerTypes.PAGENUMBER) return false
    if (currentProposal.id === null) {
      return true
    } else {
      if ((box.maskId === "header" || box.maskId === "footer") && !maskTypeEdiatble && box.type !== "img") return false
      if (container?.editable || !container?.hasOwnProperty("editable")) {
        return true
      } else {
        if (box.type === "img") {
          return true
        } else {
          return false
        }
      }
    }
  }, [container, currentProposal, box]);

  if (!box) { return null }
  return (
    <div
      ref={boxRef}
      id={`box-${box?.id ? box.id : 'new'}`}
      className={isSlided ? 'box-sliding' : ''}
      onMouseEnter={() => setIsHovered(editableContainerHoverRule())}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(event) => {
        editableContainerRule(event)
      }}
      style={{
        outline: `2px solid ${boxActionColor({
          selected: isSelected,
          inGroup: isInSelectedGroup,
          hovered: isHovered
        })}`,
        zIndex: box.zIndex,
        ...(fromPdf && isAImgBox(box?.type) &&
          !isAnUrl(box?.content?.src) ? { visibility: 'hidden' } : {}),
        ...getStyles(drawMode, box, header, footer)
      }}
    >
      {selectComponent({
        ...box,
        width: width || box.width,
        height: height || box.height,
      }, drawMode)}
    </div>
  );
};

export default Box;
