import React, { useMemo } from 'react'
import { useStore } from '../../store/store';
import { contentHeight, pageBackground } from '../../constants/constants';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import ItemWrapper from '../Container/ItemWrapper';
import { Virtuoso } from 'react-virtuoso';

const selector = ({
  containers,
  configuration,
  landscape
}) => ({
  containersIds: containers.map((p) => p.id),
  configuration,
  landscape
});

const listRef = React.createRef();
export const scrollToItem = (box, containers) => {
  const containerIdx = containers.findIndex((c) => c.columnsIds.includes(box.columnId))
  listRef.current.scrollToIndex(containerIdx + 1);
};

const List = React.forwardRef((props, ref) => {
  const {
    configuration,
    landscape
  } = useStore(selector);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: landscape ? 'start' : 'center',
        marginLeft: landscape ? 40 : 0,
      }}
      data-role={pageBackground}>
      <div
        {...props}
        ref={ref}
        style={{
          display: "flex",
          backgroundColor: "white",
          flexDirection: "column",
          position: 'relative',
          width: landscape ? pageHeight : pageWidth,
          color: configuration.baseFontColor?.style,
          ...props.style
        }}
        className="page"
        data-role={pageBackground} />
    </div>
  )
})

const InfinitePage = () => {
  const {
    containersIds,
  } = useStore(selector);

  const items = useMemo(() => {
    return [
      { type: 'adder-menu', id: 0 },
      ...containersIds.map((containerId) => ({ type: 'container', id: containerId })),
      { type: 'infinite-page-drop', id: 0 },
    ]
  }, [containersIds])

  return (
    <Virtuoso
      ref={listRef}
      style={{
        height: contentHeight,
        width: '100%'
      }}
      className='no-scrollbar'
      totalCount={items.length}
      itemContent={index => <ItemWrapper
        index={index}
        itemId={items[index].id}
        itemLength={items.length}
      />}
      defaultItemHeight={contentHeight}
      computeItemKey={(index) => {
        return `${items[index].type}-${items[index].id}`
      }}
      components={{ List }}
    />
  )
}

export default InfinitePage
