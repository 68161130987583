import { Button } from 'antd'
import React, { useCallback } from 'react'
import ReactDOM from 'react-dom';
import { useStore } from '../../store/store';
import { getBackgroundBorderStyle, iconButtonStyle, navbarButtonStyle } from '../../utils/styles';
import { Separator } from '../Layout/Navbar';
import { NavbarListButton } from '../Layout/NavbarListButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { dispostionButtons } from '../../constants/constants';
import NavbarSelector from '../Layout/NavbarSelector';
import { CaretDownOutlined } from '@ant-design/icons';
import CheckboxCustom from './CheckboxCustom';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { isAVariableBox } from '../../utils/boxes';
import { Editor } from 'draft-js';

const selector = (id) =>
  ({ updateBox, selectedBoxId, configuration, boxes, masks }) => {
    let selectedBox = [...boxes, ...masks.map((m) => m.boxes).flat()].find((box) => box?.id === id)
    return {
      isSelected: selectedBoxId === id,
      updateBox,
      configuration,
      checkboxStyle: configuration.checkboxStyles.find((ts) => ts.id === selectedBox?.checkboxStyleId)
    };
  };

const StylingComponent = ({ updateBox, box, configuration, checkboxStyle }) => {

  const { t } = useTranslation();

  const changeDisposition = (disposition) => {
    updateBox(box.id, (box) => {
      box.disposition = disposition
    })
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarListButton
        title={t('disposition')}
        icon={< FontAwesomeIcon icon={icon({ name: 'grid-dividers', style: 'light' })} style={iconButtonStyle()} />}
      >
        {Object.keys(dispostionButtons).map((buttonName) => {
          return (
            <Button
              key={`${buttonName}`}
              style={{
                ...navbarButtonStyle,
                marginBottom: '1px',
              }}
              disabled={false}
              icon={dispostionButtons[buttonName].icon}
              onClick={() => changeDisposition(dispostionButtons[buttonName].disposition)}
            />
          );
        })}
      </NavbarListButton>
      <NavbarSelector
        tooltipContent={t('table-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(box.id, (box) => {
            box.checkboxStyleId = configuration.checkboxStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={checkboxStyle?.name}
        options={configuration.checkboxStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      <Separator />
    </div>
  )
}

const CheckboxsVariable = ({ box }) => {
  const {
    checkboxs,
    maxNumberOfAnwser
  } = box
  const { updateLeftShownStatus } = useOverlay();
  const hasCheckedMaxNumberOfAnswers = checkboxs.filter((c) => c.checked).length === maxNumberOfAnwser
  const {
    updateBox,
    isSelected,
    configuration,
    checkboxStyle
  } = useStore(selector(box.id));

  const getStyleOnCheckbox = useCallback(() => {
    return {
      width: checkboxStyle?.box?.width,
      height: checkboxStyle?.box?.height,
      backgroundColor: checkboxStyle?.box?.backgroundColor.style,
      border: `${checkboxStyle?.borders?.size}px solid ${checkboxStyle?.borders?.color?.style}`,
      borderRadius: `${checkboxStyle?.borders?.radius}px`,
      color: checkboxStyle?.check?.color?.style,
      fontSize: `${checkboxStyle?.check?.height}px`,
      fontWeight: `${checkboxStyle?.check?.weight}`,
    }
  }, [checkboxStyle])

  const renderCheckbox = ({ checked, label }, idx) => {
    return <div key={idx} style={{
      display: 'flex',
      columnGap: 8,
      overflow: 'hidden',
      alignItems: 'center',
      zIndex: 1000,
    }}>
      <CheckboxCustom
        label={<Editor
          readOnly
          editorState={label} />} getStyleOnCheckbox={getStyleOnCheckbox}
        checked={checked}
        disabled={hasCheckedMaxNumberOfAnswers && !checked && maxNumberOfAnwser !== 1}
        box={box}
        idx={idx}
        maxNumberOfAnwser={maxNumberOfAnwser}
      />
    </div>
  }

  const getStyle = () => {
    const keys = [
      'textDecoration',
      'fontStyle'
    ]

    const style = { ...checkboxStyle ? checkboxStyle : configuration.checkboxStyles[0] }
    keys.forEach((key) => {
      if (style[key]) {
        delete style[key]
      }
    })

    let gridTemplateColumns = "";
    let dispo;
    if (box.disposition) dispo = box.disposition
    else dispo = style.disposition
    for (let i = 0; i < dispo; i++) {
      gridTemplateColumns += "1fr "
    }
    return {
      display: "grid",
      gridTemplateColumns,
      ...getBackgroundBorderStyle(style.backgroundColor, style.borders, style),
      color: style.color.style,
    }
  }

  return (
    <>
      <div style={{
        ...getStyle(),
        width: box.width,
      }}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type)) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
      >
        {checkboxs?.map((c, idx) => renderCheckbox(c, idx))}
      </div>
      {isSelected &&
        ReactDOM.createPortal(
          <StylingComponent updateBox={updateBox} box={box} configuration={configuration} checkboxStyle={checkboxStyle} />,
          document.getElementById('ComponentPortal')
        )}
    </>
  )
}

export default CheckboxsVariable
