import React from 'react'
import { variableStyle } from '../../utils/styles'
import VariableCustomTextLeftForm from './VariableCustomTextLeftForm';
import { useTranslation } from 'react-i18next';
import ButtonCommon from '../common/ButtonCommon';
import { Modal } from 'antd';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { useStore } from '../../store/store';

const { confirm } = Modal;

const VariableCustomText = ({ box, form, validateFormAndRetrieveValues }) => {
  const { t } = useTranslation()
  const deleteVariableInDocument = useStore(({ deleteVariableInDocument }) => deleteVariableInDocument)
  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableCustomTextLeftForm box={box} form={form} validateFormAndRetrieveValues={validateFormAndRetrieveValues} />
      </div>
      <div style={variableStyle["middle"]}>
        <ButtonCommon
          type='delete'
          style={{ marginLeft: 'auto', marginRight: 'auto' }}
          onClick={() => {
            confirm({
              title: t('delete-custom-variable-text-modal', { name: box.name }),
              icon: <ExclamationCircleFilled />,
              okText: t('delete'),
              okType: "danger",
              cancelText: t('cancel'),
              onOk() {
                deleteVariableInDocument(box.id)
                return false;
              },
              onCancel() {
                return false;
              },
            });
          }}
        >
          {t('delete-custom-variable-text')}
        </ButtonCommon>
      </div>
      <div style={variableStyle["right"]} />
    </>
  )
}

export default VariableCustomText
