import React, { useEffect, useRef, useState } from 'react';
import { Button, Divider, Layout } from 'antd';
import './Navbar.less';
import { ReactComponent as SmartLogo } from '../../images/logo.svg';

import { ImageComponent } from '../Image/Image';
import { BorderManager } from './BorderManager';
import { RightPropertiesComponent } from './RightProperties';
import { UserInfosComponent } from './UserInfos';
import { SummarySelector } from '../Summary/SummarySelector';
import { useStore } from '../../store/store';
import { AlignmentComponent } from './Alignment';
import { marginOnPageButton } from '../../constants/gridConfig';
import { BoxRotationInterface } from '../common/BoxRotationInterface';
import { BackgroundColorPicker } from './BackgroundColorPicker';
import { navbarHeight } from '../../constants/gridConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useModalOpener } from '../../hooks';
import { isAImgBox, isAVariableBox } from '../../utils/boxes';

const selector = ({
  selectedBoxId,
  boxes,
  masks,
  user,
  groupSelection,
  proposalList,
  maskEditing,
  setMaskEditing,
  currentDocument,
  containers,
  currentProposal,
}) => {
  const box = [
    ...boxes,
    ...masks.map((m) => m.boxes).flat(),
  ].find((box) => selectedBoxId && box.id === selectedBoxId)
  const container = containers.find(container => container.columnsIds.includes(box?.columnId))
  let selectedBox = box
  if (!container?.editable && (isAImgBox(box?.type) || isAVariableBox(box?.type)) && container?.hasOwnProperty('editable') && currentProposal.id !== null) {
    selectedBox = {}
  }
  return {
    selectedBox: selectedBox,
    groupSelection,
    user,
    proposalList,
    maskEditing,
    setMaskEditing,
    currentDocument
  }
};

const { Header } = Layout;

const navbarStyle = {
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  margin: '0 auto',
  height: navbarHeight,
  width: '100%',
  padding: 0,
  position: 'fixed',
  zIndex: 1000,
}

export const Separator = () => {
  return (
    <Divider
      type="vertical"
      style={{
        top: 0,
        height: '36px',
        margin: `0px ${3 * marginOnPageButton}px 0px ${2 * marginOnPageButton}px`,
        borderLeft: '2px solid white',
      }}
    />
  );
};

const Navbar = () => {
  const {
    selectedBox,
    user,
    proposalList,
    maskEditing,
    setMaskEditing,
    currentDocument
  } = useStore(selector);
  const { t } = useTranslation();
  const optionsRef = useRef();
  const headerLogoRef = useRef();
  const [configurationPanelVisible, setConfigurationPanelVisible] = useModalOpener(false)
  const [configurationTab, setConfigurationTab] = useState('Couleurs')

  useEffect(() => {
    function handleWindowResize() {
      const headerLogoSize = maskEditing?.length > 0 ? 190 : 138
      if (optionsRef.current.clientWidth > window.innerWidth - headerLogoSize) {
        headerLogoRef.current.style.display = 'none'
      } else {
        headerLogoRef.current.style.display = 'flex'
      }
    }
    handleWindowResize();
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [selectedBox?.id, maskEditing]);

  return (
    <Header style={navbarStyle}>
      <div className='navbar__logo-container' style={{ marginLeft: 15, position: 'absolute', left: 8 }} ref={headerLogoRef}>
        {maskEditing?.length > 0 ?
          <Button
            onClick={() => {
              if (currentDocument.type === 'template') {
                setConfigurationTab('masks');
                setConfigurationPanelVisible(true);
              }
              setMaskEditing([]);
            }}
            icon={<FontAwesomeIcon icon={icon({ name: 'arrow-left', style: 'solid' })} style={{ marginRight: "8px" }} />}
            style={{ padding: '4px 10px' }}
            type='primary'
          >
            <span>{t('main-document')}</span>
          </Button>
          :
          <>
            {user.logo ?
              <div style={{ height: '100%' }}>
                <img src={user.logo} alt="User Logo" className='navbar__logo' />
              </div>
              :
              <div style={{ width: 115 }}>
                <SmartLogo className='navbar__logo' />
              </div>
            }
          </>
        }
      </div>
      <div style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center'
      }} ref={optionsRef}>
        <div
          id='VariablePortal'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        />
        <div
          id='ComponentPortal'
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          {isAImgBox(selectedBox?.type) ? (
            <>
              <ImageComponent box={selectedBox} />
              <Separator />
            </>
          ) : null}
          {selectedBox?.type === 'summary' ? (
            <>
              <SummarySelector selectedBox={selectedBox} />
              <Separator />
            </>
          ) : null}
        </div>
        <BackgroundColorPicker />
        <Separator />
        <BorderManager />
        <Separator />
        <BoxRotationInterface />
        <Separator />
        <AlignmentComponent />
        <Separator />

        {/* -- RIGHT Properties Buttons */}
        {!proposalList && <RightPropertiesComponent
          selectedBox={selectedBox}
          configurationPanelVisible={configurationPanelVisible}
          setConfigurationPanelVisible={setConfigurationPanelVisible}
          configurationTab={configurationTab}
          setConfigurationTab={setConfigurationTab}
        />}

        {/* -- USER Infos Buttons -- */}
        {user && <UserInfosComponent />}
      </div>
    </Header>
  );
};

export default React.memo(Navbar);
