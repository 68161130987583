import { Modal } from 'antd';
import { useCallback, useEffect } from 'react';
import { useStore } from '../../store/store';
import { CopyBox, isAImgBox, isAVariableBox } from '../../utils/boxes';
import { getUrlParamsObject, searchUrlChangedEvent } from '../../utils/browser';
import { defaultSlider } from '../../constants/constants';
import { debounce } from 'lodash';
const { confirm } = Modal;
const boxSelector = ({
  moveBoxInfinite,
  selectedBoxId,
  setCopy,
  boxes,
  masks,
  boxIsMovable,
  removeBox,
  setSlider,
  slider,
  setCurrentDocument,
  eventManagerEnabled,
  setEventManagerEnabled,
  currentProposal,
  containers,
  boxVariableSelectedId,
  fromPdf
}) => {
  let selectedBox = selectedBoxId && [...boxes, ...masks.map((m) => m.boxes).flat()].find((box) => box?.id === selectedBoxId)
  const container = containers.find(container => container.columnsIds.includes(selectedBox?.columnId))
  if (!container?.editable && (isAImgBox(selectedBox?.type) || isAVariableBox(selectedBox?.type)) && container?.hasOwnProperty('editable') && currentProposal.id !== null) {
    selectedBox = {}
  }
  return {
    moveBoxInfinite,
    setCopy,
    selectedBox,
    boxIsMovable,
    removeBox,
    setSlider,
    slider,
    setCurrentDocument,
    eventManagerEnabled,
    setEventManagerEnabled,
    boxVariableSelectedId,
    fromPdf
  }
};

const EventManager = ({ children }) => {
  const {
    moveBoxInfinite,
    setCopy,
    selectedBox,
    boxIsMovable,
    removeBox,
    setSlider,
    slider,
    setCurrentDocument,
    eventManagerEnabled,
    setEventManagerEnabled,
    boxVariableSelectedId,
    fromPdf
  } = useStore(boxSelector);

  const handleCtrlCopy = useCallback(
    (event) => {
      if (
        selectedBox &&
        (event.ctrlKey || event.metaKey) &&
        (event.key === 'c' || event.key === 'C') &&
        eventManagerEnabled
      ) {
        CopyBox(selectedBox, setCopy);
      }
    },
    [selectedBox, eventManagerEnabled, setCopy]
  );

  const updateBoxPosition = useCallback(debounce(event => {
    if (selectedBox?.id && boxIsMovable && eventManagerEnabled) {
      const move = { h: 1, w: 1 };
      switch (event.key) {
        case 'ArrowUp':
          moveBoxInfinite(
            selectedBox?.left,
            selectedBox?.top - move.h,
            selectedBox?.id
          );
          break;
        case 'ArrowDown':
          moveBoxInfinite(
            selectedBox?.left,
            selectedBox?.top + move.h,
            selectedBox?.id
          );
          break;
        case 'ArrowLeft': {
          moveBoxInfinite(
            selectedBox?.left - move.w,
            selectedBox?.top,
            selectedBox?.id
          );
          break;
        }
        case 'ArrowRight': {
          moveBoxInfinite(
            selectedBox?.left + move.w,
            selectedBox?.top,
            selectedBox?.id
          );
          break;
        }
        default:
          break;
      }
    }
  }, 1), [
    selectedBox?.id,
    selectedBox?.left,
    selectedBox?.top,
    boxIsMovable,
    eventManagerEnabled,
    moveBoxInfinite,
  ]);

  const handleArrowKeys = useCallback(
    (event) => {
      if (eventManagerEnabled && ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown'].includes(event.key))
        event.preventDefault();
      updateBoxPosition(event)
    },
    [
      updateBoxPosition,
      eventManagerEnabled
    ]
  );

  const handleRemoveKeys = useCallback(
    (event) => {
      if (selectedBox?.id && eventManagerEnabled) {
        switch (event.key) {
          case 'Delete': {
            event.preventDefault();
            setEventManagerEnabled(false);
            confirm({
              title: "Supprimer l'élément selectionné ?",
              okText: 'Oui',
              cancelText: 'Non',
              onCancel: () => {
                setEventManagerEnabled(true);
              },
              onOk: () => {
                removeBox(selectedBox?.id);
                setEventManagerEnabled(true);
              },
            });
            break;
          }
          case 'Backspace': {
            event.preventDefault();
            setEventManagerEnabled(false);
            confirm({
              title: "Supprimer l'élément selectionné ?",
              okText: 'Oui',
              cancelText: 'Non',
              onCancel: () => {
                setEventManagerEnabled(true);
              },
              onOk: () => {
                removeBox(selectedBox?.id);
                setEventManagerEnabled(true);
              },
            });
            break;
          }
          default:
            break;
        }
      }
    },
    [selectedBox?.id, eventManagerEnabled, setEventManagerEnabled, removeBox]
  );

  const handleSearchUrlChange = useCallback(
    (event) => {
      if (!fromPdf) { setCurrentDocument(getUrlParamsObject()); }
    },
    [setCurrentDocument, fromPdf]
  );

  const resetSlider = useCallback(
    (e) => {
      if (slider?.from?.value) {
        setSlider(defaultSlider);
      }
    },
    [setSlider, slider.from.value]
  );

  useEffect(() => {
    if (selectedBox && !boxVariableSelectedId) {
      window.addEventListener('keydown', handleArrowKeys);
      window.addEventListener('keydown', handleCtrlCopy);
      window.addEventListener('keydown', handleRemoveKeys);
      return () => {
        window.removeEventListener('keydown', handleArrowKeys);
        window.removeEventListener('keydown', handleCtrlCopy);
        window.removeEventListener('keydown', handleRemoveKeys);
      };
    }
  }, [
    selectedBox?.id,
    handleArrowKeys,
    handleCtrlCopy,
    selectedBox,
    resetSlider,
    handleRemoveKeys,
    boxVariableSelectedId
  ]);

  useEffect(() => {
    window.addEventListener(searchUrlChangedEvent, handleSearchUrlChange);
    return () => {
      window.removeEventListener(searchUrlChangedEvent, handleSearchUrlChange);
    };
  }, [handleSearchUrlChange]);

  return <div onMouseUp={resetSlider}>{children}</div>;
};

export default EventManager;
