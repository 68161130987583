import React from 'react'
import { useStore } from '../../store/store';
import { columnsDispositions } from '../../constants/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const ColumnPreview = ({ size = 1, isDraw }) => {
  const width = 80 * size;

  return (
    <div style={{
      width,
      height: 20,
      borderRadius: 3,
      border: '1.5px dashed #7bb7ff'
    }}>
      {isDraw && <div style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: '100%'
      }}>
        <FontAwesomeIcon
          icon={icon({ name: 'paintbrush-pencil', style: 'solid' })}
          style={{ margin: 0, fontSize: 12, color: '#7bb8ff' }}
        />
      </div>
      }
    </div>
  )
}

const constructPreviewColumns = ({ sizes, isDraw }) => sizes.map((size, idx) => <ColumnPreview
  size={size}
  key={idx}
  isDraw={isDraw}
/>)

const columns = (filterColumn) => columnsDispositions.filter(filterColumn);

const selector = (columnId, containerId) => ({
  addContainers,
  updateColumnsInContainer,
  containers
}) => {
  const container = columnId || containerId ? containers.find((c) => containerId ?
    c.id === containerId : c.columnsIds.includes(columnId))
    : undefined
  return {
    addContainers,
    updateColumnsInContainer,
    container
  }
};

const AdderMenu = ({ containerIndex, closeMenu, columnId, columnIndex, containerId }) => {
  const { addContainers, updateColumnsInContainer, container } = useStore(selector(columnId, containerId));
  const filterColumn = (c) => {
    if (!container) return true
    if (columnId) return container.columnsIds.length < c.key + 1
    return container.columnsIds.length === c.key + 1
  }

  const onAdderClick = ({ sizes, drawMode }) => {
    if (columnId || containerId) {
      updateColumnsInContainer({ columnsSizes: sizes, index: columnIndex, containerId: container.id })
    } else {
      addContainers({ index: containerIndex, columnsSizes: sizes, drawMode });
    }
    closeMenu();
  }

  return (
    <div style={{
      position: "absolute",
      top: "calc(100%)",
      padding: 16,
      boxShadow: "rgb(94, 94, 94) 0px 0px 15px -4.6px",
      display: "flex",
      flexDirection: "column",
      gap: 32,
      borderRadius: 12,
      width: 350,
      zIndex: 1001,
      backgroundColor: "white"
    }}>
      {columns(filterColumn).map((col) =>
        <div key={col.key} style={{
          display: "flex", alignItems: "baseline", whiteSpace: "nowrap", gap: 24,
        }}>
          <div style={{ display: "flex", justifyContent: "space-between", width: 80 }}><span>{col.title}</span>
            <span> :</span></div>
          <div style={{ display: "flex", gap: 10, flexWrap: "wrap" }}>
            {col.dispositions.map((choice) => <div className='adder-container-menu-button'
              onClick={() => onAdderClick({ sizes: choice.sizes, drawMode: choice.isDraw })}
              key={choice.key}>
              {constructPreviewColumns({ ...choice })}
            </div>)}
          </div>
        </div>)}
    </div>
  )
}

export default AdderMenu
