import React, { useMemo, useRef, useState } from 'react';
import * as documentDatasApi from '../../requests/documentDatas';
import { getImageStyle } from './imageUtils';
import ImageCascader from './ImageCascader';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

// Common
import './../common/Utils.css';
import { useStore } from '../../store/store';
import Img from './placeholder-img.png';
import { Upload, message } from 'antd';
import { convertFileToBase64 } from '../../utils/file';
import { isAnUrl } from '../../utils/browser';
import { findWithRegex } from '../../utils/dataSync';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';
import { isAImgBox, isAVariableBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { MASKS_TYPE } from '../../constants/constants';
import ButtonRefresh from '../common/ButtonRefresh';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import VariableSwitcher from '../common/VariableSwitcher';
const selector =
  (id) =>
    ({
      removeBox,
      updateBox,
      configuration,
      selectedBoxId,
      currentDocument,
      currentProposal,
    }) => ({
      currentDocument,
      removeBox,
      updateBox,
      configuration,
      isSelected: selectedBoxId === id,
      currentProposal,
    });

const Image = ({ box, updateImage, fromViewer }) => {
  const { currentDocument } = useStore(selector(box.id));
  const { updateLeftShownStatus } = useOverlay();
  const updateBox = useStore(({ updateBox }) => updateBox);
  const defaultVariablesApi = useStore(({ defaultVariablesApi }) => defaultVariablesApi)
  const [retryCount, setRetryCount] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const ref = useRef();
  const { t } = useTranslation();

  const defaultVariableApiSrc = useMemo(() => {
    if (!box.content.adminValue || !defaultVariablesApi) return null

    const keys = box.content.adminValue.split('.')
    if (keys.length === 3) {
      const indexKey = keys[0]
      const originKey = Object.keys(defaultVariablesApi.opportunities_variables[indexKey])[0]
      const categoryKey = keys[1]
      const informationKey = keys[2]
      return defaultVariablesApi.opportunities_variables[indexKey][originKey][categoryKey][informationKey]
    }

    const categoryKey = keys[0]
    const informationKey = keys[1]

    if (defaultVariablesApi.offer_variables) {
      return defaultVariablesApi.offer_variables[categoryKey][informationKey]
    }

    return defaultVariablesApi[categoryKey][informationKey]
  }, [box.content.adminValue, defaultVariablesApi])

  const getNewImageBoxFromRefresh = (oldBox) => {
    let newBox = { ...oldBox }
    if (defaultVariableApiSrc) {
      newBox.content = { ...newBox.content, src: defaultVariableApiSrc }
    } else {
      const keys = box.content.adminValue.split(".")
      const lastKey = keys[keys.length - 1]
      const beforeLastKey = keys[keys.length - 2]
      newBox.content = { ...newBox.content, src: `#{${beforeLastKey}.${lastKey}}` }
    }
    newBox.content = { ...newBox.content, ratio: null }
    newBox.height = 'auto';
    return newBox
  }

  const handleOnClickRefresh = (e) => {
    e.stopPropagation()
    if (updateImage) {
      updateImage(getNewImageBoxFromRefresh(box))
    }
    else {
      updateBox(box.id, (box) => {
        box = getNewImageBoxFromRefresh(box)
      });
    }
  }

  const regex = new RegExp(/#{.*?}/, 'm');
  const src = isAnUrl(box.content.src) ? box.content.src : Img;
  const desc = isAnUrl(box.content.src)
    ? null
    : findWithRegex(regex, box.content.src)
      ? t(box.content.src?.slice(2, -1))
      : null;

  const waitAndReload = (event) => {
    const originalSrc = event.target.src;
    if (retryCount < 3) {
      event.target.src = Img;
      setTimeout(function () {
        // console.log('retrying to load image');
        event.target.src = originalSrc;
        setRetryCount(retryCount + 1);
      }, 300);
    } else {
      event.target.src = Img;
    }
  }

  return (
    <div style={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onDoubleClick={(event) => {
        if (isAVariableBox(box?.type)) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}
    >
      {currentDocument.type !== 'template' &&
        box.content.adminValue &&
        box.content.src !== defaultVariableApiSrc &&
        !fromViewer && isHovered &&
        <div style={{
          position: 'absolute',
          zIndex: 9999,
          top: 0,
          right: 0
        }}>
          <ButtonRefresh handleOnClickRefresh={handleOnClickRefresh} />
        </div>
      }
      <img
        ref={ref}
        alt='rand img'
        onError={waitAndReload}
        src={src.replace('http://', 'https://')}
        style={{
          ...getImageStyle(
            box.backgroundColor,
            box.border,
            currentDocument.type === 'template',
          ),
          width: box.width,
          height: box.height,
        }}
      />
      <p
        style={{
          position: 'absolute',
          top: '15%',
          left: '15%',
          fontWeight: 'bold',
          pointerEvents: 'none'
        }}
      >
        {desc}
      </p>
    </div>
  );
};

export const ImageComponent = ({ box, updateImage }) => {
  const { updateBox, currentDocument, currentProposal } = useStore(
    selector(box?.id)
  );
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const { t } = useTranslation();

  const getNewImageBoxFromLoad = (oldBox, res, imgEl, column, columnWidth) => {
    const box = oldBox;
    box.content = { ...box.content, src: res.url, ratio: imgEl.width / imgEl.height };
    box.height = 'auto';
    if (column && !column.drawMode) {
      box.width = reinitializeBoxWidthAndHeight({
        header,
        footer,
        box,
        columnWidth,
      }).width
    }
    return box
  }


  const getNewImageBoxFromAdmin = (oldBox, adminValue, srcValue) => {
    const box = oldBox;
    box.content = { ...box.content, src: srcValue, ratio: null };
    box.height = 'auto';
    box.content.adminValue = adminValue
    return box
  }

  const handleUploadImg = async (file) => {
    const img = await convertFileToBase64(file.file);
    const imgEl = document.createElement('img');
    let id = 0;
    if (currentDocument.type === 'template') {
      id = currentDocument.id;
    } else {
      id = currentProposal.id;
    }
    documentDatasApi.postImage(id, currentDocument.type, {
      img: /base64,(.+)/.exec(img)[1],
      filename: file.file.name,
    }).then((res) => {
      imgEl.onload = () => {
        if (updateImage) {
          updateImage(getNewImageBoxFromLoad(box, res, imgEl))
        }
        else {
          updateBox(box.id, (box, column, columnWidth) => {
            box = getNewImageBoxFromLoad(box, res, imgEl, column, columnWidth)
          });
        }
      };
      imgEl.src = img;
    }).catch((err) => {
      if (err.response.status === 422)
        message.error({
          content: "La taille de l'image ne doit pas dépasser 5Mo",
          key: 422,
        })
    });
  };

  return (
    <div>
      <div className='navbar__img-properties-container' style={{
        position: 'relative'
      }}>
        <VariableSwitcher boxId={box.id} />
        <div className='navbar__imgtopmid-properties-container'>
          <Upload
            accept="image/png, image/gif, image/jpeg"
            name='file'
            customRequest={(e) => {
              handleUploadImg(e);
            }}
            showUploadList={false}
            onMouseDown={(e) => e.preventDefault()}
          >
            <NavbarButton
              tooltipContent={t('download')}
              disabled={!box || !isAImgBox(box.type)}
              icon={< FontAwesomeIcon icon={icon({ name: 'upload', style: 'light' })} style={iconButtonStyle()} />}
            />
          </Upload>
        </div>
        <div className='navbar__imgbotmid-properties-container'>
          <ImageCascader
            onLoadImage={(srcValue, adminValue) => {
              if (updateImage) {
                updateImage(getNewImageBoxFromAdmin(box, adminValue, srcValue))
              }
              else {
                updateBox(box.id, (box, column, columnWidth) => {
                  box = getNewImageBoxFromAdmin(box, adminValue, srcValue)
                });
              }
            }}
            selectedBox={box}
          />
        </div>
      </div>
    </div>
  );
};

export default React.memo(Image);
