import { EditorState, Modifier, SelectionState, convertToRaw } from 'draft-js';
import { getEntities, translateVariable } from '../components/RichEditor/utils';
import {
  isATextBox,
  isATitleBox,
  isAVariableBox,
  simulateVariableGroupBox,
  simulateVariableTextBox,
} from './boxes';
import { ItemTypes, variableHandlingType } from '../constants/constants';
import {
  replaceVariablesInPlainText,
  transformTextToEditorState,
} from './dataSync';

const replaceVariableText = ({
  entity,
  variables,
  blockKey,
  start,
  end,
  contentState,
  entityKey,
  editorState,
}) => {
  let newContentState = contentState;
  let modifiedEditorState = editorState;
  const { key } = entity.getData();
  const variableTranslated = translateVariable({
    variableKey: key,
    variables,
  });
  if (variables[key].notUsed) {
    delete variables[key].notUsed;
  }
  const selectionState = SelectionState.createEmpty(blockKey).merge({
    anchorOffset: start,
    focusOffset: end,
  });
  const contentBlock = newContentState.getBlockForKey(blockKey);
  newContentState = Modifier.replaceText(
    newContentState,
    selectionState,
    variableTranslated,
    contentBlock.getInlineStyleAt(start),
    entityKey
  );
  modifiedEditorState = EditorState.push(
    modifiedEditorState,
    newContentState,
    'insert-characters'
  );
  return { newContentState, modifiedEditorState };
};

export const replaceVariables = (editorState, variables) => {
  const entitiesLength = getEntities(editorState).length;
  let newContentState = editorState.getCurrentContent();
  let modifiedEditorState = editorState;

  for (let i = 0; i < entitiesLength; i += 1) {
    const { entityKey, start, end, blockKey } =
      getEntities(modifiedEditorState)[i];
    const entity = newContentState.getEntity(entityKey);
    if (entity.getType() === 'VARIABLE') {
      const {
        modifiedEditorState: newestEditorState,
        newContentState: newestContentState,
      } = replaceVariableText({
        entity,
        variables,
        blockKey,
        start,
        end,
        contentState: newContentState,
        entityKey,
        editorState: modifiedEditorState,
      });
      newContentState = newestContentState;
      modifiedEditorState = newestEditorState;
    }
    if (entity.getType() === 'IMAGE') {
      const { box, menuAncestorType, menuAncestorIds } = entity.getData();
      const newImageBox = {
        ...box,
        content: {
          ...box.content,
          src: replaceVariablesInPlainText(box.content.src, variables),
        },
      };
      newContentState = newContentState.replaceEntityData(entityKey, {
        box: newImageBox,
        menuAncestorType,
        menuAncestorIds,
      });
    }
  }
  return modifiedEditorState;
};

export const removeVariables = (editorState, variableKeys) => {
  const entities = getEntities(editorState);
  let newContentState = editorState.getCurrentContent();
  let modifiedEditorState = editorState;

  entities.reverse().forEach(({ entityKey, start, end, blockKey }) => {
    const entity = newContentState.getEntity(entityKey);
    if (entity.getType() !== 'VARIABLE') return;
    const { key } = entity.getData();
    if (!variableKeys.includes(key)) return;

    const selectionState = SelectionState.createEmpty(blockKey).merge({
      anchorOffset: start,
      focusOffset: end,
    });
    newContentState = Modifier.applyEntity(
      newContentState,
      selectionState,
      null
    );

    modifiedEditorState = EditorState.push(
      modifiedEditorState,
      newContentState,
      'apply-entity'
    );
  });
  return modifiedEditorState;
};

export const getVariablesGroupedByContainer = ({
  variableGroups,
  variables,
  boxes,
  containers,
  columns,
}) => {
  const variablesBoxes = boxes.filter((b) => isAVariableBox(b.type));
  const variablesGroupedByContainer = [];
  const orphanCustomTextVariables = {
    ...variables,
  };

  variableGroups.forEach((vb) => {
    const variableIds = [];
    vb.variableIds.forEach((vId) => {
      if (orphanCustomTextVariables[vId]) {
        variableIds.push(vId);
        delete orphanCustomTextVariables[vId];
      }
    });
    variablesGroupedByContainer.push({
      containerId: vb.id,
      variableGroupBox: {
        ...simulateVariableGroupBox(vb),
        notUsed: !vb.variableIds.some(
          (id) => variables[id] && !variables[id]?.notUsed
        ),
      },
      type: variableHandlingType.OTHER,
      variablesBoxes: variableIds
        .filter((vId) => variables[vId])
        .map((vId) => {
          return simulateVariableTextBox([vId, variables[vId]]);
        }),
    });
  });

  Object.entries(orphanCustomTextVariables).forEach(([key, variable]) => {
    variablesGroupedByContainer.push({
      containerId: key,
      type: variableHandlingType.OTHER,
      variablesBoxes: [simulateVariableTextBox([key, variable])],
    });
  });

  variablesBoxes.forEach((box) => {
    const boxContainerId = containers.find((c) =>
      c.columnsIds.includes(box.columnId)
    ).id;

    const drawMode = columns.find((c) => c.id === box.columnId).drawMode;
    const sameContainerIndex = variablesGroupedByContainer.findIndex(
      (vInfos) => vInfos.containerId === boxContainerId && !drawMode
    );

    if (sameContainerIndex !== -1) {
      variablesGroupedByContainer[sameContainerIndex].variablesBoxes.push(box);
    } else {
      variablesGroupedByContainer.push({
        containerId: boxContainerId,
        type: variableHandlingType.FORM,
        variablesBoxes: [box],
      });
    }
  });
  return variablesGroupedByContainer;
};

export const isVariableCustom = (key) => {
  const firstKey = key.substring(0, key.indexOf('.'));
  return firstKey === 'custom';
};

const editorStateHasEntity = (editorState, variableKey) => {
  const entities = getEntities(editorState);
  return entities.some(({ entityKey }) => {
    const entity = editorState.getCurrentContent().getEntity(entityKey);
    const { key } = entity.getData();
    return key === variableKey;
  });
};

export const boxContainsVariable = (variableKey, box) => {
  if (
    (isATextBox(box.type) || isATitleBox(box.type)) &&
    box.content.editorState
  ) {
    if (editorStateHasEntity(box.content.editorState, variableKey)) return true;
  }
  if (isAVariableBox(box.type) && box.name) {
    if (editorStateHasEntity(box.name, variableKey)) return true;
    if (
      box.type === ItemTypes.CHECKBOXS_VARIABLE &&
      box.checkboxs.some((c) => editorStateHasEntity(c.label, variableKey))
    )
      return true;
    if (
      box.type === ItemTypes.SELECTOR_VARIABLE &&
      box.options.some((c) => editorStateHasEntity(c.label, variableKey))
    )
      return true;
  }
  if (box.type === ItemTypes.TABLE) {
    if (
      box.content.columns.some((c) =>
        editorStateHasEntity(transformTextToEditorState(c.title), variableKey)
      )
    )
      return true;
    if (
      box.content.data.some((d) => {
        const keys = Object.keys(d);
        return keys.some(
          (key) =>
            key !== 'key' &&
            editorStateHasEntity(
              transformTextToEditorState(d[key]),
              variableKey
            )
        );
      })
    )
      return true;
  }
  return false;
};

export const mutateInjectVariablesInBox = ({ variables, box }) => {
  if (isATextBox(box.type) || isATitleBox(box.type)) {
    box.content.editorState = replaceVariables(
      box.content.editorState,
      variables
    );
  }
  if (isAVariableBox(box.type)) {
    box.name = replaceVariables(box.name, variables);
    if (box.type === ItemTypes.CHECKBOXS_VARIABLE) {
      box.checkboxs = box.checkboxs.map((c) => ({
        ...c,
        label: replaceVariables(c.label, variables),
      }));
    }

    if (box.type === ItemTypes.SELECTOR_VARIABLE) {
      box.options = box.options.map((c) => ({
        ...c,
        label: replaceVariables(c.label, variables),
      }));
    }
  }
  if (box.type === ItemTypes.TABLE) {
    box.content.columns = box.content.columns.map((c) => ({
      ...c,
      title: JSON.stringify(
        convertToRaw(
          replaceVariables(
            transformTextToEditorState(c.title),
            variables
          ).getCurrentContent()
        )
      ),
    }));
    box.content.data = box.content.data.map((d) => {
      const keys = Object.keys(d);
      const newData = {};
      keys.forEach((key) => {
        if (key === 'key') {
          newData[key] = d[key];
        } else {
          newData[key] = JSON.stringify(
            convertToRaw(
              replaceVariables(
                transformTextToEditorState(d[key]),
                variables
              ).getCurrentContent()
            )
          );
        }
      });
      return newData;
    });
  }
  return box;
};

export const mutateInjectVariablesInDocument = ({
  variables,
  variable,
  variableKey,
  draft,
}) => {
  const boxes = [...draft.boxes, ...draft.masks.map((m) => m.boxes).flat()];
  boxes.forEach((b) => {
    if (!boxContainsVariable(variableKey, b)) return;
    mutateInjectVariablesInBox({
      variables: {
        ...variables,
        [variableKey]: variable,
      },
      box: b,
    });
  });
};
