import {
  convertContentToRawText,
  decorator,
} from '../components/RichEditor/utils';
import { MIN_COLUMN_WIDTH } from '../components/Table';
import {
  defaultItemValues,
  defaultSlider,
  ItemTypes,
  MASKS_TYPE,
  uuid,
} from '../constants/constants';
import {
  calculateWidth,
  marginsObject,
  masksDefaultHeight,
  pageHeight,
  pageWidth,
} from '../constants/gridConfig';
import i18n from '../i18n';
import { computeColumnWidth } from './columns';
import { maxContainerHeight, mutateAddContainers } from './containers';
import { isAnUrl } from './browser';
import { getIncrementedName } from './general';
import { mutateCreateMask, mutateInjectMask } from './masks';
import { mutateResizeSummary, mutateTitlesIndexes } from './titles';
import { ContentState, EditorState } from 'draft-js';
export const isATitleBox = (type) =>
  [
    ItemTypes.TITLE,
    ItemTypes.TITLE_2,
    ItemTypes.TITLE_3,
    ItemTypes.TITLE_4,
  ].includes(type);

export const isAOneRowBox = (type) =>
  [
    ItemTypes.TITLE,
    ItemTypes.TITLE_2,
    ItemTypes.TITLE_3,
    ItemTypes.TITLE_4,
    ItemTypes.SUMMARY,
    ItemTypes.TABLE,
    ItemTypes.MASK,
    ItemTypes.DRAW,
    ItemTypes.SECTION,
    ItemTypes.SECTION_VARIABLE,
    ItemTypes.LINE_BREAK,
    ItemTypes.FILE_PDF
  ].includes(type);

export const isADrawBox = (type) =>
  [
    ItemTypes.MASK,
    ItemTypes.DRAW,
    ItemTypes.SECTION,
    ItemTypes.SECTION_VARIABLE,
    ItemTypes.FILE_PDF,
  ].includes(type);

export const isADefaultBox = (type) => [ItemTypes.LINE_BREAK].includes(type);

export const isAVariableBox = (type) =>
  [
    ItemTypes.CHECKBOXS_VARIABLE,
    ItemTypes.SELECTOR_VARIABLE,
    ItemTypes.TEXT_VARIABLE,
    ItemTypes.SECTION_VARIABLE,
    ItemTypes.IMG_VARIABLE,
    ItemTypes.TABLE_VARIABLE,
  ].includes(type);

export const isASelectionBox = (type) =>
  [ItemTypes.MASK, ItemTypes.SECTION, ItemTypes.SECTION_VARIABLE, ItemTypes.FILE_PDF].includes(
    type
  );

export const isAWritableBox = (type) =>
  [
    ItemTypes.TEXT,
    ItemTypes.TEXT_VARIABLE,
    ItemTypes.TABLE,
    ItemTypes.TITLE,
    ItemTypes.TITLE_2,
    ItemTypes.TITLE_3,
    ItemTypes.TITLE_4,
  ].includes(type);

export const isATextBox = (type) =>
  [ItemTypes.TEXT, ItemTypes.TEXT_VARIABLE].includes(type);
export const isATableBox = (type) =>
  [ItemTypes.TABLE, ItemTypes.TABLE_VARIABLE].includes(type);
export const isAImgBox = (type) =>
  [ItemTypes.IMG, ItemTypes.IMG_VARIABLE].includes(type);

export const boxBounding = (box) => {
  const width = boxBoundingWidth(box);
  const height = boxBoundingHeight(box);
  return {
    width,
    height,
    top: box.clientTop,
    left: box.clientLeft,
    bottom: box.clientTop + height,
    right: box.clientLeft + width,
    vertical: box.clientTop + height / 2,
    horizontal: box.clientLeft + width / 2,
  };
};

export const boxBoundingHeight = (box) => {
  if (!box.clientHeight) {
    console.error(
      'boxBoundingHeight',
      `box.clientHeight  for box ${box.id} is undefined`
    );
  }
  // bounding Rect heigth is always vertical
  return box.clientHeight;
  // if (box.rotation && ((box.rotation > 90 && box.rotation < 180) || (box.rotation > 270 && box.rotation < 360))) {
  //   if (!box.clientWidth) { console.error('boxBoundingHeight', `box.clientWidth  for box ${box.id} is undefined`);}
  //   return box.clientWidth;
  // } else {
  //   return box.clientHeight;
  // }
};
export const boxHeight = (box) => {
  let h = boxContentHeight(box);
  if (!h) {
    console.error('boxHeight', 'boxHeight can not be computed');
  }
  return h;
};
export const boxContentHeight = (box) => {
  switch (box.type) {
    case ItemTypes.IMG:
    case ItemTypes.IMG_VARIABLE:
      if (box.content.ratio) {
        return box.width / box.content.ratio - 3;
      } else {
        return box.width - 15; // default image => ratio 1
      }
    default:
      if (box.rotation && box.rotation > 0) {
        if (!box.clientHeight || !box.clientWidth || !box.width) {
          console.log(
            'boxContentHeight',
            `box.clientHeight or box.clientWidth or box.width for box ${box.id} is undefined`
          );
        }
        return (
          (boxBoundingWidth(box) -
            box.width * Math.cos((box.rotation * Math.PI) / 180)) /
          Math.sin((box.rotation * Math.PI) / 180)
        );
      } else {
        return box.clientHeight;
      }
  }
};
export const boxBoundingWidth = (box) => {
  if (!box?.clientWidth) {
    console.error(
      'boxBoundingWidth',
      `box.clientWidth  for box ${box.id} is undefined`
    );
  }
  return box.clientWidth;
  // if (box.rotation && ((box.rotation > 90 && box.rotation < 180) || (box.rotation > 270 && box.rotation < 360))) {
  //   if (!box.clientHeight) { console.error('boxBoundingWidth', `box.clientHeight  for box ${box.id} is undefined`);}
  //   return box.clientHeight;
  // } else {
  //   return box.clientWidth;
  // }
};
export const boxWidth = (box) => {
  let w = boxContentWidth(box);
  if (!w) {
    console.error('boxWidth', 'boxWidth can not be computed');
  }
  return w;
};
export const boxContentWidth = (box) => {
  let totalWidth;
  if (box.type === ItemTypes.TABLE) {
    totalWidth = box.columns.reduce((acc, col) => acc + col.width, 0);
  } else {
    totalWidth = box.width;
  }
  if (!totalWidth) {
    console.error('boxContentWidth', 'box.width is not defined');
  }
  return totalWidth;
};

export const boxVerticalSize = (box) => {
  if (
    box.rotation &&
    ((box.rotation > 90 && box.rotation < 180) ||
      (box.rotation > 270 && box.rotation < 360))
  ) {
    return boxWidth(box);
  } else {
    return boxHeight(box);
  }
};
export const boxHorizontalSize = (box) => {
  let hs;
  if (
    box.rotation &&
    ((box.rotation > 90 && box.rotation < 180) ||
      (box.rotation > 270 && box.rotation < 360))
  ) {
    hs = boxHeight(box);
  } else {
    hs = boxWidth(box);
  }
  return hs;
};
export const boxBoundingTop = (box) => {
  if (!box.clientTop) {
    console.error(
      'boxBoundingTop',
      `box.clientTop  for box ${box.id} is undefined`
    );
  }
  return box.clientTop;
};

export const boxBoundingLeft = (box) => {
  if (!box.clientLeft) {
    console.error(
      'boxBoundingLeft',
      `box.clientLeft  for box ${box.id} is undefined`
    );
  }
  return box.clientLeft;
};

export const bBoxMove = ({ side, box, targetValue }) => {
  const bBoxMove = {
    top: 0,
    left: 0,
  };
  const bBox = boxBounding(box);
  if (side === 'top') {
    bBoxMove.top = targetValue - bBox.top;
  } else if (side === 'bottom') {
    bBoxMove.top = targetValue - bBox.bottom;
  } else if (side === 'left') {
    bBoxMove.left = targetValue - bBox.left;
  } else if (side === 'right') {
    bBoxMove.left = targetValue - bBox.right;
  } else if (side === 'vertical') {
    bBoxMove.top = targetValue - bBox.vertical;
  } else if (side === 'horizontal') {
    bBoxMove.left = targetValue - bBox.horizontal;
  }
  return bBoxMove;
};

export const boxAffectedSide = (alignmentSide) => {
  if (alignmentSide === 'right' || alignmentSide === 'horizontal') {
    return 'left';
  } else if (alignmentSide === 'bottom' || alignmentSide === 'vertical') {
    return 'top';
  } else {
    return alignmentSide;
  }
};

export const getAlignedBoxTargetValue = ({ draft, selectedBox, side }) => {
  const column = draft.columns.find((c) => c.id === selectedBox.columnId);
  const mask = draft.masks.find((m) => m.id === selectedBox.maskId);
  const hasVerticalMargin =
    (column &&
      (draft.landscape ||
        (column.drawMode && (column.isOldPage || column.maskId)))) ||
    (mask &&
      mask.id !== MASKS_TYPE.HEADER.id &&
      mask.id !== MASKS_TYPE.FOOTER.id);
  const margins = marginsObject(draft.configuration, hasVerticalMargin);
  let targetValue;

  if (side === 'top') {
    targetValue = hasVerticalMargin ? margins[side] : 0;
  } else if (side === 'left') {
    targetValue = margins[side];
  } else if (side === 'right') {
    targetValue = draft.landscape
      ? pageHeight - margins.right
      : pageWidth - margins.right;
  } else if (side === 'bottom') {
    if (hasVerticalMargin) {
      targetValue = draft.landscape
        ? pageWidth - margins.bottom
        : pageHeight - margins.bottom;
    } else if (column) {
      const containerHeight = draft.containers.find((c) =>
        c.columnsIds.includes(column.id)
      ).height;
      targetValue = containerHeight;
    } else if (mask.id === MASKS_TYPE.FOOTER.id) {
      targetValue = mask.height ?? masksDefaultHeight.footer;
    } else {
      targetValue = mask.height ?? masksDefaultHeight.header;
    }
  }
  return targetValue;
};

export const alignedBoxValues = ({ side, box, targetValue }) => {
  const bBoxMoveValues = bBoxMove({ side, box, targetValue });
  const boxPropertyside = boxAffectedSide(side);
  const moveValue = bBoxMoveValues[boxPropertyside]; // + delta;

  return {
    propertyToAlign: boxPropertyside,
    value: moveValue,
  };
};

export const rotatedBoxDelta = ({ rotation, direction, x, y }) => {
  // Direction corresponds to the handler selected, could be "right" or "bottom"
  let width, height;
  if (rotation === 0 || !rotation) {
    width = direction === 'right' ? x : 0;
    height = direction === 'bottom' ? y : 0;
  } else {
    width = direction === 'right' ? x : 0;
    height =
      direction === 'bottom'
        ? y * Math.cos((rotation * Math.PI) / 180) -
          x * Math.sin((rotation * Math.PI) / 180)
        : 0;
  }
  return {
    width,
    height,
  };
};

export const getRatioMove = ({ width, height }, ratio) => {
  // ratio = width / height
  if (!ratio) return { width, height };
  return {
    width,
    height: width / ratio,
  };
};

export const getBoxBaseCornerRealPosition = (box, initialHeight) => {
  const { left: boxLeft, top: boxTop, width } = box;
  const realHeight = initialHeight ?? boxHeight(box);

  const boxCenterLeft = boxLeft + width / 2;
  const boxCenterTop = boxTop + realHeight / 2;
  const radius = Math.sqrt(
    Math.pow(width / 2, 2) + Math.pow(realHeight / 2, 2)
  );
  const cornerAngle = (Math.atan(realHeight / width) * 180) / Math.PI;
  const rotatedBoxBaseCornerLeft =
    boxCenterLeft -
    radius * Math.cos(((box.rotation + cornerAngle) * Math.PI) / 180);
  const rotatedBoxBaseCornerTop =
    boxCenterTop -
    radius * Math.sin(((box.rotation + cornerAngle) * Math.PI) / 180);
  return {
    top: rotatedBoxBaseCornerTop,
    left: rotatedBoxBaseCornerLeft,
  };
};

export const getRotatedBoxPositionAfterResize = (
  box,
  newWidth,
  newHeight,
  initialHeight
) => {
  const rotatedBoxBaseCornerPosition = getBoxBaseCornerRealPosition(
    box,
    initialHeight
  );
  const newRadius = Math.sqrt(
    Math.pow(newWidth / 2, 2) + Math.pow(newHeight / 2, 2)
  );
  const newCornerAngle = (Math.atan(newHeight / newWidth) * 180) / Math.PI;
  const newBoxCenterLeft =
    rotatedBoxBaseCornerPosition.left +
    newRadius * Math.cos(((box.rotation + newCornerAngle) * Math.PI) / 180);
  const newBoxCenterTop =
    rotatedBoxBaseCornerPosition.top +
    newRadius * Math.sin(((box.rotation + newCornerAngle) * Math.PI) / 180);
  return {
    top: newBoxCenterTop - newHeight / 2,
    left: newBoxCenterLeft - newWidth / 2,
  };
};

// export const isInPage = (box, pageId) => box.pageId === pageId;

export const isAfterFrom = (box, fromValue) =>
  box.top + boxBoundingHeight(box) > fromValue;
export const isBeforeFrom = (box, fromValue) => box.top < fromValue;
export const isInMask = (box) => !!box.maskId;

export const CopyBox = (box, setCopy) => {
  const newBox = {
    ...box,
  };
  delete newBox.left;
  delete newBox.top;
  delete newBox.id;
  delete newBox.maskId;
  delete newBox.idForMask;
  setCopy(newBox);
};

export const orderBoxesByContainer = ({ containers, boxesToSort }) => {
  const columnsIdOrdered = containers.map((cont) => cont.columnsIds).flat();
  boxesToSort.sort((boxA, boxB) => {
    if (boxA.columnId !== boxB.columnId && columnsIdOrdered) {
      return (
        columnsIdOrdered.indexOf(boxA.columnId) -
        columnsIdOrdered.indexOf(boxB.columnId)
      );
    }
    const boxAHasText = isATitleBox(boxA.type) || isATextBox(boxA.type);
    const boxBHasText = isATitleBox(boxB.type) || isATextBox(boxB.type);
    if (boxAHasText && !boxBHasText) {
      return 1; // A après B
    } else if (!boxAHasText && boxBHasText) {
      return -1; // A avant B
    } else {
      return boxA.top - boxB.top; // A avant B si negatif
    }
  });
  return boxesToSort;
};

export const mutateVariablesBoxes = (draft, maskId = null, newBoxes) => {
  const variablesBoxesNames = draft.boxes
    .filter(
      (box) =>
        isAVariableBox(box.type) && newBoxes.every((b) => b.id !== box.id)
    )
    .map((b) => convertContentToRawText(b.name));
  const newBoxesNames = [];
  orderBoxesByContainer({
    containers: draft.containers,
    boxesToSort: newBoxes,
  });
  return newBoxes.map((box) => {
    if (maskId || !isAVariableBox(box.type)) return box;

    const baseNameBox = box.name
      ? typeof box.name === 'string'
        ? box.name
        : convertContentToRawText(box.name)
      : i18n.t(box.type);
    const oldNames = variablesBoxesNames.concat(newBoxesNames);
    const newName = getIncrementedName(baseNameBox, oldNames);

    newBoxesNames.push(newName);
    return {
      ...box,
      name: EditorState.createWithContent(
        ContentState.createFromText(newName),
        decorator
      ),
    };
  });
};

export const mutateBoxesOrderInfinite = (draft, maskId = null) => {
  const boxesToSort =
    maskId === null
      ? draft.boxes
      : draft.masks.find((m) => m.id === maskId).boxes;
  orderBoxesByContainer({ containers: draft.containers, boxesToSort });
};

export const mutateAddBoxes = ({ draft, newBoxes = [], maskId = null }) => {
  newBoxes = mutateVariablesBoxes(draft, maskId, newBoxes);
  if (maskId === null) {
    draft.boxes.push(newBoxes);
    draft.boxes = draft.boxes.flat();
  } else {
    const mask = draft.masks.find((m) => m.id === maskId);
    let newMasksBoxes = newBoxes;
    if (maskId === MASKS_TYPE.HEADER.id || maskId === MASKS_TYPE.FOOTER.id) {
      const mask = draft.masks.find((m) => m.id === maskId);
      const newWidth =
        (maskId === MASKS_TYPE.HEADER.id
          ? mask.height ?? masksDefaultHeight.header
          : mask.height ?? masksDefaultHeight.footer) *
        (2 / 3);
      newMasksBoxes = newMasksBoxes.map((b) =>
        isAImgBox(b.type) ? { ...b, width: newWidth } : b
      );
    }
    mask.boxes.push(newMasksBoxes);
    mask.boxes = mask.boxes.flat();
  }
  mutateBoxesOrderInfinite(draft, maskId);
  if (newBoxes.some((box) => isATitleBox(box.type))) {
    mutateTitlesIndexes(draft, maskId);
    mutateResizeSummary(draft, maskId);
  }
};

export const sortBoxesForMove = (boxes, moveY) => {
  if (moveY > 0) {
    return sortBoxesToGoDown(boxes);
  }
  return sortBoxesToGoUp(boxes);
};

export const sortBoxesToGoDown = (boxes) => {
  return boxes.sort((boxA, boxB) => {
    const boxAHeight = boxBoundingHeight(boxA);
    const boxBHeight = boxBoundingHeight(boxB);
    return boxA.top + boxAHeight - (boxB.top + boxBHeight);
  });
};

export const sortBoxesToGoUp = (boxes) => {
  return boxes.sort((boxA, boxB) => boxB.top - boxA.top);
};

export const boxesToMoveInfinite = (boxes, fromValue) => {
  return boxes.filter((box) => {
    return box.top >= fromValue;
  });
};

export const mutateMoveBoxesBatchInfinite = (
  draft,
  moveObject,
  except = []
) => {
  const { to, from } =
    moveObject?.to && moveObject.from ? moveObject : draft.slider;
  const moveY = to.value - from.value;
  let boxesToMove = boxesToMoveInfinite(draft.boxes, from.value);
  boxesToMove.forEach((box) => {
    box.top += moveY;
  });
  draft.slider = defaultSlider;
};

export const mutateMoveBoxInfinite = (draft, left, top, boxId) => {
  const box = [...draft.boxes, ...draft.masks.map((m) => m.boxes).flat()].find(
    (box) => box.id === boxId
  );
  box.top = top;
  box.left = left;
  mutateBoxesOrderInfinite(draft);
  if (isATitleBox(box.type)) {
    mutateTitlesIndexes(draft);
    mutateResizeSummary(draft);
  }
};

export const mutateRemoveBox = (draft, id, maskId = null) => {
  const mask = draft.masks.find((m) => m.id === maskId);
  const boxesToUpdate = maskId === null ? draft.boxes : mask.boxes;
  const index = boxesToUpdate.findIndex((b) => b.id === id);
  const box = boxesToUpdate[index];
  boxesToUpdate.splice(index, 1);
  if (isATitleBox(box.type)) {
    mutateTitlesIndexes(draft, maskId);
    mutateResizeSummary(draft, maskId);
  }
};

export const mutateSelectContainer = (draft, container) => {
  draft.selectedContainer = container;
};
export const mutateSelectBox = (draft, box, group) => {
  // SI CTRL et une box est déjà sélectionnée
  if (group && draft.selectedBoxId) {
    // Si la box cliquée est dans le group de selection
    if (draft.groupSelection.includes(box.id)) {
      draft.groupSelection = draft.groupSelection.filter(
        (boxId) => boxId !== box.id
      );
      // SI box clickée est la box déjà sélectionnée
    } else if (draft.selectedBoxId === box.id) {
      // SI un groupe existe déjà
      if (draft.groupSelection.length > 0) {
        draft.selectedBoxId = draft.groupSelection[0];
        draft.groupSelection.shift();
      }
      // SI box clickée n'est pas la box déjà sélectionnée
    } else {
      // SI box clickée est dans la même page que la box selectionnée (maitre)
      //  TODO : Voir la gestion des groupes ?
      // Max une hauteur de page entre le haut de la première et la fin de la seconde ?
      // if (box.pageId === selectedBox.pageId) {
      draft.groupSelection.push(box.id);
      // }
    }
  } else {
    if (draft.selectedBoxId !== box.id) {
      draft.groupSelection = [];
      draft.selectedBoxId = box.id;
      draft.boxIsMovable = true;
    }
  }
};

export const initializeBoxContent = ({
  box,
  configuration,
  titles,
  mask,
  column,
  parentId,
}) => {
  /**
   * Add a new box
   */
  if (box.type === ItemTypes.TABLE) {
    box.width = calculateWidth(configuration.margins, 0, 0);
  }

  if (box.type === ItemTypes.LINE) {
    if (box.copied === false) {
      box.content = {
        color: configuration.shapeColor.style,
        style: 'solid',
        height: 5,
        side: 'Bottom',
      };
      delete box.copied;
    }
  } else if (box.type === ItemTypes.SHAPE) {
    if (box.copied === false) {
      box.content = {
        color: configuration.shapeColor.style,
        shape: 'square',
      };
      delete box.copied;
    }
  } else if (
    box.type === ItemTypes.TEXT ||
    box.type === ItemTypes.TEXT_VARIABLE
  ) {
    if (box.copied === false) {
      box.content = {
        text: '',
        editorState: EditorState.createEmpty(),
      };
      delete box.copied;
      if (box.type === ItemTypes.TEXT_VARIABLE) {
        box.width = configuration.textStyles[box.textStyleId].width;
      }
    }
  } else if (box.type === ItemTypes.SELECTOR_VARIABLE) {
    if (box.copied === false) {
      box.width = configuration.selectorStyles[box.selectorStyleId].width;
      box.options = [
        {
          selected: false,
          label: EditorState.createWithContent(
            ContentState.createFromText('Oui'),
            decorator
          ),
          id: uuid(),
        },
        {
          selected: false,
          label: EditorState.createWithContent(
            ContentState.createFromText('Non'),
            decorator
          ),
          id: uuid(),
        },
      ];
    }
  } else if (box.type === ItemTypes.CHECKBOXS_VARIABLE) {
    if (box.copied === false) {
      box.checkboxs = [
        {
          checked: false,
          label: EditorState.createWithContent(
            ContentState.createFromText('Oui'),
            decorator
          ),
          id: uuid(),
        },
        {
          checked: false,
          label: EditorState.createWithContent(
            ContentState.createFromText('Non'),
            decorator
          ),
          id: uuid(),
        },
      ];
    }
  } else if (box.type === ItemTypes.SUMMARY) {
    if (box.copied === false) {
      box.content = {
        configuration: {
          ...box.content.configuration,
          lineSummaryEnd: titles.length - 1,
          lineSummaryStart: titles.length - (titles.length - 1),
          indexTitle: box.content.indexTitle,
        },
      };
      delete box.copied;
    }
  }
  const newBox = {
    ...box,
    id: uuid(),
  };
  if (mask) {
    newBox.maskId = parentId;
    delete newBox.columnId;
  }
  if (column) {
    newBox.columnId = parentId;
    delete newBox.maskId;
  }
  return newBox;
};

const reninitializeTableWidth = ({ box, columnWidth }) => {
  const tableWidth = box.content.columns.reduce(
    (acc, col) => acc + col.width,
    0
  );
  const isReducingTable = tableWidth > columnWidth;

  let widthPerCol =
    Math.abs(tableWidth - columnWidth) / box.content.columns.length;
  let boxColsToChange = box.content.columns;
  let oldNumberOfColsToChange = null;

  while (
    boxColsToChange.length !== oldNumberOfColsToChange &&
    isReducingTable
  ) {
    oldNumberOfColsToChange = boxColsToChange.length;
    // eslint-disable-next-line no-loop-func
    boxColsToChange = boxColsToChange.filter(
      (c) => c.width - widthPerCol > MIN_COLUMN_WIDTH
    );
    widthPerCol = Math.abs(tableWidth - columnWidth) / boxColsToChange.length;
  }

  const columns = box.content.columns.map((c) => {
    if (boxColsToChange.some((col) => col.key === c.key))
      return {
        ...c,
        width: c.width - (isReducingTable ? widthPerCol : -widthPerCol),
      };
    return c;
  });

  return {
    ...box,
    content: {
      ...box?.content,
      columns,
    },
    width: columnWidth,
  };
};

export const reinitializeBoxWidthAndHeight = ({
  box,
  columnWidth,
  onResize = false,
  onCreate = false,
  header,
  footer,
}) => {
  if (
    onCreate === false &&
    box.type === ItemTypes.TABLE &&
    box.content?.columns?.length > 0
  ) {
    return reninitializeTableWidth({ box, columnWidth });
  }

  let width =
    typeof box.width === 'number' && box.width > columnWidth
      ? columnWidth
      : box.width;

  let height =
    typeof box.height === 'number'
      ? (width / box.width) * box.height
      : box.height;

  const isHeightWithPixelTooBig =
    typeof height === 'number' &&
    height > maxContainerHeight({ header, footer });

  const isHeightWithRatioTooBig =
    isAImgBox(box.type) &&
    box.content?.ratio &&
    width / box.content?.ratio > maxContainerHeight({ header, footer });

  if (isHeightWithPixelTooBig || isHeightWithRatioTooBig) {
    const c = isHeightWithPixelTooBig ? height : width / box.content?.ratio;
    if (typeof width === 'number') {
      width = (maxContainerHeight({ header, footer }) / c) * width;
    }
    if (isHeightWithPixelTooBig) {
      height = maxContainerHeight({ header, footer });
    }
  }

  if (
    !onResize &&
    (box.type === ItemTypes.TEXT ||
      box.type === ItemTypes.CHECKBOXS_VARIABLE ||
      box.type === ItemTypes.SELECTOR_VARIABLE ||
      box.type === ItemTypes.TEXT_VARIABLE)
  ) {
    width = columnWidth;
  }

  return {
    ...box,
    width,
    height,
  };
};

export const hasVariableBoxError = (box) => {
  if (box?.type === ItemTypes.CHECKBOXS_VARIABLE) {
    if (box.checkboxs.filter((c) => c.checked).length < box.minNumberOfAnwser) {
      return {
        error: true,
        message: `${i18n.t('number-of-answer-to-fill')} ${
          box.minNumberOfAnwser - box.checkboxs.filter((c) => c.checked).length
        }`,
      };
    }
  }
  if (box?.type === ItemTypes.SELECTOR_VARIABLE) {
    if (box.options.filter((c) => c.selected).length < box.minNumberOfAnwser) {
      return {
        error: true,
        message: `${i18n.t('number-of-answer-to-fill')} ${
          box.minNumberOfAnwser - box.options.filter((c) => c.selected).length
        }`,
      };
    }
  }
  if (box?.type === ItemTypes.TEXT_VARIABLE) {
    if (
      box.content.editorState.getCurrentContent().getPlainText().length === 0 &&
      box.value_required
    ) {
      return {
        error: true,
        message: `${i18n.t('required-text-input')}`,
      };
    }
  }
  if (box?.type === ItemTypes.SECTION_VARIABLE) {
    if (!box.answer) {
      return {
        error: true,
        message: `${i18n.t('non-resolved-question')}`,
      };
    }
  }
  if (box?.type === ItemTypes.CUSTOM_TEXT_VARIABLE) {
    if (!box.value) {
      return {
        error: true,
        message: `${i18n.t('missing-value')}`,
      };
    }
  }
  if (box?.type === ItemTypes.IMG_VARIABLE) {
    if (!isAnUrl(box.content.src)) {
      return {
        error: true,
        message: `${i18n.t('missing-value')}`,
      };
    }
  }
  return { error: false, message: '' };
};

export const getVariableSelectionKey = (boxType) => {
  switch (boxType) {
    case ItemTypes.CHECKBOXS_VARIABLE:
      return 'checkboxs';
    case ItemTypes.SELECTOR_VARIABLE:
      return 'options';

    default:
      return null;
  }
};

export const simulateVariableTextBox = ([key, variable]) => {
  return {
    ...variable,
    type: ItemTypes.CUSTOM_TEXT_VARIABLE,
    positions: { horizontal: 'start', vertical: 'center' },
    zIndex: 0,
    width: '100%',
    content: null,
    height: 'auto',
    name: variable.name,
    value: variable.value,
    remark: variable.remark,
    id: key,
  };
};

export const simulateVariableGroupBox = (variableGroup) => {
  return {
    ...variableGroup,
    type: ItemTypes.VARIABLE_GROUP,
    positions: { horizontal: 'start', vertical: 'center' },
    zIndex: 0,
    width: '100%',
    content: null,
    height: 'auto',
  };
};

export const createBoxesFromPdfToImage = ({
  data,
  draft
 }) => {
    const containerIndex = draft.containers.findIndex((c) => c.id === draft.tempContainerPdfToImage.id);

  if (!draft.tempContainerPdfToImage.drawMode)
  {
    mutateCreateMask({draft, name: '', KeyPdfToImage: data.KeyPdfToImage})
    const mask = draft.masks[draft.masks.length - 1];
    const box = initializeBoxContent({
      box: {...defaultItemValues.img, type: ItemTypes.IMG, height: 'auto', width: 695, content: {
                    src: data.image_url,
                   }},
      configuration: draft.configuration,
      titles:draft.titles,
      mask,
      parentId: mask.id
    });

    box.width = pageWidth - 1;
    mask.boxes = [box];
    const maskBox = initializeBoxContent({
        box:{...defaultItemValues.mask, type: ItemTypes.MASK},
        configuration:draft.configuration,
        titles: draft.titles,
      });
    mutateAddContainers({
          draft,
          index: containerIndex,
          drawMode: true,
          box: maskBox,
          KeyPdfToImage: data.KeyPdfToImage
        });
      mutateInjectMask({ draft, maskId: mask.id, boxId: maskBox.id })

  } else{
    let newBox = initializeBoxContent({
      box: {...defaultItemValues.img, type: ItemTypes.IMG, height: 'auto', width: 695, content: {
                    src: data.image_url,
                   }},
      configuration: draft.configuration,
      titles:draft.titles,

    });
    newBox.width = 676;
    newBox.top = 1;
    newBox.left = 73;

    newBox = reinitializeBoxWidthAndHeight({
                box: newBox,
              columnWidth: computeColumnWidth({
                landscape: draft.landscape,
                marginRight: 0,
                marginLeft: 0
              }),
              onCreate: true,
              header: draft.header,
              footer: draft.footer
            })
     mutateAddContainers({
              index: containerIndex,
              drawMode: true,
              draft,
              box: newBox,
              KeyPdfToImage: data.KeyPdfToImage,
              from: "PdfToImageSizeMaxDefault"
     })
  }
}
