import { Form, Input, InputNumber, Select } from 'antd';
import { Suspense, useState } from 'react';
import {
  maxFontSize,
  minFontSize,
  selectorMaxWidth,
  selectorMinWidth,
} from '../../../constants/gridConfig';
import { useStore } from '../../../store/store';
import ColorSelector from '../../common/ColorSelector';
import FontPicker from '../../common/FontPicker';
import { FamilyPicker } from '../../common/FamilyPicker';
import { bulletList } from '../../../utils/bulletList';
import './fontTab.css';
import { useTranslation } from 'react-i18next';


const selector = ({
  configuration,
  setConfiguration,
}) => ({
  configuration,
  setConfiguration,
});

export const TabsFonts = () => {
  const { configuration, setConfiguration } = useStore(selector);
  const { Option } = Select;
  const [previewFamily, setPreviewFamily] = useState(configuration.fontFamily);
  const { t } = useTranslation();

  const updatePreview = (family) => {
    setPreviewFamily(family);
  }

  const handleSymbol = (element, number) => {
    if (element === 'Defaut') {
      return setConfiguration({
        ...configuration,
        unorderedListBulletValue0: '●',
        unorderedListBulletValue1: '○',
        unorderedListBulletValue2: '■',
        unorderedListBulletValue3: '■',
        unorderedListBulletValue4: '■',
        customBulletPadding: '0px 0px 0px 0px',
      });
    }
    element = `"${element}"`;
    setConfiguration({
      ...configuration,
      [`unorderedListBulletValue${number}`]: element,
      customBulletPadding: '0px 0px 0px 5px',
    });
  };

  const handleChangeBaseFontColor = (selectedColor) => {
    setConfiguration({
      ...configuration,
      baseFontColor: configuration.colors.find(
        (color) => color.label === selectedColor
      ),
    });
  };

  const handleChangeBaseFontFamily = (selectedFont) => {
    setConfiguration({
      ...configuration,
      fontFamily: selectedFont,
    });
    updatePreview(selectedFont)
  };

  const handleChangeDefaultFontSize = (selectedSize) => {
    setConfiguration({
      ...configuration,
      fontSize: selectedSize,
    });
  };

  return (
    <>
      <Form.Item
        name='fontSize'
        label={'Taille de police par défaut'}
        initialValue={configuration.fontSize}
      >
        <InputNumber min={minFontSize} max={maxFontSize} onChange={handleChangeDefaultFontSize} />
      </Form.Item>
      <Form.Item
        name='base-fontColor'
        label='Couleur de police par défaut'
      >
        <ColorSelector
          forcedValue={configuration.baseFontColor.label}
          disableTransparent={true}
          onChange={(colors) => handleChangeBaseFontColor(colors)}
          colors={configuration.colors}
        />
      </Form.Item>
      <Form.Item
        name='fontStyle'
        label={'Style de police par défaut'}
      >
        <FontPicker onChange={handleChangeBaseFontFamily} forcedValue={configuration.fontFamily} />
      </Form.Item>
      <Suspense fallback='Chargement de Google font'>
        <Form.Item
          name='fontFamily'
          label={t('available-fonts')}
        >
          <FamilyPicker selectFamily={updatePreview} />
        </Form.Item>
      </Suspense>
      <Form.Item
        colon={true}
        label={t('text-preview')}>
      </Form.Item>
      <Form.Item
        name='preview'
        label={previewFamily}
        initialValue="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
      >
        <Input.TextArea className='preview-family-text'
          style={{
            fontFamily: previewFamily,
            color: configuration.baseFontColor.style,
            fontSize: configuration.fontSize,
          }}
          autoSize
        />
      </Form.Item>

      <Form.Item
        colon={true}
        label={t('lists-symbols')}>
      </Form.Item>
      {[1, 2, 3, 4, 5].map((level, index) => {
        return (
          <Form.Item
            key={level}
            name={`symbol-style${index}`}
            label={`Niveau ${level}`}
            initialValue={configuration[`unorderedListBulletValue${index}`].replace(/['"]+/g, '')}
          >
            <Select
              onSelect={(symbol) => {
                handleSymbol(symbol, index);
              }}
              style={{
                maxWidth: selectorMaxWidth,
                minWidth: selectorMinWidth,
                textAlign: 'center',
              }}
            >
              {bulletList.map((item) => {
                return (
                  <Option
                    key={item}
                    value={item}
                    style={{ textAlign: 'center', fontSize: '1.3vw' }}
                  >
                    {item}
                  </Option>
                );
              })}
            </Select>
          </Form.Item >
        );
      })}
    </>
  );
};
