import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ItemTypes, variablesIcons } from "../../constants/constants";
import { useStore } from "../../store/store";
import { useTranslation } from "react-i18next";
import { faAngleRight } from "@fortawesome/pro-duotone-svg-icons";
import { Editor } from "draft-js";

const VariableEditorTitle = ({ isHandlingVariables, box, variablesGroupedByContainerSelected }) => {
  const { t } = useTranslation();
  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)

  const renderBoxInfos = (b, { isSelected = true, onClick }) =>
    <div style={{
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      color: isSelected ? 'inherit' : 'gray',
      cursor: 'pointer',
      textDecoration: isSelected ? 'inherit' : 'underline'
    }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={variablesIcons[b.type]} style={{ fontSize: 16 }} />
      {typeof b.name === "string" ? b.name : <Editor readOnly editorState={b.name} />}
    </div>

  const getTitle = () => {
    if (isHandlingVariables) {
      return t('variables-handling')
    }

    const onVariableGroupClick = () => {
      handleBoxVariableSelectedId(variablesGroupedByContainerSelected.variableGroupBox.id)
    }

    if (box.type === ItemTypes.CUSTOM_TEXT_VARIABLE && variablesGroupedByContainerSelected.variableGroupBox) {
      return <div style={{
        display: 'flex',
        alignItems: 'center',
        gap: 16
      }}>
        {renderBoxInfos(variablesGroupedByContainerSelected.variableGroupBox,
          { isSelected: false, onClick: onVariableGroupClick })}
        <FontAwesomeIcon icon={faAngleRight} />
        {renderBoxInfos(box, {})}
      </div>
    }

    return renderBoxInfos(box, {})
  }

  return (
    <div style={{
      color: 'white',
      display: 'flex',
      fontSize: 20,
      fontWeight: 500,
      marginBottom: 16
    }}>
      {getTitle()}
    </div>
  )
}

export default VariableEditorTitle
