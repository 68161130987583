import React from 'react'
import { Checkbox, Form, InputNumber } from 'antd'
import { useTranslation } from 'react-i18next'
import { pageBackground } from '../../constants/constants'

const SelectionMiddle = ({ box, onBlur, form, arrayKey, selectionKey }) => {
  const { t } = useTranslation();

  const handleOnCheck = (checked) => {
    if (checked) {
      form.setFieldValue("maxNumberOfAnwser", 1);
      form.setFieldValue("minNumberOfAnwser", 1)
    }
    const keepOne = (box) => {
      if (box[arrayKey].filter((c) => c[selectionKey]).length > 1) {
        const firstIdx = box[arrayKey].findIndex((c) => c[selectionKey]);

        box[arrayKey] = box[arrayKey].map((c, idx) => idx !== firstIdx ? {
          ...c,
          [selectionKey]: false
        } : c)
      }
    }
    onBlur(keepOne)
  }

  const handleOnMaxChange = () => {
    const max = form.getFieldValue("maxNumberOfAnwser")
    const removeLast = (box) => {
      if (box[arrayKey].filter((c) => c[selectionKey]).length > max) {
        const excess = box[arrayKey].filter((c) => c[selectionKey]).length - max
        for (let i = 0; i < excess; i += 1) {
          const lastIdx = box[arrayKey].findLastIndex((c) => c[selectionKey]);

          box[arrayKey] = box[arrayKey].map((c, idx) => idx === lastIdx ? {
            ...c,
            [selectionKey]: false
          } : c)
        }
      }
    }
    onBlur(removeLast)
  }

  return (
    <>
      <Form.Item
        label={t('one-answer-only')}
        name="onlyOneRequiredAnswer"
        valuePropName="checked"
        initialValue={box.onlyOneRequiredAnswer}
        rules={[{ required: true }]}
      >
        <Checkbox onChange={handleOnCheck} />
      </Form.Item><Form.Item
        label={t('min-number-of-answer')}
        name="minNumberOfAnwser"
        initialValue={box.minNumberOfAnwser}
        rules={[{ required: true }]}
      >
        <InputNumber
          onChange={onBlur}
          min={0}
          controls={false}
          precision={0}
          max={box.maxNumberOfAnwser}
          disabled={box.onlyOneRequiredAnswer}
          data-role={pageBackground}
        />
      </Form.Item>
      <Form.Item
        label={t('max-number-of-answer')}
        name="maxNumberOfAnwser"
        initialValue={box.maxNumberOfAnwser}
        rules={[{ required: true }]}
      >
        <InputNumber
          onChange={handleOnMaxChange}
          controls={false}
          min={box.minNumberOfAnwser > 0 ? box.minNumberOfAnwser : 1}
          precision={0}
          max={box[arrayKey].length}
          disabled={box.onlyOneRequiredAnswer}
          data-role={pageBackground}
        />
      </Form.Item>
    </>
  )
}

export default SelectionMiddle
