import React, { useCallback, useEffect, useRef } from 'react'
import { Button, Form, Input } from 'antd'
import { useTranslation } from 'react-i18next';
import { PRIMARY_COLOR } from '../../constants/constants';
import { useStore } from '../../store/store';
import { useCreateCustomTextVariable } from '../../hooks';

const selector = ({ addVariable }) => {
  return ({
    addVariable,
  })
}

const AddVariableForm = ({ injectVariableInText, setIsPopoverOpen }) => {
  const { addVariable } = useStore(selector);
  const { t } = useTranslation();
  const inputRef = useRef();
  const [form] = Form.useForm();
  const {
    mutate: createCustomTextVariable,
  } = useCreateCustomTextVariable();


  const handleFormSubmit = useCallback((event) => {
    if (!['Enter'].includes(event.key)) return;
    event.stopPropagation()
    event.preventDefault()
    form.submit()
  }, [form])

  useEffect(() => {
    window.addEventListener('keydown', handleFormSubmit, true);
    return () => {
      window.removeEventListener('keydown', handleFormSubmit, true);
    };
  }, [handleFormSubmit]);

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  const onFinish = (values) => {
    createCustomTextVariable({ name: values.name }, {
      onSuccess: (newVariable) => {
        addVariable(newVariable, (variables, variableKey) => {
          injectVariableInText(variableKey, variables)
        })
      }
    })
  }

  return (
    <Form
      form={form}
      colon={false}
      layout='horizontal'
      validateMessages={{ required: "Champ obligatoire." }}
      onFinish={onFinish}
    >
      <Form.Item label="Nom"
        name="name"
        rules={[{ required: true }]}
        style={{
          marginBottom: 12
        }}
      >
        <Input
          placeholder='Nom de la variable'
          ref={inputRef}
          style={{
            width: "100%"
          }}
        />
      </Form.Item>
      <div style={{
        display: "flex",
        justifyContent: 'end',
        gap: 8
      }}>
        <Button onClick={() => setIsPopoverOpen(false)}>
          {t('cancel')}
        </Button>
        <Button
          onClick={() => form.submit()}
          style={{
            backgroundColor: PRIMARY_COLOR,
            color: 'white'
          }}>
          {t('create')}
        </Button>
      </div>
    </Form>
  )
}

export default AddVariableForm
