import React, { useCallback, } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronRight } from '@fortawesome/pro-light-svg-icons'
import VariableLineSelector from './VariableLineSelector'

const VariableGroupCollapse = ({
  variablesGroupedByContainer,
  variableGroupBox,
  variablesBoxes,
  groupOpenId,
  setGroupOpenId,
  setIsHandlingVariables
}) => {

  const renderCollapse = useCallback(() => {
    return (
      <FontAwesomeIcon
        icon={groupOpenId === variableGroupBox.id ? faChevronDown : faChevronRight}
        style={{
          fontSize: 16,
          width: 24,
          cursor: 'pointer',
        }}
        onClick={() => {
          setGroupOpenId((old) => old === variableGroupBox.id ? undefined : variableGroupBox.id)
        }}
      />
    )
  }, [groupOpenId, variableGroupBox.id, setGroupOpenId])

  const onLineNameClick = useCallback(() => {
    setGroupOpenId(variableGroupBox.id)
  }, [setGroupOpenId, variableGroupBox.id])

  return (
    <div className='variable-group-collapse'>
      <VariableLineSelector
        box={variableGroupBox}
        variablesGroupedByContainer={variablesGroupedByContainer}
        renderCustomIcon={renderCollapse}
        setIsHandlingVariables={setIsHandlingVariables}
        onLineNameClick={onLineNameClick}
        childrenBoxes={variablesBoxes.filter((b) => !b.notUsed)}
      />
      {groupOpenId === variableGroupBox.id && variablesBoxes.filter((b) => !b.notUsed).map((b) =>
        <div
          style={{
            display: 'flex'
          }}
          className='variable-group-child'
          key={b.id}
        >
          <span className='variable-tree-line' />
          <div style={{
            flex: 1,
            fontSize: 12,
            marginLeft: 8
          }}>
            <VariableLineSelector
              box={b}
              variablesGroupedByContainer={variablesGroupedByContainer}
              setIsHandlingVariables={setIsHandlingVariables}
              iconStyle={{
                fontSize: 14,
                marginRight: 4,
                width: 'auto'
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default VariableGroupCollapse
