import React from 'react'
import Container from '../Container/Container';
import AdderColumns from './AdderColumns';
import { ItemTypes } from '../../constants/constants';
import { useDrop } from 'react-dnd';
import { useStore } from '../../store/store';

const accept = [
  'newBox',
  ItemTypes.IMG,
  ItemTypes.TEXT,
  ItemTypes.SHAPE,
  ItemTypes.LINE,
  ItemTypes.TABLE,
  ItemTypes.TITLE,
  ItemTypes.TITLE_2,
  ItemTypes.TITLE_3,
  ItemTypes.TITLE_4,
  ItemTypes.SUMMARY,
  ItemTypes.MASK,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE
];

const selector = ({ fromPdf }) => ({
  fromPdf,
})

const ContainerWrapper = React.memo(({ containerId, windowItemsIndex, onDrop, boxCanDrop, fromViewer }) => {
  const [{ canDrop, boxIsOver }, dropAdder] = useDrop({
    accept,
    drop: (box, monitor) => onDrop({ box, monitor, isFromAdder: true }),
    canDrop: boxCanDrop,
    collect: (monitor) => ({
      boxIsOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    }),
  });
  const { fromPdf } = useStore(selector)

  return (
    <>
      <Container
        id={containerId}
        fromViewer={fromViewer}
      />
      {!fromViewer && !fromPdf && <div ref={dropAdder} id={`adder-${containerId}`}>
        <AdderColumns containerIndex={windowItemsIndex - 1} boxIsOver={boxIsOver} canDrop={canDrop} />
      </div>}
    </>
  )
})

export default ContainerWrapper
