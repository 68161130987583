import React from 'react';
import { useTranslation } from 'react-i18next';

// ANT Design
import { Divider, Tooltip } from 'antd';

import { ItemTypes } from '../../constants/constants';
import { useStore } from '../../store/store';
import './LeftSide.less';
import { displayTitleIndex, getTitlesConfig } from '../../utils/titles';
import { scrollToItem } from '../Middle/InfinitePage';

const selector = (state) => state.getSummaryBoxes();
const itemSelector =
  () =>
    ({ configuration, containers }) => ({
      separator: configuration.separatorTitle,
      hideTitleIndex: configuration.hideTitleIndex,
      titlesTypeConfig: getTitlesConfig(configuration),
      containers
    });

const SummarySide = () => {
  const boxes = useStore(selector);
  const { separator, titlesTypeConfig, hideTitleIndex, containers } = useStore(
    itemSelector()
  );
  const { t } = useTranslation();

  return (
    <>
      <div>
        {boxes.length > 0 && (
          <Divider style={{ color: 'white' }}>{t('summary')}</Divider>
        )}
      </div>
      {boxes.map((box) => {
        return (
          <Tooltip
            key={box.id}
            title={box.text}
            placement='bottom'
            mouseLeaveDelay='0'
          >
            <div
              onClick={() => {
                scrollToItem(box, containers)
              }}
              className={`left-side__summary__item${box.type === ItemTypes.TITLE_2 ? '--t2' : ''
                } truncate`}
            >
              {displayTitleIndex(
                box.indexTitle,
                separator,
                titlesTypeConfig,
                hideTitleIndex
              )}
              {box.text}
            </div>
          </Tooltip>
        );
      })}
    </>
  );
};

export default React.memo(SummarySide);
