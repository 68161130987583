import React, { useCallback } from 'react'
import { useStore } from '../../store/store';
import AdderColumns from './AdderColumns';
import { ItemTypes, MASKS_TYPE, containerBackground, contentHeight, defaultItemValues, pageBackground } from '../../constants/constants';
import { gridUnit } from '../../constants/gridConfig';
import { initializeBoxContent, isADrawBox, isATitleBox, reinitializeBoxWidthAndHeight } from '../../utils/boxes';
import { computeColumnWidth } from '../../utils/columns';
import { useDrop } from 'react-dnd';
import ContainerWrapper from './ContainerWrapper';

const accept = [
  'newBox',
  ItemTypes.IMG,
  ItemTypes.TEXT,
  ItemTypes.SHAPE,
  ItemTypes.LINE,
  ItemTypes.TABLE,
  ItemTypes.TITLE,
  ItemTypes.TITLE_2,
  ItemTypes.TITLE_3,
  ItemTypes.TITLE_4,
  ItemTypes.SUMMARY,
  ItemTypes.MASK,
  ItemTypes.CHECKBOXS_VARIABLE,
  ItemTypes.SELECTOR_VARIABLE,
  ItemTypes.TEXT_VARIABLE,
  ItemTypes.IMG_VARIABLE,
  ItemTypes.TABLE_VARIABLE
];

const selector = ({
  boxes,
  configuration,
  addContainers,
  containers,
  landscape
}) => ({
  containers,
  addContainers,
  configuration,
  landscape,
  marginLeft: configuration.margins.left * gridUnit,
  marginRight: configuration.margins.right * gridUnit,
  titles: boxes.filter((box) => isATitleBox(box.type)),
});

const ItemWrapper = React.memo(({ index: windowItemsIndex, itemId, itemLength }) => {
  const {
    addContainers,
    configuration,
    marginLeft,
    marginRight,
    titles,
    containers,
    landscape
  } = useStore(selector);
  const header = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.HEADER.id))
  const footer = useStore(({ masks }) => masks.find(({ id }) => id === MASKS_TYPE.FOOTER.id))
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')

  const onDrop = ({ box, monitor, isFromAdder }) => {
    if (monitor.getItemType() === 'newBox') {
      let newBox = initializeBoxContent({
        box,
        configuration,
        titles,
      });

      const drawMode = isADrawBox(box.type);

      if (!drawMode) {
        newBox = reinitializeBoxWidthAndHeight({
          box: newBox,
          columnWidth: computeColumnWidth({
            landscape,
            marginRight,
            marginLeft
          }),
          onCreate: true,
          header,
          footer
        })
      }
      let index;
      if (isFromAdder) {
        if (windowItemsIndex === 0) { index = null } else {
          index = windowItemsIndex - 1
        }
      }
      addContainers({
        index,
        drawMode,
        box: newBox,
      })
    }
  }

  const boxCanDrop = useCallback((_, monitor) => {
    const box = monitor.getItem();
    if (landscape && !isADrawBox(box.type)) {
      return false;
    }
    // Can't move box from parent to another one
    if (box.parentId) {
      return false
    }
    if (!isTemplate && !unlockProposal) {
      return false
    }
    return true
  }, [unlockProposal, isTemplate, landscape])

  const [{ canDrop, boxIsOver }, dropAdder] = useDrop({
    accept,
    drop: (box, monitor) => onDrop({ box, monitor, isFromAdder: true }),
    canDrop: boxCanDrop,
    collect: (monitor) => ({
      boxIsOver: monitor.isOver({ shallow: true }),
      canDrop: monitor.canDrop()
    }),
  });

  const [, dropInfinite] = useDrop({
    accept,
    canDrop: boxCanDrop,
    drop: (box, monitor) => onDrop({ box, monitor, isFromAdder: false })
  });

  const addTextBoxOnDoubleClick = useCallback(
    () => {
      if (landscape || (!isTemplate && !unlockProposal)) return;
      let newBox = initializeBoxContent({
        box: {
          ...defaultItemValues.text,
          type: 'text',
        },
        configuration,
        titles,
      });
      newBox = reinitializeBoxWidthAndHeight({
        box: newBox,
        columnWidth: computeColumnWidth({
          landscape,
          marginRight,
          marginLeft
        }),
        onCreate: true,
        header,
        footer
      })
      addContainers({ box: newBox });
    },
    [landscape, isTemplate, unlockProposal, configuration, titles, marginRight, marginLeft, header, footer, addContainers]
  );

  if (windowItemsIndex === 0) {
    return (
      <div data-role={pageBackground} data-parent={containerBackground}>
        <div ref={dropAdder}>
          <AdderColumns withoutContainers={itemLength === 1} boxIsOver={boxIsOver} canDrop={canDrop} />
        </div>
      </div>
    )
  }

  if (windowItemsIndex + 1 === itemLength) {
    return (
      <div data-role={pageBackground} data-parent={containerBackground}>
        <div
          id="infinite-page-drop"
          style={{
            width: "100%",
            height: contentHeight,
            backgroundColor: "white",
            position: 'relative'
          }}
          data-role={pageBackground}
          data-parent={containerBackground}
          onDoubleClick={addTextBoxOnDoubleClick}
          ref={dropInfinite}
        >
        </div>
      </div>
    )
  }

  const containerId = itemId
  if (!containers.some((c) => c.id === containerId)) return null

  return (
    <div data-role={pageBackground} data-parent={containerBackground}>
      <ContainerWrapper
        containerId={containerId}
        windowItemsIndex={windowItemsIndex}
        onDrop={onDrop}
        boxCanDrop={boxCanDrop} />
    </div>
  )
})

export default ItemWrapper
