import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/store';
// import Axios from 'axios';
import { Select } from 'antd';
import { searchInputWidth } from '../../constants/gridConfig';

const selector = ({ configuration, loadFont, summaryConfiguration, setSummaryConfiguration }) => ({
  configuration,
  summaryConfiguration,
  setSummaryConfiguration,
  loadFont,
});

const FontPicker = ({ onChange = null, forcedValue }) => {
  const { configuration, loadFont } = useStore(selector);
  const [fonts, setFonts] = useState([]);

  useEffect(() => {
    setFonts(configuration.fontFamilyList);
  }, [configuration.fontFamilyList]);

  const handleOnChange = (e) => {
    loadFont(e);
    onChange(e);
  }

  return (
    <Select
      showSearch
      value={forcedValue}
      style={{ width: searchInputWidth, fontFamily: forcedValue }}
      optionFilterProp='value'
      getPopupContainer={(triggerNode) => triggerNode.parentNode}
      filterOption={(input, option) =>
        option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      onChange={handleOnChange}
    >
      {fonts.map((font) => (
        <Select.Option key={font.key} value={font.familyName} style={{ fontFamily: font.familyName }}>
          {font.familyName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default FontPicker;
