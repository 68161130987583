import { ItemTypes } from '../constants/constants';

export const titlesActions = (set, get) => ({
  getSummaryBoxes: () =>
    get()
      .boxes.filter((box) =>
        [ItemTypes.TITLE, ItemTypes.TITLE_2].includes(box.type)
      )
      .map((b) => ({
        ...b,
        text: b.content.editorState.getCurrentContent().getPlainText(),
        indexTitle: b.content.indexTitle,
      })),
});
