import React from 'react';
import LoaderGIF from './images/Smart4Loader.gif';
import './loader.css';

export default function Loader() {
  return (
    <div
      style={{
        position: 'sticky',
        height: 'calc(100vh - 60px)',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        className='Loader'
        src={LoaderGIF}
        alt={LoaderGIF}
        style={{ width: '30vw' }}
      />
    </div>
  );
}
