import { useStore } from '../../store/store';
// Common
import './Utils.css';
import NavbarSelector from '../Layout/NavbarSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import { ItemTypes } from '../../constants/constants';
import { useMemo, useState } from 'react';
import { useQueryDocumentSections } from '../../hooks';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { Spin, Radio } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { postPdfInTemplate } from '../../requests/templates';

const selector =
  (id) =>
    ({
      boxes,
      masks,
      injectMask,
      injectSection,
      landscape,
      currentDocument,
      currentProposal,
      updateBox,
      handleSectionFromSectionVariable,
      setLoaderPdfToImage,
      setTempContainerPdfToImage,
      selectedContainer,
    }) => {
      const box = boxes.find((b) => b.id === id);
      const maskList = masks.filter(({ id }) => !["header", "footer"].includes(id))
      let inject;
      let selectedValue;
      if (box.type === ItemTypes.MASK) {
        inject = injectMask
      } else if (box.type === ItemTypes.SECTION_VARIABLE) {
        if (currentDocument.type === 'template') {
          inject = ({ id: optionId, boxId: id }) => updateBox(id, (box) => {
            box.answer = optionId
          })
        } else {
          inject = ({ id: optionId, boxId }) => handleSectionFromSectionVariable({
            optionId,
            boxId,
            sectionId: box.sectionId,
            action: box.questions.find((q) => q.id === optionId).label === 'Oui' ? "inject" : "remove"
          })
        }
        selectedValue = box.answer
      } else {
        inject = injectSection
      }
      return {
        maskList,
        box,
        inject,
        landscape,
        setLoaderPdfToImage,
        setTempContainerPdfToImage,
        selectedContainer,
        documentType: currentDocument.type,
        documentId: currentDocument.type === 'template' ? currentDocument.id : currentProposal.id,
        selectedValue
      }
    };

const SelectionBox = ({ id }) => {
  const {
    inject,
    box,
    maskList,
    landscape,
    documentType,
    documentId,
    selectedValue,
    setLoaderPdfToImage,
    setTempContainerPdfToImage,
    selectedContainer
  } = useStore(selector(id));
  const { data: sectionList, isLoading } = useQueryDocumentSections({ documentType, documentId })
  const { t } = useTranslation();
  const { updateLeftShownStatus } = useOverlay();
  const [drawPdfToImage, setDrawPdfToImage] = useState(true)

  const selectionList = useMemo(() => {
    let list = []
    if (box.type === ItemTypes.MASK) {
      list = maskList
    } else if (box.type === ItemTypes.SECTION_VARIABLE) {
      list = box.questions.map((q) => ({ ...q, name: q.label }))
    }
    else { list = sectionList || [] }
    return list.map(({ id, name }) => {
      return {
        value: id,
        label: name,
      }
    });
  }, [box.type, box?.questions, maskList, sectionList]);

  const selectedSectionVariable = useMemo(() => {
    if (box.type === ItemTypes.SECTION_VARIABLE) return (sectionList || []).find((s) => s.id === box.sectionId)
    return null
  }, [box?.sectionId, box.type, sectionList])


  const handleSelect = (optionId) => {
    inject({ id: optionId, boxId: id })
  };

  const handlePdfSelect = (event) => {
    console.log(event.target.files[0])
    setTempContainerPdfToImage({ id: selectedContainer.id, drawMode: drawPdfToImage })
    postPdfInTemplate({ id: documentId, boxId: id, file: event.target.files[0], setLoaderPdfToImage, currentDocumentType: documentType })
  }

  const iconElement = () => {
    if (box.type === ItemTypes.MASK) return < FontAwesomeIcon
      icon={icon({ name: 'masks-theater', style: 'regular' })}
      style={{ marginRight: "8px" }} />
    if (box.type === ItemTypes.SECTION_VARIABLE) return < FontAwesomeIcon
      icon={icon({ name: 'question', style: 'regular' })}
      style={{ marginRight: "8px" }} />
    return < FontAwesomeIcon
      icon={icon({ name: 'rectangle-history-circle-plus', style: 'regular' })}
      style={{ marginRight: "8px" }} />
  }

  const placeholder = useMemo(() => {
    if (box.type === ItemTypes.SECTION_VARIABLE) {
      if (documentType === 'template') return t("answer-only-proposal")
      return t("answer")
    }
    return t(box.type)
  }, [box.type, documentType, t])

  const renderPdfSelector = () => <div style={{ display: "flex" }}>
    <input style={{ width: 300 }} type='file' accept='application/pdf' onChange={handlePdfSelect} />
    <div style={{ display: 'flex' }}>
      {/* <div>Avec En-tête et pied de page</div>
      <Switch onChange={() => setDrawPdfToImage(prev => !prev)} />
      <div>Sans en tête et pied de page</div> */}
      <Radio.Group name="radiogroup" defaultValue={1} onChange={() => setDrawPdfToImage(prev => !prev)}>
        <Radio value={1}>Avec En-tête et pied de page</Radio>
        <Radio value={2}>Pleine page</Radio>
      </Radio.Group>
    </div>
  </div>

  const renderNavbarSelector = () => <NavbarSelector
    width={(landscape ? pageHeight : pageWidth) / 1.5}
    tooltipContent={t(box.type)}
    suffixIcon={iconElement()}
    onSelect={handleSelect}
    options={selectionList}
    placeholder={placeholder}
    selectedValue={selectedValue}
    disabled={box.type === ItemTypes.SECTION_VARIABLE && documentType === 'template'}
  />

  return (
    <div
      id={`box-${box?.id ? box.id : 'new'}`}
      onDoubleClick={(event) => {
        if (isAVariableBox(box?.type)) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: "100%",
        backgroundColor: "#bfbfbf",
        flexDirection: "column",
        rowGap: 8
      }}
    >
      {box.type === ItemTypes.SECTION_VARIABLE && <>
        <div>
          {isLoading ? <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> :
            <>
              {selectedSectionVariable && `Voulez-vous affichez la section ${selectedSectionVariable.name} ?`}
              {!selectedSectionVariable && box.sectionId && "La section à afficher n'est plus disponible"}
              {!selectedSectionVariable && !box.sectionId && "Aucune section à afficher"}
            </>
          }
        </div>
        {selectedSectionVariable && renderNavbarSelector()}
      </>}
      {box.type !== ItemTypes.SECTION_VARIABLE && box.type !== ItemTypes.FILE_PDF && renderNavbarSelector()}
      {box.type === ItemTypes.FILE_PDF && renderPdfSelector()}
    </div>
  );
};

export default SelectionBox;
