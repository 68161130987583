import React, { useCallback, useState } from 'react'
import { useStore } from '../../store/store';
import { Button, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { pageBackground, roundButtonWidth } from '../../constants/constants';
import { useTranslation } from 'react-i18next';

const selector = (id) => ({
  containers,
  removeContainer,
  removeSectionContainers,
  boxes,
  maskEditing,
  masks,
  updateContainer,
  currentProposal,
}) => {
  const container = containers.find((c) => c.id === id);
  const mask = !container && maskEditing.length > 0
    && maskEditing.includes(id)
    && masks.find((m) => m.id === id);
  return {
    container: container,
    removeContainer,
    removeSectionContainers,
    updateContainer,
    currentProposal,
    hasBox: !mask && boxes.some((b) => container.columnsIds.includes(b.columnId)),
  }
};

const EditableContainerButton = ({ id }) => {
  const {
    container,
    updateContainer,
    currentProposal,
  } = useStore(selector(id));
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);

  const editableContainer = () => {
    updateContainer({
      id: container.id, fn: (container) => {
        if (!container.hasOwnProperty('editable')) {
          container.editable = false;
        } else {
          container.editable = !container.editable;
        }
      }
    })
  }
  const padLock = useCallback(() => {
    if (container.editable || !container.hasOwnProperty('editable')) {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole-open', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
    else {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
  }, [container])

  const dynamicTooltip = useCallback(() => {
    if (container?.editable || !container.hasOwnProperty('editable')) {
      return "unblock-editable-container"
    }
    else {
      return "block-editable-container"
    }
  }, [container])

  const showButton = useCallback(() => {
    if (currentProposal.id !== null && (typeof container?.editable !== 'boolean' || container?.editable)) {
      return false
    } else return true
  }, [currentProposal, container])
  return showButton() ? (
    <Tooltip
      open={isHovered}
      placement='bottom'
      mouseLeaveDelay='0'
      title={t(dynamicTooltip())}
    >
      <div
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}>
        <Button shape="circle"
          disabled={currentProposal.id !== null}
          size={14}
          data-role={pageBackground}
          onClick={editableContainer}
          style={{
            width: roundButtonWidth,
            height: roundButtonWidth,
            minWidth: roundButtonWidth,
            minHeight: roundButtonWidth,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          icon={
            padLock()
          }
        />
      </div>
    </Tooltip>) : null
}

export default EditableContainerButton;
