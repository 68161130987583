import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  Editor, EditorState, getDefaultKeyBinding, Modifier, RichUtils, SelectionState
} from 'draft-js';
import { useStore } from '../../store/store';

// common components
import '../common/Utils.css';

import { getBackgroundBorderStyle } from '../../utils/styles';
import { debounce } from 'lodash';
import TextStylingComponent from '../TextStylingComponent/TextStylingComponent'
import { useStyleMap } from '../../hooks';
import { decorator, getBlockStyle, handleBeforeInput } from './utils';
import EditorWrapper from '../common/EditorWrapper';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { MENU_ANCESTORS } from '../../constants/constants';
import { EditorProvider } from '../../contexts/EditorContext';

export const setDefaultInlineStyleIfNeeded = (
  currentEditorState,
  removedProperty,
  defaultProperty,
  prefix = ''
) => {
  let nextContentState = currentEditorState.getCurrentContent();
  const blocks = [];
  nextContentState.getBlockMap().forEach((block) => {
    const characterList = block.getCharacterList().map((character) => {
      if (character.hasStyle(`${prefix}${removedProperty}`)) {
        return CharacterMetadata.removeStyle(
          character,
          `${prefix}${removedProperty}`
        );
      } else {
        return character;
      }
    });
    blocks.push(
      new ContentBlock({
        key: block.getKey(),
        text: block.getText(),
        type: block.getType(),
        depth: block.getDepth(),
        data: block.getData(),
        characterList,
      })
    );
  });

  return EditorState.push(
    currentEditorState,
    ContentState.createFromBlockArray(blocks),
    'change-inline-style'
  );
};

// Store selector
const selector = (id) => (state) => {
  return {
    selectedBoxId: state.selectedBoxId,
    removeBox: state.removeBox,
    updateBox: state.updateBox,
    configuration: state.configuration,
    isSelected: state.selectedBoxId === id,
    setBoxMovable: state.setBoxMovable,
    isOffer: state.currentDocument.type === 'offer',
    setEventManagerEnabled: state.setEventManagerEnabled,
    fromPdf: state.fromPdf
  };
};

const RichEditor = ({ box, drawMode }) => {
  const styleMap = useStyleMap()
  const editorRef = useRef(null);
  const boundingBoxRef = useRef(null);
  const {
    updateBox,
    isSelected,
    setBoxMovable,
    configuration,
    setEventManagerEnabled,
    fromPdf
  } = useStore(selector(box.id));

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    box.content.editorState.getCurrentContent(),
    decorator
  ));

  useEffect(() => {
    var root = document.querySelector(':root');
    var element0 = configuration.unorderedListBulletValue0;
    var element1 = configuration.unorderedListBulletValue1;
    var element2 = configuration.unorderedListBulletValue2;
    var element3 = configuration.unorderedListBulletValue3;
    var element4 = configuration.unorderedListBulletValue4;
    var customBulletPadding = configuration.customBulletPadding;

    root.style.setProperty('--unorderedSymbol0', element0);
    root.style.setProperty('--unorderedSymbol1', element1);
    root.style.setProperty('--unorderedSymbol2', element2);
    root.style.setProperty('--unorderedSymbol3', element3);
    root.style.setProperty('--unorderedSymbol4', element4);
    root.style.setProperty('--customBulletPaddingCSS', customBulletPadding);
  }, [
    configuration.unorderedListBulletValue,
    configuration.unorderedListBulletValue0,
    configuration.unorderedListBulletValue1,
    configuration.unorderedListBulletValue2,
    configuration.unorderedListBulletValue3,
    configuration.unorderedListBulletValue4,
    configuration.customBulletPadding,
  ]);

  const saveBox = useCallback(debounce((editorState) => {
    if (boundingBoxRef.current) {
      updateBox(box.id, (box) => {
        box.height = boundingBoxRef?.current.firstChild.clientHeight;
        box.content.editorState = EditorState.createWithContent(
          editorState.getCurrentContent(),
          decorator
        );
      });
    }
  }, 100), [box?.id, updateBox]);

  const setEditorContentWithReplacement = (editorObject = editorState) => {
    let modifiedEditorState = editorObject;
    setEditorState(modifiedEditorState);
  }

  const write = (editorState) => {
    setEditorContentWithReplacement(editorState);
  };

  const handleKeyCommand = useCallback(
    (command) => {
      if (command) {
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, command.toUpperCase())
        );
      }
    },
    [editorState]
  );

  const handleTab = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const newSelection = new SelectionState({
      anchorKey: key,
      focusKey: key,
    });
    let textWithInsert = '';
    let editorWithInsert = '';
    let newEditorState = '';
    if (
      blockType === 'unordered-list-item' ||
      blockType === 'ordered-list-item'
    ) {
      const newState = RichUtils.onTab(e, editorState, 4);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      } else {
        return 'not-handled';
      }
    } else {
      textWithInsert = Modifier.insertText(
        currentContent,
        newSelection,
        '    ',
        null
      );
      editorWithInsert = EditorState.push(
        editorState,
        textWithInsert,
        'insert-characters'
      );
      newEditorState = EditorState.moveFocusToEnd(
        editorWithInsert,
        textWithInsert.getSelectionAfter()
      );
      setEditorState(newEditorState);
    }
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 9 /* `S` key */) {
      handleTab(e);
    }
    return getDefaultKeyBinding(e);
  };

  // If in default container, focus on one click
  useEffect(() => {
    if (!drawMode && isSelected) {
      editorRef.current.focus();
    }
  }, [drawMode, isSelected]);

  useEffect(() => {
    if (isSelected) {
      saveBox(editorState)
    }
  }, [isSelected, saveBox, editorState]);

  const placeholder = useMemo(() => {
    const bulletStatus = RichUtils.getCurrentBlockType(editorState)
    return fromPdf ||
      bulletStatus === "unordered-list-item" ||
      bulletStatus === "ordered-list-item" ?
      "" : 'Votre texte ici'
  }, [editorState, fromPdf])

  const updateBoxOnStylingChange = useCallback((newEditorState) => {
    updateBox(box.id, (box) => {
      box.content.editorState = EditorState.createWithContent(
        newEditorState.getCurrentContent(),
        decorator
      );
    });
  }, [updateBox]);

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
      <div
        ref={boundingBoxRef}
        id={box.id}
        style={{
          ...getBackgroundBorderStyle(box.backgroundColor, box.border),
          width: box.width,
        }}
        onClick={() => {
          if (isSelected && !editorState.getSelection().getHasFocus()) {
            editorRef.current.focus();
          }
        }}
        onKeyDown={(e) => {
          if (editorState.getSelection().getHasFocus())
            e.stopPropagation()
        }}
      >
        <EditorWrapper boxId={box.id} editorState={editorState}>
          <Editor
            stripPastedStyles={true}
            handleBeforeInput={(chars, editorState) => handleBeforeInput(
              {
                chars,
                editorState,
                setEditorState
              }
            )}
            placeholder={placeholder}
            blockStyleFn={getBlockStyle}
            className='RichEditor-editor'
            style={{
              overflow: 'visible',
            }}
            customStyleMap={styleMap}
            editorState={editorState}
            handleKeyCommand={handleKeyCommand}
            onBlur={() => saveBox(editorState)}
            onFocus={(event) => {
              setBoxMovable(false);
              setEventManagerEnabled(false);
            }}
            onChange={write}
            keyBindingFn={myKeyBindingFn}
            spellCheck={true}
            ref={editorRef}
            textAlignment={box.content.alignment}
            readOnly={!isSelected}
          />
        </EditorWrapper>
      </div>
      {isSelected &&
        ReactDOM.createPortal(
          <ContextualMenu
            editorState={editorState}
            setEditorState={setEditorState}
            menuAncestorType={MENU_ANCESTORS.BOX.content}
            menuAncestorIds={menuAncestorIds}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      {isSelected &&
        ReactDOM.createPortal(
          <TextStylingComponent
            hasAlignement
            boxId={box.id}
            boxAlignement={box.content.alignment}
            editorState={editorState}
            setEditorState={setEditorState}
            updateBoxOnStylingChange={updateBoxOnStylingChange}
          />,
          document.getElementById('ComponentPortal')
        )}
    </EditorProvider>
  );
};

export default RichEditor;
