import React, { useCallback, useMemo } from 'react'
import { Table } from 'antd'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import './TableWithDrag.less'

const DraggableRow = ({
  children,
  moveRow,
  style,
  onDrop,
  canDrag = true,
  canDrop = true,
  dragIconTitle,
  ...props }) => {

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: "table-row",
      item: { id: props["data-row-key"] },
      collect: (monitor) => ({
        isDragging: props["data-row-key"] === monitor.getItem()?.id,
      }),
      canDrag,
      end: () => onDrop(props["data-row-key"]),
    }),
    [props["data-row-key"], onDrop, canDrag]
  );

  const [, drop] = useDrop(
    () => ({
      accept: "table-row",
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
      hover({ id: draggedId }) {
        if (draggedId !== props["data-row-key"]) {
          moveRow(props["data-row-key"], draggedId)
        }
      },
    }),
    [moveRow, props["data-row-key"]]
  );

  const opacity = isDragging ? 0.8 : 1;

  return (
    <tr {...props} style={{ ...style, opacity }} ref={(el) => {
      if (canDrop) {
        drop(el);
        preview(el);
      }
    }}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                touchAction: 'none',
                cursor: 'grab',
              }}
                ref={drag}
                title={dragIconTitle}
              >
                <FontAwesomeIcon
                  icon={icon({ name: 'arrows-up-down-left-right', style: 'solid' })}
                  style={{
                    fontSize: 14,
                    opacity: canDrag ? 1 : 0.3
                  }}
                />
              </div>

            ),
          });
        }
        return child;
      })}
    </tr>
  );
}

const TableWithDrag = ({
  columns = [],
  dataSource = [],
  setDataSource,
  backgroundColor = "#0B2239",
  color = "white",
  onDrop,
  scroll
}) => {

  const moveRow = useCallback(
    (hoverId, draggedId) => {
      setDataSource((old) => {
        const dragRowIdx = old.findIndex(({ id }) => draggedId === id);
        const hoverIdx = old.findIndex(({ id }) => hoverId === id);
        const dragRow = old[dragRowIdx]
        return update(old, {
          $splice: [
            [dragRowIdx, 1],
            [hoverIdx, 0, dragRow],
          ],
        })
      }
      );
    },
    [setDataSource],
  );

  const columnsWithDragger = useMemo(() => {
    return [
      {
        title: <></>,
        dataIndex: 'sort',
        key: 'sort',
        width: 30,
        onHeaderCell: () => ({
          style: {
            backgroundColor,
            color,
          },
        }),
      },
      ...columns
    ]
  }, [backgroundColor, color, columns])

  const onDropRow = useCallback((recordId) => {
    if (onDrop) onDrop(recordId)
  }, [onDrop])

  return (
    <Table
      className='table-with-drag'
      pagination={false}
      columns={columnsWithDragger}
      dataSource={dataSource}
      rowKey="id"
      bordered
      scroll={scroll}
      components={{
        body: {
          row: DraggableRow,
        },
      }}
      onRow={(record) => {
        const attr = {
          moveRow,
          onDrop: onDropRow,
          canDrag: record.canDrag,
          canDrop: record.canDrop,
          dragIconTitle: record.dragIconTitle,
        };
        return attr;
      }}
    />
  )
}

export default TableWithDrag
