//ANT Design
import { message } from 'antd';

// Axios
import { instance } from './axios.instance';

import { formatResContent, textToRaw } from '../utils/dataSync';

export const getTemplates = async () => {
  try {
    const res = await instance.get('/proposals/templates/');
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const getOpportunityTemplates = async (id) => {
  try {
    const res = await instance.get(`/proposals/opportunities/${id}/templates/`);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const getOfferTemplates = async (id) => {
  try {
    const res = await instance.get(`/proposals/offers/${id}/templates/`);
    if (res.status === 200) {
      return res.data;
    }
    throw new Error(`GET proposal templates status code ${res.status}`);
  } catch (error) {}
};

export const deleteTemplate = async (id) => {
  try {
    const res = await instance.delete(`/proposals/templates/${id}`);
    if (res.status === 204) {
      message.success('Le template a bien été supprimé.');
      return true;
    }
    throw new Error(`DELETE proposal template ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le template n'a pas pu être supprimé.");
    return false;
  }
};

export const postTemplate = async ({
  name,
  templatable,
  pattern,
  landscape,
  boxes,
  pages,
  masks,
  configuration,
  summaryConfiguration,
  work,
  documentTypeIds,
  variables,
  variableGroups,
}) => {
  if (!boxes) return;
  try {
    const res = await instance.post('/proposals/templates/', {
      name: name,
      document_type_ids: documentTypeIds,
      templatable,
      pattern,
      landscape,
      work,
      content: {
        boxes: textToRaw(boxes),
        pages,
        configuration,
        summaryConfiguration,
        variables,
        variableGroups,
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
    });
    if (res.status === 200) {
      message.success('Le template a bien été enregistré');
      return res.data;
    }

    if (res.status === 401) {
      message.error("Vous n'êtes pas autorisé");
    }
    throw new Error(`POST proposal template  status code ${res.status}`);
  } catch (error) {
    message.error(
      "Une erreur est survenu lors de l'enregistrement du template"
    );
  }
};

export const updateTemplate = async ({
  id,
  name,
  pattern,
  landscape,
  templatable,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfiguration,
  work,
  containers,
  columns,
  document_type_ids,
  variables,
  variableGroups,
}) => {
  if (!containers || !columns) return;
  try {
    const res = await instance.patch(`/proposals/templates/${id}`, {
      name: name,
      templatable,
      landscape,
      pattern,
      work,
      document_type_ids: document_type_ids,
      content: {
        variables,
        variableGroups,
        boxes: textToRaw(boxes),
        containers,
        columns,
        configuration,
        summaryConfiguration,
        gridConfiguration,
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
    });

    if (res.status === 200) {
      message.success('Le template a bien été enregistré');
      return res.data;
    }

    if (res.status === 401) {
      message.error("Vous n'êtes pas autorisé");
    }

    throw new Error(`PATCH proposal template ${id} status code ${res.status}`);
  } catch (error) {
    console.log(error);
    message.error(
      "Une erreur est survenue lors de l'enregistrement du template"
    );
  }
};

export const getTemplate = async (
  id,
  defaultVariablesApi,
  currentDocIsTemplate = false
) => {
  try {
    const res = await instance.get(`/proposals/templates/${id}`);
    if (res.status === 200) {
      // return res.data;
      res.data.content = formatResContent(
        res.data.content,
        defaultVariablesApi,
        currentDocIsTemplate,
        res.data.landscape
      );
      return res.data;
    }
    throw new Error(`GET proposal template ${id} status code ${res.status}`);
  } catch (error) {
    return {
      error,
    };
  }
};
export const updateTemplateInfos = async (id, { name, document_type_ids }) => {
  try {
    const res = await instance.post(`/proposals/templates/${id}/update_infos`, {
      name,
      document_type_ids,
    });
    if (res.status === 200) {
      message.success(`Le modèle ${res.name} a bien été modifé.`);
      return res.data;
    }
    throw new Error(`MODIFY proposal ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le modèle n'a pas pu être modifié.");
    return false;
  }
};

export const duplicateTemplate = async (id, newName) => {
  try {
    const res = await instance.post(`/proposals/templates/${id}/duplicate`, {
      name: newName,
    });
    if (res.status === 200) {
      message.success(`Le modèle  a bien été dupliqué.`);
      return res.data;
    }
    throw new Error(`DUPLICATE template ${id} status code ${res.status}`);
  } catch (error) {
    message.success("Le modèle n'a pas pu être dupliqué.");
    return false;
  }
};

export const postTemplateAndFetchPdf = async ({
  currentDocument,
  boxes,
  masks,
  configuration,
  summaryConfiguration,
  gridConfig,
  saveOnArrived,
  name,
  containers,
  columns,
  landscape,
  isArchive,
  variables,
  variableGroups,
}) => {
  if (!containers || !columns || !currentDocument.id) return;
  try {
    var res;
    const url = `/proposal/templates/${currentDocument.id}/generate_pdf`;
    // HERE ============================================
    res = await instance.post(url, {
      content: {
        boxes: textToRaw(boxes),
        configuration,
        containers,
        columns,
        summaryConfiguration,
        gridConfig,
        variables,
        variableGroups,
        masks: masks.map((m) => {
          return {
            ...m,
            boxes: m.boxes ? textToRaw(m.boxes) : [],
          };
        }),
      },
      landscape,
      name,
      must_be_saved: isArchive,
    });

    if (res.status === 202) {
      return res.data;
    }
    throw new Error(`POST proposal template  status code ${res.status}`);
  } catch (error) {
    message.error('Une erreur est survenu lors de la génération du PDF');
    return {};
  }
};

export const postPdfInTemplate = async ({id, boxId, file, setLoaderPdfToImage, currentDocumentType}) => {
  try {
    // Utilisez FormData pour encapsuler le fichier
    const formData = new FormData();
    formData.append('file', file);
    formData.append('box_id', boxId);

    // Envoyez le fichier en utilisant axios avec multipart/form-data
    let urlBase = '';
    if(currentDocumentType === 'template') {
      urlBase = '/proposal/templates';
    } else {
      urlBase = '/proposal';
    }
    const res = await instance.post(`${urlBase}/${id}/pdf`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    if (res.status === 200) {
      setLoaderPdfToImage(true);

      message.success('Le PDF a bien été enregistré');
      return res.data;
    }
    throw new Error(`POST proposal template status code ${res.status}`);
  } catch (error) {
    message.error('Une erreur est survenue lors de la sauvegarde du PDF');
    return {};
  }
};
