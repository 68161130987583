import { produce } from 'immer';
import { getDocumentFromParams } from '../utils/browser';

export const browserActions = (set, get) => ({
  setCurrentDocument: (params) => {
    const doc = getDocumentFromParams(params);
    set(
      produce((draft) => {
        draft.currentDocument = doc;
      }),
      false,
      `Set current document => ${doc.type} n°${doc.id}`
    );
  },
});
