import React from 'react'
import VariableName from './VariableName'
import { variableStyle } from '../../utils/styles'
import { Form, Select } from 'antd'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../store/store'
import { useQueryDocumentSections } from '../../hooks'
import ModalImage from 'react-modal-image';
import { ContentState, EditorState } from 'draft-js'

import VariableRemark from './VariableRemark'
import { decorator } from '../RichEditor/utils'
const { Option } = Select;

const selector =
  ({
    currentDocument,
    currentProposal,
  }) => {
    return {
      documentType: currentDocument.type,
      documentId: currentDocument.type === 'template' ? currentDocument.id : currentProposal.id,
    }
  };

const VariableSection = ({ box, onBlur, form }) => {
  const {
    documentType,
    documentId,
  } = useStore(selector);
  const { t } = useTranslation();
  const { data: sectionList } = useQueryDocumentSections({ documentType, documentId })
  return (
    <>
      <div className='variable-editor__left' style={variableStyle["left"]}>
        <VariableName box={box} onBlur={onBlur} form={form} />
        <VariableRemark box={box} onBlur={onBlur} form={form} />
      </div>
      <div className='variable-editor__middle_section' style={variableStyle["middle"]}>
        <Form.Item
          label={t('section-to-show')}
          name="sectionId"
          initialValue={box.sectionId}
          rules={[{ required: true }]}
        >
          <Select
            style={{
              flex: 1,
              width: '100%',
            }}
            onSelect={(e) => {
              form.setFieldValue("name", EditorState.createWithContent(
                ContentState.createFromText(sectionList.find((s) => s.id === e).name),
                decorator
              ))
              onBlur();
            }}
            dropdownMatchSelectWidth={false}
            optionLabelProp="label"
            showSearch
            optionFilterProp="label"
          >
            {(sectionList || []).map(({ name, id, preview }) => {
              return (
                <Option key={id} value={id} label={name}>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    width: 500
                  }}>
                    <div style={{
                      width: 420,
                      whiteSpace: 'break-spaces',
                      wordWrap: 'break-word',
                    }}>
                      {name}
                    </div>
                    <div style={{ width: 80 }}>
                      <ModalImage
                        small={preview}
                        large={preview}
                        alt='Aperçu'
                      />
                    </div>
                  </div>
                </Option>
              )
            })}
          </Select>
        </Form.Item>
      </div >
      <div style={variableStyle["right"]}>
      </div>
    </>
  )
}

export default VariableSection
