import React, { useEffect, useRef, useState } from 'react'
import { faMagnifyingGlass } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Input } from 'antd'
import './InputHideable.less'

const InputHideable = ({
  value,
  setValue
}) => {
  const [isShowingInput, setIsShowingInput] = useState(false);
  const [isInputDisappearing, setIsInputDisappearing] = useState(false);
  const ref = useRef()

  const showAndHideInput = () => {
    if (!isShowingInput) {
      setIsShowingInput(true)
      setValue("")
    } else {
      setIsInputDisappearing(true)
      setValue("")
    }
  }

  const hideInput = () => {
    if (isInputDisappearing && isShowingInput) {
      setIsShowingInput(false)
      setIsInputDisappearing(false)
    }
  }

  const onChangeInput = (e) => {
    setValue(e.target.value)
  }

  useEffect(() => {
    if (isShowingInput)
      ref.current.focus()
  }, [isShowingInput])

  return (
    <div style={{
      minHeight: 20.85,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      position: 'relative',
      width: "100%"
    }}
      onAnimationEnd={hideInput}
    >
      {
        isShowingInput &&
        <Input
          ref={ref}
          value={value}
          className={`input-hideable ${isInputDisappearing ? 'disappear' : 'appear'}`}
          suffix={
            <div style={{
              width: 16,
              height: 16
            }} />
          }
          onChange={onChangeInput}
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            fontSize: 12,
          }}
        />
      }
      <div style={{
        alignSelf: 'center',
        position: 'absolute',
        right: isShowingInput && !isInputDisappearing ? 10 : 0,
        color: isShowingInput && !isInputDisappearing ? 'black' : 'inherit',
        transition: 'color 300ms ease-in-out, right 300ms ease-in-out',
        zIndex: 9999,
        padding: 6,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer'
      }}
        onClick={showAndHideInput}
      >
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
        />
      </div>
    </div>
  )
}

export default InputHideable
