import { Form, Select, } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../store/store';

const { Option } = Select;

const VariableSelectionGroup = ({ box, onBlur }) => {
  const { t } = useTranslation();
  const variableGroups = useStore(({ variableGroups }) => variableGroups)
  const initialValue = variableGroups.find((vG) => vG.variableIds.includes(box.id))?.id

  if (variableGroups.length < 1) return null

  return (
    <Form.Item
      label={t('linked-group')}
      name="linked-group"
      initialValue={initialValue}
      labelCol={{ span: 24 }}
    >
      <Select onChange={() => onBlur(initialValue)} allowClear>
        {variableGroups.map((vG) => {
          return (
            <Option
              key={vG.id}
              value={vG.id}
            >
              {vG.name}
            </Option>
          );
        })}
      </Select>
    </Form.Item>
  )
}

export default VariableSelectionGroup
