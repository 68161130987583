import { EditorState, convertToRaw } from 'draft-js';

export const newVariableGroup = ({
  id,
  name,
  disposition = 2,
  remark = JSON.stringify(
    convertToRaw(EditorState.createEmpty().getCurrentContent())
  ),
}) => {
  return {
    id,
    name,
    remark,
    disposition,
    variableIds: [],
  };
};

export const mutateAddVariableGroup = ({
  draft,
  name,
  remark,
  disposition,
  id,
}) => {
  const variableGroup = newVariableGroup({ name, remark, disposition, id });
  draft.variableGroups.push(variableGroup);
  return variableGroup;
};

export const mutateDeleteVariableGroup = ({ draft, id }) => {
  const vbIndex = draft.variableGroups.findIndex((vb) => vb.id === id);
  draft.variableGroups = draft.variableGroups.filter(
    (_, idx) => idx !== vbIndex
  );
};

export const mutateHandleLinkedVariable = ({
  variableGroup,
  variableId,
  variables,
  variableIds,
}) => {
  variableGroup.variableIds = variableIds;
};

export const constructVariableIds = ({ variableGroup, variableId }) => {
  if (variableGroup.variableIds.includes(variableId)) {
    return variableGroup.variableIds.filter((id) => id !== variableId);
  } else {
    return [...variableGroup.variableIds, variableId];
  }
};

export const variableGroupToRaw = (variableGroup) => ({
  ...variableGroup,
  remark: JSON.stringify(
    convertToRaw(variableGroup.remark.getCurrentContent())
  ),
});

export const rawVariableGroupToStoreVariableGroup = (rawVG) => ({
  ...rawVG,
  id: typeof rawVG.id === 'string' ? rawVG.id : rawVG.id.toString(),
  variableIds: rawVG.variable_ids,
});

export const mutateSortVariableGroups = (draft) => {
  draft.variableGroups.sort((vA, vB) => {
    const vAIsUsed = vA.variableIds.some((id) => !draft.variables[id]?.notUsed);
    const vBIsUsed = vB.variableIds.some((id) => !draft.variables[id]?.notUsed);
    if (vAIsUsed && !vBIsUsed) return -1;
    if (!vAIsUsed && vBIsUsed) return 1;
    return 0;
  });
};
