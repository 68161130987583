import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { variableStyle } from '../../utils/styles'
import { useStore } from '../../store/store'
import ButtonCommon from '../common/ButtonCommon'
import { transformTextToEditorState } from '../../utils/dataSync'
import { simulateVariableGroupBox } from '../../utils/boxes'
import VariableGroupTable from './VariableGroupTable'
import VariableGroupLeftForm from './VariableGroupLeftForm'
import { ItemTypes } from '../../constants/constants'
import VariableGroupChildren from './VariableGroupChildren'
import { useTranslation } from 'react-i18next'
import VariableCustomTextLeftForm from './VariableCustomTextLeftForm'
import { useCreateVariableGroup } from '../../hooks'
import { rawVariableGroupToStoreVariableGroup, variableGroupToRaw } from '../../utils/variableGroups'

const defaultFormInfos = { isOpen: false, item: null }

const VariableGroupHandler = ({
  variablesGroupedVariables,
  form,
  validateFormAndRetrieveValues
}) => {
  const [formInfos, setFormInfos] = useState(defaultFormInfos);
  const { t } = useTranslation()
  const {
    mutate: createVariableGroup,
    isLoading
  } = useCreateVariableGroup();

  const addVariableGroup = useStore(({ addVariableGroup }) => addVariableGroup);

  const onCancel = useCallback(() => {
    setFormInfos(defaultFormInfos)
  }, [])

  const onCreate = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues()
      createVariableGroup(variableGroupToRaw({ name: values.name, remark: values.remark, disposition: values.disposition }), {
        onSuccess: (newVariableGroup) => {
          addVariableGroup(rawVariableGroupToStoreVariableGroup(newVariableGroup),
            (newVariableGroup) => {
              setFormInfos({ isOpen: true, item: simulateVariableGroupBox(newVariableGroup) })
            })
        }
      })
    } catch (errors) {
    }
  }, [validateFormAndRetrieveValues, createVariableGroup, addVariableGroup])

  const box = useMemo(() => {
    if (!formInfos.isOpen) {
      return;
    }
    if (!formInfos.item) {
      form.setFieldValue("name", "")
      return {
        name: "",
        remark: transformTextToEditorState(),
        id: "create-group"
      }
    }
    form.setFieldValue("name", formInfos.item.name)
    return formInfos.item
  }, [form, formInfos])

  useLayoutEffect(() => {
    form.resetFields()
  }, [form, box])

  return (
    <>
      <VariableGroupTable
        formInfos={formInfos}
        setFormInfos={setFormInfos}
        variablesGroupedVariables={variablesGroupedVariables}
      />
      <div style={variableStyle["middle"]}>
        <div style={{
          marginBottom: 16,
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          minHeight: 32
        }}>
          {formInfos.isOpen && !formInfos.item && <>
            <ButtonCommon
              type="cancel"
              style={{ marginLeft: 'auto' }}
              onClick={onCancel}
            >
              {t('cancel')}
            </ButtonCommon>
            <ButtonCommon
              type="add"
              onClick={onCreate}
              loading={isLoading}
              disabled={isLoading}
            >
              {t('create')}
            </ButtonCommon>
          </>}
        </div>
        {
          formInfos.isOpen && !formInfos.item &&
          <VariableGroupLeftForm box={box} form={form} />
        }
        {
          formInfos.isOpen && formInfos.item?.type === ItemTypes.VARIABLE_GROUP &&
          <VariableGroupLeftForm box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} />
        }
        {
          formInfos.isOpen && formInfos.item?.type === ItemTypes.CUSTOM_TEXT_VARIABLE &&
          <VariableCustomTextLeftForm box={box} validateFormAndRetrieveValues={validateFormAndRetrieveValues} form={form} />
        }
      </div>
      <div style={variableStyle["right"]}>
        {
          formInfos.isOpen && formInfos.item?.type === ItemTypes.VARIABLE_GROUP &&
          <VariableGroupChildren box={box} variablesGroupedVariables={variablesGroupedVariables} />
        }
      </div>
    </>
  )
}

export default VariableGroupHandler
