// Others
import { useStore } from '../../store/store';
import { getDisplayableTitles, getIdsFromConfig } from './SummarySelector';

// Common
import { isATitleBox } from '../../utils/boxes';

const selector =
  (id) =>
    ({ boxes, summaryConfiguration, configuration }) => {
      const box = boxes.find((box) => box.id === id);
      const allTitles = boxes.filter((box) => isATitleBox(box.type));
      const displayableTitles = getDisplayableTitles(boxes, summaryConfiguration, configuration);
      const { lineSummaryStart, lineSummaryEnd } = box.content.configuration;
      if (!lineSummaryStart || !lineSummaryEnd) return ({ titles: displayableTitles, summaryConfiguration: summaryConfiguration })

      const { idStart, idEnd } = getIdsFromConfig(lineSummaryStart, lineSummaryEnd, displayableTitles, allTitles)

      const startTitleIndex = displayableTitles.findIndex((title) => title.id === idStart);
      const endTitleIndex = displayableTitles.findIndex((title) => title.id === idEnd);

      const titlesToDisplay = displayableTitles.slice(startTitleIndex, endTitleIndex + 1);
      return {
        titles: titlesToDisplay,
        summaryConfiguration: summaryConfiguration,
      };
    };

const itemSelector =
  () =>
    ({ gridConfiguration }) => ({ gridConfiguration });

export const SummaryTitleItem = ({ title, style, boxId }) => {
  const margin = Number(title.type[title.type?.length - 1]) * 15 - 15;
  const fontColor = style.color?.style;

  const { gridConfiguration } = useStore(
    itemSelector()
  );

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>
          <span
            style={{
              ...style,
              color: fontColor,
              marginLeft: margin,
              flexGrow: 1,
              paddingRight: gridConfiguration.summary.paddingSummary,
            }}
          >
            {title.indexTitle}
          </span>
          <span style={{
            ...style,
            color: fontColor,
            flexGrow: 1,
          }}
          >
            {title.text}
          </span>
        </span>
        <span style={{ ...style, color: fontColor }} id={`${boxId}-${title.id}`}>
        </span>
      </div>
    </>
  );
};

const Summary = ({ box }) => {
  const { titles, summaryConfiguration } = useStore(selector(box.id));
  if (titles.length === 0) {
    return (
      <div
        style={{
          width: box.width,
          backgroundColor: 'lightgray',
          padding: '1rem',
          color: 'black'

        }}
      >
        <p style={{ margin: 0 }}>Sommaire - Aucun titre dans le document</p>
      </div>
    )
  }

  return (
    <div
      style={{
        width: box.width,
      }}
    >
      {titles.map((title) => (
        <SummaryTitleItem
          key={title.id}
          boxId={box.id}
          title={title}
          style={summaryConfiguration[title.type]}
        />
      ))}
    </div>
  );
};

export default Summary;
