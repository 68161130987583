import React from 'react'
import { contentPadding, pageHeight, pageWidth } from '../../constants/gridConfig'
import { useStore } from '../../store/store'
import { pageBackground, variableHandlingType } from '../../constants/constants'
import VariableContainer from './VariableContainer'
import VariablesProposal from './VariablesProposal'

const selector = ({
  boxVariableSelectedId,
  currentDocument,
  configuration,
  landscape
}) => {
  return {
    documentType: currentDocument.type,
    boxVariableSelectedId,
    fontSize: configuration.fontSize,
    fontFamily: configuration.fontFamily,
    landscape
  }
}

const VariableViewer = ({ variablesGroupedByContainer, box, variablesGroupedByContainerSelected }) => {
  const { documentType, boxVariableSelectedId, fontSize, fontFamily, landscape } = useStore(selector)

  return (
    <div style={{
      height: '100%',
      paddingLeft: 34,
      width: "100%",
      paddingTop: contentPadding,
      display: 'flex',
      justifyContent: 'center',
      fontSize: `${fontSize}px`,
      fontFamily: fontFamily,
    }}
      data-role={pageBackground}
    >
      {documentType === 'template' ?
        <div style={{
          backgroundColor: 'white',
          width: landscape ? pageHeight : pageWidth,
          paddingTop: 16
        }}
          data-role={pageBackground}
        >
          <VariableContainer boxId={boxVariableSelectedId} />
        </div> :
        <VariablesProposal
          box={box}
          variablesGroupedByContainer={variablesGroupedByContainer.filter((vInfos) => {
            if (vInfos.type === variableHandlingType.FORM) return true
            if (vInfos.variableGroupBox)
              return vInfos.variablesBoxes.some((v) => !v.notUsed)
            return !vInfos.variablesBoxes[0].notUsed
          })}
          variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
        />
      }
    </div>
  )
}

export default VariableViewer
