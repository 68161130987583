import {
  CompositeDecorator,
  EditorState,
  Modifier,
  RichUtils,
  SelectionState,
  convertToRaw,
} from 'draft-js';
import VariableDecorator from '../ContextualMenu/VariableDecorator';
import ImageDecorator from '../common/ImageDecorator';
import { ItemTypes, MENU_ANCESTORS } from '../../constants/constants';

export const handleAlignmentChange = (boxId, updateBox, selectedAlignment) => {
  updateBox(boxId, (box) => {
    box.content.alignment = selectedAlignment;
  });
};
const sides = ['left', 'top', 'bottom', 'right'];

export const hasAllBordersOn = (box) => {
  return sides.every((side) => {
    return getBoxBorder(box, side);
  });
};

const mutateBoxAllBorders = (box, value) => {
  sides.forEach((side) => {
    mutateBoxBorder(box, side, value);
  });
};

export const getBoxBorder = (box, side) => {
  if (box.type === 'shape') {
    return box.borders;
  } else {
    return box.border && box.border[side];
  }
};
export const getBoxBorderColor = (box) => {
  if (box.type === 'shape') {
    return box.borders && box.borders.color;
  } else {
    return box.border && box.border.color;
  }
};

const mutateToggleBoxBorder = (box, side) => {
  if (box.type === 'shape') {
    if (!box.borders) {
      box.borders = {};
    }
    box.borders = !box.borders;
  } else {
    if (!box.border) {
      box.border = {};
    }
    box.border[side] = !box.border[side];
  }
};

const mutateBoxBorder = (box, side, value) => {
  if (box.type === 'shape') {
    if (!box.borders) {
      box.borders = {};
    }
    box.borders[side] = value;
  } else {
    if (!box.border) {
      box.border = {};
    }
    box.border[side] = value;
  }
};

export const handleBorderChangement = (boxId, updateBox, sides) => {
  updateBox(boxId, (box) => {
    if (sides.length === 4) {
      if (hasAllBordersOn(box)) {
        mutateBoxAllBorders(box, false);
      } else {
        mutateBoxAllBorders(box, true);
      }
    } else {
      sides.forEach((side) => {
        mutateToggleBoxBorder(box, side);
      });
    }
  });
};

export const onChangeColor =
  (boxId, updateBox, configuration) => (selectedColor) => {
    updateBox(boxId, (box) => {
      box.backgroundColor = {
        label: selectedColor,
        style: configuration.colors.find(
          (color) => color.label === selectedColor
        ).style,
      };
    });
  };

export const onChangeBorderColor =
  (boxId, updateBox, configuration) => (selectedColor) => {
    updateBox(boxId, (box) => {
      box.border.color = {
        label: selectedColor,
        style: configuration.colors.find(
          (color) => color.label === selectedColor
        ).style,
      };
    });
  };

export const applyInlineStyle = ({ selectedProperty, prefix = '', editor }) => {
  let nextContentState = editor.getCurrentContent();
  let nextEditorState = EditorState.push(
    editor,
    nextContentState,
    'change-inline-style'
  );
  const currentStyle = editor.getCurrentInlineStyle();
  //unset current style depending prefix
  nextEditorState = currentStyle
    .filter((elem) => elem?.includes(prefix))
    .reduce((state, property) => {
      return RichUtils.toggleInlineStyle(state, property);
    }, nextEditorState);
  // apply any way
  nextEditorState = RichUtils.toggleInlineStyle(
    nextEditorState,
    selectedProperty
  );
  return nextEditorState;
};

export const removeInlineStyle = ({ prefix = '', editor }) => {
  let nextContentState = editor.getCurrentContent();
  let nextEditorState = EditorState.push(
    editor,
    nextContentState,
    'change-inline-style'
  );
  const currentStyle = editor.getCurrentInlineStyle();
  //unset current style depending prefix
  nextEditorState = currentStyle
    .filter((elem) => elem?.includes(prefix))
    .reduce((state, property) => {
      return RichUtils.toggleInlineStyle(state, property);
    }, nextEditorState);
  return nextEditorState;
};

export const toggleInlineStyle = ({
  selectedProperty,
  prefix = '',
  editor,
}) => {
  let nextContentState = editor.getCurrentContent();
  let nextEditorState = EditorState.push(
    editor,
    nextContentState,
    'change-inline-style'
  );
  const currentStyle = editor.getCurrentInlineStyle();
  //unset current style depending prefix
  nextEditorState = currentStyle
    .filter((elem) => elem?.includes(prefix))
    .reduce((state, property) => {
      return RichUtils.toggleInlineStyle(state, property);
    }, nextEditorState);
  //toggle new style if not already present
  if (currentStyle.has(selectedProperty)) {
    return nextEditorState;
  }
  nextEditorState = RichUtils.toggleInlineStyle(
    nextEditorState,
    selectedProperty
  );
  return nextEditorState;
};

export const highlightUnknownVariables = (editorStateToHighlight) => {
  let modifiedEditorState = editorStateToHighlight;
  const previousSelectionState = editorStateToHighlight.getSelection();
  const text = modifiedEditorState.getCurrentContent().getPlainText();
  // check si y'a des variables
  const variables = text.match(/\*\*\[.*?\]\*\*/g);
  if (variables && variables.length > 0) {
    // reéupère les différents blocs contenu dans draft
    const blocks = modifiedEditorState.getCurrentContent().getBlockMap();
    blocks.forEach((b) => {
      let remainingBlockText = b.getText();
      let substractedCharacters = 0;
      // si variables dans le block
      const unknownVars = remainingBlockText.match(/\*\*\[.*?\]\*\*/g);
      if (unknownVars && unknownVars.length > 0) {
        unknownVars.forEach((uv) => {
          const selectionState = SelectionState.createEmpty(b.key);
          const regex = new RegExp(`\\*\\*\\[${uv.slice(3, -3)}\\]\\*\\*`);
          const m = remainingBlockText.match(regex);
          const selectionStateWithRange = selectionState
            .set('anchorOffset', m.index + substractedCharacters) // start of selection within block
            .set('focusOffset', m.index + m[0].length + substractedCharacters); // end of selection within block
          const modifiedEditorWithSelection = EditorState.forceSelection(
            modifiedEditorState,
            selectionStateWithRange
          );
          modifiedEditorState = applyInlineStyle({
            selectedProperty: `HIGHLIGHT_yellow`,
            prefix: 'HIGHLIGHT_',
            editor: modifiedEditorWithSelection,
          }); // HIGHLIGHT_
          modifiedEditorState = EditorState.forceSelection(
            modifiedEditorState,
            previousSelectionState
          );
          substractedCharacters += m.index + m[0].length;
          remainingBlockText = remainingBlockText.substring(
            m.index + m[0].length
          );
        });
      }
    });
  }
  return modifiedEditorState;
};

export const contentChanged = (currentEditor, newEditor) => {
  const currentContentState = currentEditor.getCurrentContent();
  const newContentState = newEditor.getCurrentContent();
  return currentContentState !== newContentState;
};

export const translateVariable = ({ variableKey, variables }) => {
  let translated;
  if (variables[variableKey]) {
    translated =
      variables[variableKey].value === ''
        ? variables[variableKey].name
        : variables[variableKey].value;
  } else {
    translated = variableKey;
  }
  if (typeof translated === 'number') {
    translated = translated.toString();
  }
  return translated;
};

export const getEntities = (editorState, entityType = null) => {
  const content = editorState.getCurrentContent();
  const entities = [];
  content.getBlocksAsArray().forEach((block) => {
    let selectedEntity = null;
    block.findEntityRanges(
      (character) => {
        if (character.getEntity() !== null) {
          const entity = content.getEntity(character.getEntity());
          if (!entityType || (entityType && entity.getType() === entityType)) {
            selectedEntity = {
              entityKey: character.getEntity(),
              blockKey: block.getKey(),
              entity: content.getEntity(character.getEntity()),
            };
            return true;
          }
        }
        return false;
      },
      (start, end) => {
        entities.push({ ...selectedEntity, start, end });
      }
    );
  });
  return entities;
};

export const convertContentToRawText = (editorState) => {
  const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
  const mappedBlocks = blocks.map(
    (block) => (!block.text.trim() && '\n') || block.text
  );

  let newText = '';
  for (let i = 0; i < mappedBlocks.length; i++) {
    const block = mappedBlocks[i];

    // handle last block
    if (i === mappedBlocks.length - 1) {
      newText += block;
    } else {
      // otherwise we join with \n, except if the block is already a \n
      if (block === '\n') newText += block;
      else newText += block + '\n';
    }
  }
  return newText;
};

export const injectVariableInEditorState = ({
  editorState,
  variableKey,
  variablesInStore,
  isTemplate,
  startOffset,
  anchorOffset,
  blockKey,
}) => {
  let modifiedEditorState = editorState;

  const variableTranslated = translateVariable({
    variableKey,
    variables: variablesInStore,
    isTemplate,
  });

  const selectionState = SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset: startOffset,
  });
  const contentstate = modifiedEditorState.getCurrentContent();
  let newContentState = contentstate.createEntity('VARIABLE', 'IMMUTABLE', {
    key: variableKey,
  });
  const entityKey = newContentState.getLastCreatedEntityKey();
  newContentState = Modifier.replaceText(
    newContentState,
    selectionState,
    variableTranslated,
    modifiedEditorState.getCurrentInlineStyle(anchorOffset),
    entityKey
  );
  modifiedEditorState = EditorState.push(
    modifiedEditorState,
    newContentState,
    'insert-characters'
  );
  modifiedEditorState = EditorState.forceSelection(
    modifiedEditorState,
    SelectionState.createEmpty(blockKey).merge({
      anchorOffset: anchorOffset + variableTranslated.length,
      focusOffset: anchorOffset + variableTranslated.length,
    })
  );
  return modifiedEditorState;
};

export const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'ordered-list-item':
      return 'RichEditor-ordered-list-item-no-marker';
    default:
      return null;
  }
};
export const handleBeforeInput = ({ chars, editorState, setEditorState }) => {
  if (chars === '. ') {
    const currentSelection = editorState.getSelection();
    setEditorState(
      EditorState.set(editorState, {
        currentContent: Modifier.replaceText(
          editorState.getCurrentContent(),
          currentSelection,
          ' '
        ),
      })
    );
    return 'handled';
  }
  return 'not-handled';
};

const getEntityStrategy = (type) => {
  return function (contentBlock, callback, contentState) {
    contentBlock.findEntityRanges((character) => {
      const entityKey = character.getEntity();
      if (entityKey === null) {
        return false;
      }
      return contentState.getEntity(entityKey).type === type;
    }, callback);
  };
};

export const decorator = new CompositeDecorator([
  {
    strategy: getEntityStrategy('VARIABLE'),
    component: VariableDecorator,
  },
  {
    strategy: getEntityStrategy('IMAGE'),
    component: ImageDecorator,
  },
]);

// Fonction pour ajouter une image
export const insertImage = ({
  editorState,
  startOffset,
  anchorOffset,
  blockKey,
  box,
  menuAncestorType,
  menuAncestorIds,
}) => {
  const contentState = editorState.getCurrentContent();
  const contentStateWithEntity = contentState.createEntity(
    'IMAGE',
    'IMMUTABLE',
    { box, menuAncestorType, menuAncestorIds }
  );
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

  // Insertion de l'image avec un espace après
  const selectionState = SelectionState.createEmpty(blockKey).merge({
    anchorOffset,
    focusOffset: startOffset,
  });
  const newContentState = Modifier.replaceText(
    contentState,
    selectionState,
    ' ',
    null,
    entityKey
  );
  const newContentStateWithSpace = Modifier.insertText(
    newContentState,
    newContentState.getSelectionAfter(),
    ' '
  );

  // Ajuster la sélection pour qu'elle soit après l'image et l'espace inséré
  const newEditorState = EditorState.push(
    editorState,
    newContentStateWithSpace,
    'insert-characters'
  );
  const newSelection = newContentStateWithSpace.getSelectionAfter();
  const editorStateWithNewSelection = EditorState.forceSelection(
    newEditorState,
    newSelection
  );
  return editorStateWithNewSelection;
};

export const getAnchorOffset = ({ editorState, startOffset, delimiter }) => {
  const blockKey = editorState.getSelection().getStartKey();
  const blockText = editorState
    .getCurrentContent()
    .getBlockForKey(blockKey)
    .getText();
  let anchorOffset;
  for (
    anchorOffset = startOffset;
    anchorOffset > 0 && blockText[anchorOffset] !== delimiter;
    anchorOffset -= 1
  );
  return anchorOffset;
};

export const getAncestorFromType = (type, parent) => {
  if (type === ItemTypes.CUSTOM_TEXT_VARIABLE)
    return MENU_ANCESTORS.CUSTOM_TEXT_VARIABLE[parent];
  if (type === ItemTypes.VARIABLE_GROUP)
    return MENU_ANCESTORS.VARIABLE_GROUP[parent];
  return MENU_ANCESTORS.BOX[parent];
};

export const replaceStyle = (editorState, oldStyle, newStyle) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();

  let newContentState = contentState;

  blockMap.forEach((block) => {
    const blockKey = block.getKey();
    const characterList = block.getCharacterList();

    // Variable pour marquer le début d'une plage de texte avec l'ancien style
    let start = null;

    // Parcourir chaque caractère du bloc
    characterList.forEach((char, index) => {
      if (char.hasStyle(oldStyle)) {
        // Si le caractère a l'ancien style et qu'aucune plage n'est en cours, démarrer une nouvelle plage
        if (start === null) {
          start = index;
        }
      } else {
        // Si le caractère n'a pas l'ancien style et qu'une plage est en cours, terminer la plage
        if (start !== null) {
          const selection = SelectionState.createEmpty(blockKey).merge({
            anchorOffset: start,
            focusOffset: index,
          });

          // Retirer l'ancien style et appliquer le nouveau style en une seule opération
          newContentState = Modifier.applyInlineStyle(
            Modifier.removeInlineStyle(newContentState, selection, oldStyle),
            selection,
            newStyle
          );
          start = null; // Réinitialiser le marqueur de plage
        }
      }
    });

    // Traiter la fin du bloc si une plage de style est encore ouverte
    if (start !== null) {
      const selection = SelectionState.createEmpty(blockKey).merge({
        anchorOffset: start,
        focusOffset: characterList.size,
      });

      // Retirer l'ancien style et appliquer le nouveau style pour la plage restante
      newContentState = Modifier.applyInlineStyle(
        Modifier.removeInlineStyle(newContentState, selection, oldStyle),
        selection,
        newStyle
      );
    }
  });

  return EditorState.push(editorState, newContentState, 'change-inline-style');
};
