import React, { useMemo, useState } from 'react'
import { hasVariableBoxError } from '../../utils/boxes'
import { useStore } from '../../store/store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ItemTypes, VARIABLE_COLOR, antdFormError, variablesIcons } from '../../constants/constants'
import { scrollToItem } from '../Middle/InfinitePage';
import { Tooltip } from 'antd'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { scrollToItemInVariableEditor } from '../VariableHandler/VariablesProposal'
import { useTranslation } from 'react-i18next'
import { Editor } from 'draft-js'

const VariableLineSelector = ({
  box,
  variablesGroupedByContainer,
  renderCustomIcon,
  setIsHandlingVariables,
  onLineNameClick,
  iconStyle,
  childrenBoxes
}) => {
  const [lineIconHovered, setLineIconHovered] = useState()
  const [lineNameHovered, setLineNameHovered] = useState()
  const [iconErrorHovered, setIconErrorHovered] = useState()
  const { t } = useTranslation()

  const { error, message } = useMemo(() => {
    if (!childrenBoxes) return hasVariableBoxError(box)
    if (childrenBoxes.some((b) => hasVariableBoxError(b).error)) {
      return { error: true, message: t('missing-value') };
    }
    return { error: false, message: '' };
  }, [childrenBoxes, box, t])


  const handleBoxVariableSelectedId = useStore(({ handleBoxVariableSelectedId }) => handleBoxVariableSelectedId)
  const containers = useStore(({ containers }) => containers)
  const currentDocument = useStore(({ currentDocument }) => currentDocument)
  const boxVariableSelectedId = useStore(({ boxVariableSelectedId }) => boxVariableSelectedId)

  const getColor = () => {
    if (error && currentDocument.type !== 'template') return antdFormError
    if (boxVariableSelectedId === box.id
      || lineNameHovered === box.id ||
      (box.type === ItemTypes.VARIABLE_GROUP && box.variableIds.includes(boxVariableSelectedId))) return 'white'
    return undefined
  }

  const getBgColor = () => {
    if (boxVariableSelectedId === box.id) {
      if (error && currentDocument.type !== 'template') return 'white'
      return 'gray'
    }
    return undefined
  }

  const renderIcon = () => {
    if (renderCustomIcon) return renderCustomIcon()
    return (
      <>
        <FontAwesomeIcon
          icon={variablesIcons[box.type]}
          style={{
            fontSize: 16,
            width: 24,
            cursor: box.type === ItemTypes.CUSTOM_TEXT_VARIABLE ? 'not-allowed' : 'pointer',
            color: lineIconHovered === box.id ? VARIABLE_COLOR : undefined,
            ...(iconStyle ?? {})
          }}
          onClick={() => {
            if (box.type === ItemTypes.CUSTOM_TEXT_VARIABLE) return
            setIsHandlingVariables(false)
            handleBoxVariableSelectedId();
            scrollToItem(box, containers)
          }}
          onMouseEnter={() => setLineIconHovered(box.id)}
          onMouseLeave={() => setLineIconHovered()}
        />
      </>
    )
  }

  if (box.notUsed) return null

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        color: 'white',
        cursor: 'pointer',
        marginBottom: 8
      }}>
      {renderIcon()}
      {"  "}
      <span
        style={{
          color: getColor(),
          textDecoration: lineNameHovered === box.id ? `underline ${getColor()}` : undefined,
          wordWrap: 'anywhere',
          wordBreak: 'normal',
          whiteSpace: "break-spaces",
          flex: 1,
          fontWeight: boxVariableSelectedId === box.id ? 'bold' : undefined
        }}
        onMouseEnter={() => setLineNameHovered(box.id)}
        onMouseLeave={() => setLineNameHovered()}
        onClick={() => {
          setIsHandlingVariables(false)
          handleBoxVariableSelectedId(box.id)
          if (currentDocument.type !== 'template') {
            scrollToItemInVariableEditor(box, variablesGroupedByContainer)
          }
          if (onLineNameClick) onLineNameClick()
        }}
      >
        <span style={{
          backgroundColor: getBgColor()
        }}>{typeof box.name === "string" ? box.name : <Editor
          readOnly
          editorState={box.name} />}</span>
        {error && currentDocument.type !== 'template' &&
          <Tooltip
            open={iconErrorHovered === box.id}
            placement='bottom'
            mouseLeaveDelay='0'
            title={message}
          >
            <FontAwesomeIcon
              icon={icon({ name: 'circle-info', style: 'solid' })}
              style={{ marginLeft: 8, color: antdFormError }}
              onMouseEnter={() => setIconErrorHovered(box.id)}
              onMouseLeave={() => setIconErrorHovered()}
            />
          </Tooltip>
        }
      </span>
    </div>
  )
}
export default VariableLineSelector
