import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { iconButtonStyle } from '../../utils/styles';
import { NavbarButton } from '../Layout/NavbarButton';
import { NavbarInputNumber } from '../Layout/NavbarInputNumber';
import { NO_ROTATION_SHAPES } from '../Shape/shapeUtils';
import './../common/Utils.css';

const NO_ROTATION_TYPES = []

const selector =
  () =>
    ({ updateBox, setBoxMovable, boxes, selectedBoxId, columns, maskEditing, masks }) => {
      const boxesToUse = maskEditing?.length > 0 ? masks.filter((m) => maskEditing.includes(m.id)).map((m) => m.boxes).flat() : boxes
      const box = boxesToUse.find((b) => b.id === selectedBoxId)
      return {
        updateBox,
        setBoxMovable,
        box,
        drawMode: maskEditing?.length > 0 || !box?.columnId || columns.find((c) => c.id === box.columnId).drawMode,
      };
    };

export const BoxRotationInterface = () => {
  const { t } = useTranslation();
  const { updateBox, box, drawMode } = useStore(selector());
  const canRotate = drawMode && (!NO_ROTATION_TYPES.includes(box?.type) ||
    (box?.content?.shape && !NO_ROTATION_SHAPES.includes(box?.content.shape)))



  // update which shape

  const rotateBox = (box, value = null) => {
    if (Number.isInteger(value)) {
      updateBox(box.id, (box) => {
        box.rotation = value % 360;
      });
      return;
    }
    updateBox(box.id, (box) => {
      box.rotation = box.rotation ?? 0;
      box.rotation = (box.rotation + 90) % 360;
    });
  };

  return (
    <div className='navbar__common-properties-container'>
      <div className='navbar__commontop-properties-container'>
        <NavbarInputNumber
          min={0}
          max={360}
          disabled={!box || !canRotate}
          tooltipContent={t('rotation')}
          value={box?.rotation ?? 0}
          suffix='°'
          onChange={(value) => rotateBox(box, value)}
          defaultValue={0}
        />
        <NavbarButton
          tooltipContent={t('rotation')}
          onClick={() => rotateBox(box)}
          disabled={!box || !canRotate}
          icon={< FontAwesomeIcon icon={icon({ name: 'rotate-right', style: 'light' })} style={iconButtonStyle()} />}
        />
      </div>
    </div>
  );
};
