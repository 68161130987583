import { DeleteOutlined } from '@ant-design/icons';
import { PRIMARY_DARK } from '../../constants/constants';
import { iconButtonStyle, navbarButtonStyle } from '../../utils/styles';
import { Checkable } from '../common/Checkable';

const ColorButton = ({
  active,
  label,
  size,
  onToggle,
  colorStyle,
  positionStyle
}) => {
  const toggle = (e) => {
    e.preventDefault();
    onToggle(label);
  };
  return (
    <div
      style={{
        ...positionStyle
      }}
    >
      <Checkable checked={active && label !== 'transparent'}>
        <div style={{
          ...navbarButtonStyle,
          height: size,
          width: size,
          marginBottom: '1px',
          position: 'relative',
          borderColor: PRIMARY_DARK,
        }}>
          <div
            style={{
              height: size,
              width: size,
              background: `repeating-conic-gradient(#000 0% 25%, #eee 0% 50%) 50%/16px 16px`,
            }}>
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              backgroundColor: colorStyle === 'transparent' ? 'white' : colorStyle,
              position: 'absolute',
              top: 0,
              left: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            onMouseDown={onToggle ? toggle : null}
          >
            {label === 'transparent' ? (
              <DeleteOutlined style={iconButtonStyle()} />
            ) : null}
          </div>
        </div>
      </Checkable>
    </div>
  );
};

export default ColorButton;
