import React, { useCallback, useEffect } from 'react'
import MenuLine from './MenuLine';
import { getAnchorOffset, insertImage } from '../RichEditor/utils';
import { initializeBoxContent } from '../../utils/boxes';
import { useStore } from '../../store/store';

const delimiter = "/"

const SelectionCommandsTranslator = ({
  options,
  selectedOptionKey,
  setSelectedOptionKey,
  setEditorState,
  startOffset,
  endOffset,
  blockText,
  setOptions,
  menuAncestorType,
  menuAncestorIds
}) => {
  const gridConfiguration = useStore(({ gridConfiguration }) => gridConfiguration);
  const configuration = useStore(({ configuration }) => configuration);

  const formatCommandsAsOptions = useCallback(() => {
    if (endOffset !== startOffset) {
      return [];
    }

    let startVariableName;
    for (
      startVariableName = startOffset - 1;
      startVariableName > 0 && blockText[startVariableName] !== delimiter;
      startVariableName -= 1);
    if (blockText[startVariableName] !== delimiter ||
      (startVariableName !== 0 && blockText[startVariableName - 1].match(/^[0-9a-z]+$/i))) {
      return []
    }

    const searchInput = blockText.slice(startVariableName + 1, startOffset)
    return [{ key: "img", name: "Image" }].filter(({ name }) => {
      return name.toLowerCase().indexOf(searchInput.toLowerCase()) > -1
    })
  }, [blockText, endOffset, startOffset])

  useEffect(() => {
    setOptions(formatCommandsAsOptions())
  }, [formatCommandsAsOptions, setOptions])

  const onSelect = useCallback((event, optionKey) => {
    event.stopPropagation()
    event.preventDefault()
    if (optionKey === 'img') {
      const box = initializeBoxContent({
        box: { ...gridConfiguration['img'], type: 'img', width: 100, height: 'auto' },
        configuration,
      });
      setEditorState((editorState) => insertImage({
        blockKey: editorState.getSelection().getStartKey(),
        editorState,
        startOffset,
        anchorOffset: getAnchorOffset({ editorState, startOffset, delimiter }),
        box,
        menuAncestorType,
        menuAncestorIds
      },
      ))
    }
  }, [gridConfiguration, configuration, setEditorState, startOffset, menuAncestorType, menuAncestorIds])

  const handleSelectAction = useCallback((event) => {
    if (!['Enter'].includes(event.key) || !options || options?.length < 1) return;

    onSelect(event, selectedOptionKey)
  }, [onSelect, options, selectedOptionKey])

  useEffect(() => {
    window.addEventListener('keydown', handleSelectAction, true);
    return () => {
      window.removeEventListener('keydown', handleSelectAction, true);
    };
  }, [handleSelectAction]);


  return (
    <>
      {options.map(({ key, name }) =>
        <MenuLine
          key={key}
          optionKey={key}
          optionName={name}
          selectedOptionKey={selectedOptionKey}
          onSelect={onSelect}
          setSelectedOptionKey={setSelectedOptionKey}
        />
      )}
    </>
  )
}

export default SelectionCommandsTranslator;
