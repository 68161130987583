import { useDragLayer } from 'react-dnd'
import Box from './Box.jsx'

const layerStyles = {
  position: 'absolute',
  left: 0,
  top: 0,
  pointerEvents: 'none',
  zIndex: 100,
  width: '100%',
  height: '100%',
  cursor: 'grabbing !important'
}

// const previewStyleContainer = {
//   position: 'relative',
//   width: '100%',
//   height: '100%',
// }

const getItemStyles = (top, left, rotation, differenceOffset) => {
  if (!differenceOffset) { return { display: 'none' } }
  const transform = rotation && `rotate(${rotation}deg)`
  return {
    position: 'absolute',
    top: top + differenceOffset.y,
    left: left + differenceOffset.x,
    transform,
    // transform,
    // WebkitTransform: transform,
  }
}

export const CustomDragLayer = ({ id, drawMode }) => { // _iuhevjh
  const { isDragging, item, itemType, differenceOffset } =
    useDragLayer((monitor) => ({
      itemType: monitor.getItemType(),
      item: monitor.getItem(),
      differenceOffset: monitor.getDifferenceFromInitialOffset(),
      isDragging: monitor.isDragging(),
    }))
  const isHere = item?.parentId === id;
  if (!isDragging || itemType === "newBox" || !isHere) {
    return null
  }

  return (
    <div id="drag-layer" style={layerStyles}>
      <div
        style={getItemStyles(item.top, item.left, item.rotation, differenceOffset)}
      >
        <Box id={item.id} asPreview drawMode={drawMode} />
      </div>
    </div>
  )
}
