export const getIncrementedName = (originalName, otherNames = []) => {
  const baseName = originalName.replace(/\s\d+$/, '');
  const sameNames = otherNames.filter((name) => {
    const baseNameOther = name.replace(/\s\d+$/, '');
    return baseNameOther === baseName;
  });

  const numberUsed = sameNames.map((name) => {
    const matches = name.match(/\d+$/);
    if (!matches) return 0;
    return parseInt(matches[0]);
  });

  numberUsed.sort((a, b) => a - b);

  let numberToAdd = '';
  let i = 0;

  for (i; i < numberUsed.length; i += 1) {
    if (numberUsed.indexOf(i) === -1) {
      break;
    }
  }
  numberToAdd = i === 0 ? '' : ` ${i}`;
  return `${baseName}${numberToAdd}`;
};
