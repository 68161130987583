import {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react';
import { CaretDownOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import { useTranslation } from 'react-i18next';
// ANT Design
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

// RichEditor
import { handleAlignmentChange, toggleInlineStyle, removeInlineStyle, contentChanged, handleBeforeInput, decorator } from '../RichEditor/utils';
import FontSizeControls from '../TextStylingComponent/FontSizeControls';
import FontFamilyControls from '../TextStylingComponent/FontFamilyControls';
import '../RichEditor/index.css'

import {
  CharacterMetadata,
  ContentBlock,
  ContentState,
  Editor, EditorState, getDefaultKeyBinding, Modifier, RichUtils, SelectionState
} from 'draft-js';
import { replaceVariablesInEditorState, findWithRegex } from '../../utils/dataSync';
import { useStore } from '../../store/store';
import {
  FONT_SIZES,
  MENU_ANCESTORS
} from '../../constants/constants';

// common components
import '../common/Utils.css';


import { getBackgroundBorderStyle, iconButtonStyle } from '../../utils/styles';
import { Separator } from '../Layout/Navbar';
import { NavbarButton } from '../Layout/NavbarButton';
import ColorControl from '../TextStylingComponent/ColorControl';
import HighlightControl from '../TextStylingComponent/HighlightControl';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import NavbarSelector from '../Layout/NavbarSelector';

import { debounce } from 'lodash';
import EditorWrapper from './EditorWrapper';
import { useStyleMap } from '../../hooks';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { EditorProvider } from '../../contexts/EditorContext';

const getBlockStyle = (block) => {
  switch (block.getType()) {
    case 'blockquote':
      return 'RichEditor-blockquote';
    case 'ordered-list-item':
      return 'RichEditor-ordered-list-item-no-marker';
    default:
      return null;
  }
};

export const setDefaultInlineStyleIfNeeded = (
  currentEditorState,
  removedProperty,
  defaultProperty,
  prefix = ''
) => {
  let nextContentState = currentEditorState.getCurrentContent();
  const blocks = [];
  nextContentState.getBlockMap().forEach((block) => {
    const characterList = block.getCharacterList().map((character) => {
      if (character.hasStyle(`${prefix}${removedProperty}`)) {
        return CharacterMetadata.removeStyle(
          character,
          `${prefix}${removedProperty}`
        );
      } else {
        return character;
      }
    });
    blocks.push(
      new ContentBlock({
        key: block.getKey(),
        text: block.getText(),
        type: block.getType(),
        depth: block.getDepth(),
        data: block.getData(),
        characterList,
      })
    );
  });

  return EditorState.push(
    currentEditorState,
    ContentState.createFromBlockArray(blocks),
    'change-inline-style'
  );
};

// Store selector
const selector = (id) => ({ selectedBoxId, removeBox, updateBox, configuration, variables, setBoxMovable, currentDocument, setEventManagerEnabled, fromPdf, boxes, masks }) => {
  let selectedBox = [...boxes, ...masks.map((m) => m.boxes).flat()].find((box) => box?.id === id)

  return {
    selectedBoxId: selectedBoxId,
    removeBox: removeBox,
    updateBox: updateBox,
    colors: configuration.colors,
    variables: variables,
    configuration: configuration,
    isSelected: selectedBoxId === id,
    setBoxMovable: setBoxMovable,
    isTemplate: currentDocument.type === 'template',
    isOffer: currentDocument.type === 'offer',
    setEventManagerEnabled: setEventManagerEnabled,
    fromPdf: fromPdf,
    textStyle: configuration.textStyles.find((ts) => ts.id === selectedBox?.textStyleId)

  };
};

const TextVariable = ({ box, drawMode }) => {
  const editorRef = useRef(null);
  const boundingBoxRef = useRef(null);
  const { updateLeftShownStatus } = useOverlay();
  const {
    updateBox,
    colors,
    variables,
    isSelected,
    isTemplate,
    setBoxMovable,
    configuration,
    setEventManagerEnabled,
    fromPdf,
    textStyle
  } = useStore(selector(box.id));

  const styleMap = useStyleMap()

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    box.content.editorState.getCurrentContent(),
    decorator
  ));

  useEffect(() => {
    if (!isSelected) {
      setEditorState(EditorState.createWithContent(
        box.content.editorState.getCurrentContent(),
        decorator
      ))
    }
  }, [box.content, isSelected]);


  const saveBox = useCallback(debounce((editorState) => {
    if (boundingBoxRef.current) {
      updateBox(box.id, (box) => {
        box.height = boundingBoxRef?.current.firstChild.clientHeight;
        box.content.editorState = EditorState.createWithContent(
          editorState.getCurrentContent(),
          decorator
        );
      });
    }
  }, 100), [box?.id, updateBox]);

  useEffect(() => {
    if (isSelected) {
      saveBox(editorState)
    }
  }, [isSelected, saveBox, editorState]);

  const setEditorContentWithReplacement = (editorObject = editorState) => {
    const regex = new RegExp(/#{.*?}/, 'm');
    const contentIsNew = contentChanged(editorState, editorObject);
    let modifiedEditorState = editorObject;
    if (contentIsNew && variables && !isTemplate && findWithRegex(
      regex,
      modifiedEditorState.getCurrentContent().getPlainText()
    ) != null) {
      // modifiedEditorState = replacePlaceholder(editorObject, variables, documentType)
      modifiedEditorState = replaceVariablesInEditorState(modifiedEditorState, variables);
    }
    setEditorState(modifiedEditorState);
  }

  const write = (editorState) => {
    setEditorContentWithReplacement(editorState);
  };

  const handleKeyCommand = useCallback(
    (command) => {
      if (command) {
        setEditorState(
          RichUtils.toggleInlineStyle(editorState, command.toUpperCase())
        );
      }
    },
    [editorState]
  );

  const handleTab = (e) => {
    e.preventDefault();
    const selection = editorState.getSelection();
    const blockType = editorState
      .getCurrentContent()
      .getBlockForKey(selection.getStartKey())
      .getType();
    const currentContent = editorState.getCurrentContent();
    const blockMap = currentContent.getBlockMap();
    const key = blockMap.last().getKey();
    const newSelection = new SelectionState({
      anchorKey: key,
      focusKey: key,
    });
    let textWithInsert = '';
    let editorWithInsert = '';
    let newEditorState = '';
    if (
      blockType === 'unordered-list-item' ||
      blockType === 'ordered-list-item'
    ) {
      const newState = RichUtils.onTab(e, editorState, 4);
      if (newState) {
        setEditorState(newState);
        return 'handled';
      } else {
        return 'not-handled';
      }
    } else {
      textWithInsert = Modifier.insertText(
        currentContent,
        newSelection,
        '    ',
        null
      );
      editorWithInsert = EditorState.push(
        editorState,
        textWithInsert,
        'insert-characters'
      );
      newEditorState = EditorState.moveFocusToEnd(
        editorWithInsert,
        textWithInsert.getSelectionAfter()
      );
      setEditorState(newEditorState);
    }
  };

  const myKeyBindingFn = (e) => {
    if (e.keyCode === 9 /* `S` key */) {
      handleTab(e);
    }
    return getDefaultKeyBinding(e);
  };

  // If in default container, focus on one click
  useEffect(() => {
    if (!drawMode && isSelected) {
      editorRef.current.focus();
    }
  }, [drawMode, isSelected])

  const applyStyleTextVariable = useCallback((style) => {
    if (box[style] === undefined) {
      return textStyle[style]
    } else {
      return box[style]
    }
  }, [box, textStyle])

  useEffect(() => {
    var root = document.querySelector(':root');
    var element0 = configuration.unorderedListBulletValue0;
    var element1 = configuration.unorderedListBulletValue1;
    var element2 = configuration.unorderedListBulletValue2;
    var element3 = configuration.unorderedListBulletValue3;
    var element4 = configuration.unorderedListBulletValue4;
    var customBulletPadding = configuration.customBulletPadding;

    root.style.setProperty('--unorderedSymbol0', element0);
    root.style.setProperty('--unorderedSymbol1', element1);
    root.style.setProperty('--unorderedSymbol2', element2);
    root.style.setProperty('--unorderedSymbol3', element3);
    root.style.setProperty('--unorderedSymbol4', element4);
    root.style.setProperty('--customBulletPaddingCSS', customBulletPadding);
  }, [
    configuration.unorderedListBulletValue,
    configuration.unorderedListBulletValue0,
    configuration.unorderedListBulletValue1,
    configuration.unorderedListBulletValue2,
    configuration.unorderedListBulletValue3,
    configuration.unorderedListBulletValue4,
    configuration.customBulletPadding,
  ]);

  const placeholder = useMemo(() => {
    const bulletStatus = RichUtils.getCurrentBlockType(editorState)
    return fromPdf ||
      bulletStatus === "unordered-list-item" ||
      bulletStatus === "ordered-list-item" ?
      "" : 'Votre texte ici'
  }, [editorState, fromPdf])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
      <div
        ref={boundingBoxRef}
        id={box.id}
        style={{
          ...getBackgroundBorderStyle(applyStyleTextVariable("backgroundColor"), applyStyleTextVariable("border")),
          width: box.width,
          fontFamily: textStyle.fontFamily,
          fontSize: textStyle.fontSize,
          color: textStyle.color.label,
          fontWeight: textStyle.fontWeight,
          fontStyle: textStyle.fontStyle,
          textDecoration: textStyle.textDecoration,
        }}
        onClick={() => {
          if (isSelected && !editorState.getSelection().getHasFocus()) {
            editorRef.current.focus();
          }
        }}
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type)) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
      >
        <div style={{
          padding: `${textStyle.paddingTopBottom}px ${textStyle.paddinngLeftRight}px`
        }}>
          <EditorWrapper boxId={box.id} editorState={editorState}>
            <Editor
              stripPastedStyles={true}
              handleBeforeInput={(chars, editorState) => handleBeforeInput(
                {
                  chars,
                  editorState,
                  setEditorState
                }
              )}
              placeholder={placeholder}
              blockStyleFn={getBlockStyle}
              className='RichEditor-editor'
              style={{
                overflow: 'visible',
              }}
              customStyleMap={styleMap}
              editorState={editorState}
              handleKeyCommand={handleKeyCommand}
              onBlur={() => saveBox(editorState)}
              onFocus={(event) => {
                setBoxMovable(false);
                setEventManagerEnabled(false);
              }}
              onChange={write}
              keyBindingFn={myKeyBindingFn}
              spellCheck={true}
              ref={editorRef}
              textAlignment={box.content.alignment || textStyle.positions.horizontal}
              readOnly={!isSelected}
            />
          </EditorWrapper>
        </div>
      </div>
      {isSelected &&
        ReactDOM.createPortal(
          <ContextualMenu
            editorState={editorState}
            setEditorState={setEditorState}
            menuAncestorType={MENU_ANCESTORS.BOX.content}
            menuAncestorIds={menuAncestorIds}
          />,
          document.getElementById('SelectionVariablePortal')
        )}
      {isSelected &&
        ReactDOM.createPortal(
          <TextStylingComponent
            box={box}
            updateBox={updateBox}
            editorState={editorState}
            setEditorState={setEditorState}
            variables={variables}
            colors={colors}
            textStyle={textStyle}
          />,
          document.getElementById('ComponentPortal')
        )}
    </EditorProvider>
  );
};

const TextStylingComponent = ({
  box,
  updateBox,
  editorState,
  setEditorState,
  colors,
  textStyle
}) => {
  const { t } = useTranslation();
  const { configuration } = useStore(selector(box.id));

  const toggleBlockType = (blockType) => {
    setEditorState(RichUtils.toggleBlockType(editorState, blockType));
  };

  const textStyleButtons = [
    {
      title: t('bold'),
      textContent: 'G',
      style: { fontWeight: 'bold', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('BOLD'),
      inlineStyleName: 'BOLD'
    },
    {
      title: t('italic'),
      textContent: 'I',
      style: { fontStyle: 'italic', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('ITALIC'),
      inlineStyleName: 'ITALIC'
    },
    {
      title: t('underline'),
      textContent: 'S',
      style: { textDecoration: 'underline', fontFamily: 'monospace' },
      active: editorState.getCurrentInlineStyle(editorState.getSelection()).has('UNDERLINE'),
      inlineStyleName: 'UNDERLINE'
    }
  ];


  const updateBoxOnStylingChange = useCallback((newEditorState) => {
    updateBox(box.id, (box) => {
      box.content.editorState = EditorState.createWithContent(
        newEditorState.getCurrentContent(),
        decorator
      );
    });
  }, [box?.id, updateBox]);

  const toggleInlineState = (inlineStyleName) => (_e) => {
    setEditorState(RichUtils.toggleInlineStyle(editorState, inlineStyleName))
  }

  const applyFontSize = (toggledFontSize) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `FONT_SIZE_${toggledFontSize}`,
      prefix: 'FONT_SIZE_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };
  const applyColor = (toggledColor) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `${toggledColor}`,
      prefix: 'color_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };
  const applyHighlightcolor = (toggledHighlightColor) => {
    const newEditorState = toggledHighlightColor === "transparent"
      ? removeInlineStyle({
        prefix: 'HIGHLIGHT_',
        editor: editorState
      })
      : toggleInlineStyle({
        selectedProperty: `HIGHLIGHT_${toggledHighlightColor}`,
        prefix: 'HIGHLIGHT_',
        editor: editorState
      })
    setEditorState(newEditorState)
    updateBoxOnStylingChange(newEditorState)
  };
  const applyFontFamily = (toggledFontFamily) => {
    const newEditorState = toggleInlineStyle({
      selectedProperty: `FONT_FAMILY_${toggledFontFamily.replace(/\s+/g, "_")}`,
      prefix: 'FONT_FAMILY_',
      editor: editorState
    })
    setEditorState(newEditorState);
    updateBoxOnStylingChange(newEditorState)
  };

  const alignement = box.content.alignment || textStyle.positions.horizontal

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarButton
        tooltipContent={t('left-alignment')}
        onClick={() => handleAlignmentChange(box.id, updateBox, 'left')}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-left', style: 'light' })} style={iconButtonStyle(alignement === 'left')} />}
      />
      <NavbarButton
        tooltipContent={t('center-alignment')}
        onClick={() => handleAlignmentChange(box.id, updateBox, 'center')}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-center', style: 'light' })} style={iconButtonStyle(alignement === 'center')} />}
      />
      <NavbarButton
        tooltipContent={t('right-alignment')}
        onClick={() => handleAlignmentChange(box.id, updateBox, 'right')}
        icon={< FontAwesomeIcon icon={icon({ name: 'align-right', style: 'light' })} style={iconButtonStyle(alignement === 'right')} />}
      />
      <Separator />
      <NavbarButton
        tooltipContent={t('LISTE_A_PUCE')}
        onClick={() => toggleBlockType('unordered-list-item')}

        icon={< FontAwesomeIcon icon={icon({ name: 'list-ul', style: 'light' })} style={iconButtonStyle(RichUtils.getCurrentBlockType(editorState) === "unordered-list-item")} />}
      />
      <NavbarButton
        tooltipContent={t('LISTE_NUMEROTEE')}
        onClick={() => {
          toggleBlockType('ordered-list-item');
        }}
        icon={< FontAwesomeIcon icon={icon({ name: 'list-ol', style: 'light' })} style={iconButtonStyle(RichUtils.getCurrentBlockType(editorState) === "ordered-list-item")} />}
      />
      <Separator />
      {configuration.fontFamilyList.length > 1 && (
        <FontFamilyControls
          applyFontFamily={applyFontFamily}
          type={box.type}
          editorState={editorState}
          fontFamily={textStyle.fontFamily}
        />
      )}
      <FontSizeControls
        applyFontSize={applyFontSize}
        defaultFontSize={textStyle.fontSize}
        type={box.type}
        editorState={editorState}
        fontSizes={FONT_SIZES}
      />
      {textStyleButtons.map((button) => {
        return (
          <NavbarButton
            key={button.title}
            tooltipContent={button.title}

            onClick={toggleInlineState(button.inlineStyleName)}
            icon={
              <p style={{ ...iconButtonStyle(button.active), ...button.style }}>
                {button.textContent}
              </p>}
          />
        )
      })}
      <ColorControl
        type={box.type}
        editorState={editorState}
        onToggle={applyColor}
        colors={colors}
      />
      <HighlightControl
        type={box.type}
        editorState={editorState}
        onToggle={applyHighlightcolor}
        colors={configuration.highlightColors}
      />
      <Separator />
      <NavbarSelector
        tooltipContent={t('text-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(box.id, (box) => {
            box.textStyleId = configuration.textStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={textStyle?.name}
        options={configuration.textStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      <Separator />
    </div>
  );
};

export default TextVariable;
