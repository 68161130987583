import React, { useCallback, useRef, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from 'antd';
import { lineSpacing, pageHeight, pageWidth } from '../../constants/gridConfig';
import AdderMenu from './AdderMenu';
import { useOutsideAlerter } from '../../hooks';
import { containerBackground, pageBackground, roundButtonWidth } from '../../constants/constants';
import { useStore } from '../../store/store';

const selector = ({ landscape,
  addContainers }) => ({
    landscape,
    addContainers
  })

const AdderColumns = ({
  containerIndex = null,
  withoutContainers = false,
  columnId,
  columnIndex = null,
  boxIsOver,
  canDrop
}) => {
  const { landscape, addContainers } = useStore(selector)
  const [isHovered, setIsHovered] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  const unlockProposal = useStore(({ configuration }) => configuration.unlockProposal)
  const isTemplate = useStore(({ currentDocument }) => currentDocument.type === 'template')
  useOutsideAlerter(wrapperRef, setMenuIsOpen);

  const closeMenu = useCallback(() => {
    setMenuIsOpen(false)
  }, []);

  const canEdit = isTemplate || unlockProposal

  const show = canEdit && ((boxIsOver && canDrop) || (!boxIsOver && (isHovered || menuIsOpen || withoutContainers)));

  const onClick = () => {
    if (!canEdit) return;
    if (!landscape) {
      setMenuIsOpen(!menuIsOpen)
    } else {
      addContainers({ index: containerIndex, drawMode: true });
    }
  }

  const width = columnId ? roundButtonWidth : lineSpacing
  const fontSize = columnId ? 15 : 9

  return (
    <div
      ref={wrapperRef}
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        width: columnId ? roundButtonWidth : (landscape ? pageHeight : pageWidth),
        height: columnId ? "100%" : width,
      }}>
      <div
        style={{
          display: 'flex',
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
          flexDirection: columnId ? "column" : "row",
          width: columnId ? roundButtonWidth : "100%",
          height: columnId ? "100%" : width,
        }}>
        <div
          onMouseOver={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={onClick}
          data-role={pageBackground}
          data-parent={containerBackground}
          style={{
            backgroundColor: show ? '#4096ff63' : 'transparent',
            display: 'flex',
            alignItems: "center",
            transition: "ease-in-out 0.2s",
            cursor: canEdit ? "pointer" : 'default',
            flexDirection: columnId ? "column" : "row",
            width: columnId ? 20 : "100%",
            height: columnId ? "100%" : width,
          }}>
          <div style={{
            backgroundColor: show ? '#4096ff3d' : 'transparent',
            display: 'flex',
            justifyContent: "center",
            position: "relative",
            flexDirection: columnId ? "column" : "row",
            width: columnId ? 1 : "100%",
            height: columnId ? "100%" : 1,
          }}>
            {show && <Button shape="circle" size={14}
              style={{
                width: width,
                height: width,
                minWidth: width,
                minHeight: width,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "absolute",
                transform: columnId ? "translateX(calc(-50% + 0.5px))" : "translateY(calc(-50% + 0.5px)",
              }}
              icon={< FontAwesomeIcon icon={icon({ name: 'plus', style: 'light' })}
                style={{ margin: 0, fontSize }} />}
            />}
          </div>
        </div>
      </div>
      {menuIsOpen && <AdderMenu
        columnId={columnId}
        containerIndex={containerIndex}
        closeMenu={closeMenu}
        columnIndex={columnIndex}
      />}
    </div>
  )
}

export default AdderColumns
