import React from 'react'
import { useStore } from '../../store/store'
import { Dragger } from '../common/Dragger'
import ContainerWrapper from '../Container/ContainerWrapper'
import { gridUnit, lineSpacing } from '../../constants/gridConfig'
import { isASelectionBox, simulateVariableTextBox } from '../../utils/boxes'
import CustomTextVariable from './CustomTextVariable'
import { viewerTypes } from '../../constants/constants'

const selector = (boxId) => ({
  boxes,
  currentDocument,
  columns,
  landscape,
  containers,
  configuration,
  variables
}) => {
  const boxInStore = boxes.find((b) => b.id === boxId)
  const box = boxInStore ??
    simulateVariableTextBox(Object.entries(variables).find(([key]) => key === boxId))
  const isCustomVariableText = !boxInStore
  return {
    isCustomVariableText,
    container: !isCustomVariableText && containers.find((c) => c.columnsIds.includes(box.columnId)),
    drawMode: !isCustomVariableText && columns.find((c) => c.id === box.columnId).drawMode,
    documentType: currentDocument.type,
    landscape,
    marginLeft: configuration.margins.left * gridUnit,
    marginRight: configuration.margins.right * gridUnit,
    boxType: box?.type
  }
}

const VariableContainer = ({ boxId }) => {
  const { container, drawMode, marginLeft, marginRight, boxType, isCustomVariableText, documentType } = useStore(selector(boxId))
  const showOnlyBox = (drawMode && !isASelectionBox(boxType))

  return (
    <>
      {!isCustomVariableText && !showOnlyBox && <ContainerWrapper
        containerId={container.id}
        fromViewer={viewerTypes.VARIABLES}
      />
      }
      {!isCustomVariableText && showOnlyBox &&
        <div style={{
          width: 'fit-content',
          paddingLeft: marginLeft,
          paddingRight: marginRight,
          position: 'relative'
        }}>
          <Dragger fromViewer={viewerTypes.VARIABLES} id={boxId} drawMode={drawMode} />
        </div>
      }
      {isCustomVariableText && documentType !== 'template' && <CustomTextVariable variableKey={boxId} />}
      <div style={{ height: lineSpacing, width: "100%" }}></div>
    </>
  )
}

export default VariableContainer
