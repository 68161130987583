import React from 'react'
import { pageBackground, VARIABLE_COLOR } from '../../constants/constants';
import VariablesProposalName from './VariablesProposalName';
import VariableContainer from './VariableContainer';


const VariablesProposalContainer = ({
  hasVariableSelected,
  variablesGroupedByContainer,
  index,
  box,
}) => {

  const getStyle = (variableGroupBox) => {
    const style = {}

    let gridTemplateColumns = "";
    let dispo;
    if (variableGroupBox.disposition) dispo = variableGroupBox.disposition
    else dispo = style.disposition
    for (let i = 0; i < dispo; i++) {
      gridTemplateColumns += "1fr "
    }
    return {
      display: "grid",
      gridTemplateColumns,
    }
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <fieldset data-role={pageBackground} style={{
        border: `1px dashed ${hasVariableSelected ? VARIABLE_COLOR : "#b1b1b1"}`,
        marginBottom: '15px',
        fontStyle: 'italic',
      }}>
        <legend style={{
          width: "fit-content",
          marginLeft: 16,
          fontSize: 14,
          padding: '0 8px',
          fontWeight: "bold",
          display: 'flex'
        }}>
          {variablesGroupedByContainer[index].variableGroupBox ?
            <VariablesProposalName
              variableContainer={variablesGroupedByContainer[index]}
              variableBox={variablesGroupedByContainer[index].variableGroupBox}
              hasVariableSelected={hasVariableSelected}
              isLast
            /> : variablesGroupedByContainer[index].variablesBoxes.map((v, idx) =>
              <VariablesProposalName
                key={v.id}
                variableBox={v}
                variableContainer={variablesGroupedByContainer[index]}
                hasVariableSelected={hasVariableSelected}
                isLast={idx + 1 === variablesGroupedByContainer[index].variablesBoxes.length}
              />
            )
          }
        </legend>
        {variablesGroupedByContainer[index].variableGroupBox ?
          <div data-role={pageBackground} style={{
            ...getStyle(variablesGroupedByContainer[index].variableGroupBox)
          }}>
            {variablesGroupedByContainer[index].variablesBoxes.filter((vB) => !vB.notUsed)
              .map((v) =>
                <div key={v.id} style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                  <div style={{
                    padding: "0 16px"
                  }}>
                    <VariablesProposalName
                      variableBox={v}
                      variableContainer={variablesGroupedByContainer[index]}
                      hasVariableSelected={box.id === v.id}
                      isLast
                    />
                  </div>
                  <VariableContainer boxId={v.id} />
                </div>)
            }
          </div>
          :
          <VariableContainer boxId={variablesGroupedByContainer[index].variablesBoxes[0].id} />
        }
      </fieldset>
    </div>
  )
}

export default VariablesProposalContainer
