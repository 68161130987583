import { Checkbox, Form, Select } from 'antd';
import { TitlesTypes } from '../../../constants/constants';
// import {
//   selectorMaxWidth,
//   selectorMinWidth,
// } from '../../../constants/gridConfig';
import { useStore } from '../../../store/store';
import TitleConfiguration from '../../common/TitleConfiguration';

const selector = ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
}) => ({
  configuration,
  setConfiguration,
  summaryConfiguration,
  setSummaryConfiguration,
  paysageMode,
  setPaysageMode,
  currentTemplate,
});

export const TabsTitles = () => {
  const {
    configuration,
    setConfiguration,
    summaryConfiguration,
    setSummaryConfiguration,
  } = useStore(selector);

  const styleRuleChangeObject = (options = {}) => {
    return {
      setFunction: options.summary ? setSummaryConfiguration : setConfiguration,
      configObject: options.summary ? summaryConfiguration : configuration,
    };
  };

  const handleSelectStyle =
    (type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontStyle: e.target.checked ? 'italic' : '',
          },
        });
      };

  const handleSelectColor =
    (type, options = {}) =>
      (selectedColor) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            color: configuration.colors.find(
              (color) => color.label === selectedColor
            ),
          },
        });
      };

  const handleSelectWeight =
    (type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            fontWeight: e.target.checked ? 'bold' : '',
          },
        });
      };

  const handleSelectDecoration =
    (type, options = {}) =>
      (e) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: {
            ...change.configObject[type],
            textDecoration: e.target.checked ? 'underline' : '',
          },
        });
      };

  const handleChangeFontSize =
    (type, options = {}) =>
      (value) => {
        const change = styleRuleChangeObject(options);
        change.setFunction({
          [type]: { ...change.configObject[type], fontSize: value },
        });
      };

  const handleChangeBackgroundColor = (type) => (selectedColor) => {
    setConfiguration({
      [type]: {
        ...configuration[type],
        backgroundColor: configuration.colors.find(
          (color) => color.label === selectedColor
        ),
      },
    });
  };

  const handleChangeBorderColor = (type) => (selectedColor) => {
    setConfiguration({
      [type]: {
        ...configuration[type],
        borders: {
          ...configuration[type].borders,
          color: configuration.colors.find(
            (color) => color.label === selectedColor
          ),
        },
      },
    });
  };

  const handleChangeBorderPlace = (type, place) => (e) => {
    if (place !== 'all') {
      setConfiguration({
        [type]: {
          ...configuration[type],
          borders: {
            ...configuration[type].borders,
            [place]: !configuration[type].borders[place],
          },
        },
      });
    } else {
      if (
        configuration[type].borders.top === true &&
        configuration[type].borders.bottom === true &&
        configuration[type].borders.left === true &&
        configuration[type].borders.right === true
      ) {
        setConfiguration({
          [type]: {
            ...configuration[type],
            borders: {
              ...configuration[type].borders,
              top: false,
              bottom: false,
              left: false,
              right: false,
            },
          },
        });
      } else {
        setConfiguration({
          [type]: {
            ...configuration[type],
            borders: {
              ...configuration[type].borders,
              top: true,
              bottom: true,
              left: true,
              right: true,
            },
          },
        });
      }
    }
  };

  const handleChangeTypeTitle = (type) => (e) => {
    setConfiguration({
      [type]: { ...configuration[type], typeTitle: e },
    });
  };

  const handleChangeSeparatorTitle = (value) => {
    setConfiguration({
      ...configuration,
      separatorTitle: value,
    });
  };

  return (
    <div>
      <Form.Item label="Cacher l'index des titres de niveau 1" name='hide-title-1-index'>
        <Checkbox
          style={{ fontSize: '14px', marginTop: '0.5%' }}
          checked={configuration.hideTitleIndex}
          onChange={(e) => {
            setConfiguration({
              ...configuration,
              hideTitleIndex: e.target.checked,
            });
          }}
        >
        </Checkbox>
      </Form.Item>
      <Form.Item
        label='Choix du séparateur'
        name='separatorTitle'
        initialValue={configuration.separatorTitle}
      >
        <Select
          onChange={handleChangeSeparatorTitle}
          style={{ width: 'auto' }}
          options={[
            { value: '-', label: '-' },
            { value: '.', label: '.' },
            { value: '_', label: '_' },
            { value: '#', label: '#' },
          ]}
        >
        </Select>
      </Form.Item>
      {
        Object.values(TitlesTypes).map((titleType, index) => {
          return (
            <TitleConfiguration
              key={`title-${titleType}`}
              level={index + 1}
              title={titleType}
              styleRules={configuration[titleType]}
              colors={configuration.colors}
              onChangeVisibility={null}
              onChangeColor={handleSelectColor(titleType)}
              onChangeFontStyle={handleSelectStyle(titleType)}
              onChangeWeight={handleSelectWeight(titleType)}
              onChangeTextDecoration={handleSelectDecoration(titleType)}
              onChangeFontSize={handleChangeFontSize(titleType)}
              onChangeBackgroundColor={handleChangeBackgroundColor(titleType)}
              onChangeBorderColor={handleChangeBorderColor(titleType)}
              onChangeBorderPlace={handleChangeBorderPlace}
              onChangeType={handleChangeTypeTitle(titleType)}
              configuration={configuration}
              titleType={titleType}
              type='title'
            />
          );
        })
      }
    </div >
  );
};
