import React, { useCallback, useEffect } from 'react'
import { Virtuoso } from 'react-virtuoso';
import { useStore } from '../../store/store';
import { pageBackground, variableHandlingType } from '../../constants/constants';
import { pageHeight, pageWidth } from '../../constants/gridConfig';
import VariablesProposalContainer from './VariablesProposalContainer';

const selector = ({
  landscape,
}) => {
  return {
    landscape
  }
};
const List = React.forwardRef((props, ref) => {
  const {
    landscape
  } = useStore(selector);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: 'center',
      }}
      data-role={pageBackground}>
      <div
        {...props}
        ref={ref}
        style={{
          backgroundColor: 'rgb(245, 245, 245)',
          width: landscape ? pageHeight : pageWidth,
          paddingTop: 16,
          marginBottom: 16,
          ...props.style
        }}
        data-role={pageBackground}
      />
    </div>
  )
})

const listRef = React.createRef();
export const scrollToItemInVariableEditor = (box, variablesGroupedByContainer) => {
  const containerIdx = variablesGroupedByContainer.filter((vInfos) => {
    if (vInfos.type === variableHandlingType.FORM) return true
    if (vInfos.variableGroupBox)
      return vInfos.variablesBoxes.some((v) => !v.notUsed)
    return !vInfos.variablesBoxes[0].notUsed
  }).findIndex((v) => {
    if (v.variableGroupBox && v.variableGroupBox.id === box.id) return true
    return v.variablesBoxes.some((b) => b.id === box.id)
  })
  listRef.current?.scrollToIndex(containerIdx);
};

const VariablesProposal = ({ variablesGroupedByContainer, box, variablesGroupedByContainerSelected }) => {

  useEffect(() => {
    scrollToItemInVariableEditor(box, variablesGroupedByContainer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderVariable = useCallback((index) => {
    const hasVariableSelected = (variablesGroupedByContainer[index].variableGroupBox?.id === box.id) ||
      variablesGroupedByContainer[index].variablesBoxes.some((b) => b.id === box.id)
    return <VariablesProposalContainer
      hasVariableSelected={hasVariableSelected}
      variablesGroupedByContainer={variablesGroupedByContainer}
      index={index}
      box={box}
      variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
    />
  }, [box, variablesGroupedByContainer, variablesGroupedByContainerSelected])

  return (
    <Virtuoso
      ref={listRef}
      style={{
        height: "100%",
        width: '100%'
      }}
      className='no-scrollbar'
      totalCount={variablesGroupedByContainer.length}
      itemContent={renderVariable}
      computeItemKey={(index) => {
        return variablesGroupedByContainer[index].variableGroupBox?.id ?? variablesGroupedByContainer[index].variablesBoxes[0].id
      }}
      components={{ List }}
    />
  )
}

export default VariablesProposal
