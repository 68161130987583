import { ItemTypes, minContainerHeight, uuid } from '../constants/constants';
import {
  masksDefaultHeight,
  pageHeight,
  pageWidth,
} from '../constants/gridConfig';
import { isASelectionBox, mutateSelectContainer } from './boxes';
import { mutateAddColumns, mutateDeleteColumns, newColumn } from './columns';

export const newContainer = ({
  height = 'auto',
  columnsIds = [],
  editableConfiguration,
  KeyPdfToImage = null,
}) => {
  return {
    id: uuid(),
    columnsIds,
    height,
    editable: editableConfiguration,
    ...(KeyPdfToImage && { KeyPdfToImage})
  };
};

export const mutateAddContainers = ({
  draft,
  index = draft.containers.length,
  count = 1,
  columnsSizes = [1],
  drawMode = false,
  box,
  from = null,
  KeyPdfToImage = null,
}) => {
  const boxes = !box || box.type === ItemTypes.DRAW ? [] : [box];
  const containerHeight = computeContainerHeight({
    drawMode,
    landscape: draft.landscape,
    boxType: box?.type,
    from,
  });
  const containersToInsert = Array(count)
    .fill()
    .map(() =>
      newContainer({
        height: containerHeight,
        editableConfiguration:
          draft.currentProposal?.id !== null
            ? true
            : draft.configuration.editableTemplate,
        KeyPdfToImage,
      })
    );
  containersToInsert.forEach((container, i) => {
    const columns = columnsSizes.map((size) => newColumn({ size, drawMode }));
    const columnsIds = columns.map((c) => c.id);

    container.columnsIds = columnsIds;
    if (index !== null) {
      draft.containers.splice(index + i + 1, 0, container);
    } else {
      draft.containers.unshift(container);
    }
    mutateAddColumns(draft, columns, boxes);
  });
  if (containersToInsert[0]) {
    mutateDropInContainer({ draft, container: containersToInsert[0], box });
  }
};

export const mutateRemoveContainer = (draft, containerId) => {
  let columnsToDeleteIds = [];
  draft.containers = draft.containers.filter((c) => {
    if (c.id === containerId) {
      columnsToDeleteIds = c.columnsIds;
      return false;
    }
    return true;
  });
  mutateDeleteColumns(draft, columnsToDeleteIds);
};

export const mutateRemoveContainers = (draft, containerIds = []) => {
  let columnsToDeleteIds = [];
  draft.containers = draft.containers.filter((c) => {
    if (containerIds.includes(c.id)) {
      columnsToDeleteIds.push(c.columnsIds);
      return false;
    }
    return true;
  });
  mutateDeleteColumns(draft, columnsToDeleteIds.flat());
};

export const mutateRemmoveSectionContainers = (
  draft,
  sectionId,
  dateTimestamp
) => {
  let columnsToDeleteIds = [];
  draft.containers = draft.containers.filter((c) => {
    if (c.sectionId === sectionId && c.dateTimestamp === dateTimestamp) {
      columnsToDeleteIds = columnsToDeleteIds.concat(c.columnsIds);
      return false;
    }
    return true;
  });

  mutateDeleteColumns(draft, columnsToDeleteIds);
};

export const mutateUpdateContainer = (draft, updatedContainer) => {
  draft.containers = draft.containers.map((c) => {
    if (c.id === updatedContainer.id) {
      return updatedContainer;
    }
    return c;
  });
};

export const computeContainerHeight = ({ drawMode, landscape, boxType, from = null }) => {
  let containerHeight = 'auto';
  if (drawMode) {
    if(from === "PdfToImageSizeMaxDefault"){
      containerHeight = maxContainerHeight({header: null, footer: null})
    } else if (landscape && (!boxType || !isASelectionBox(boxType))) {
      containerHeight = pageWidth;
    } else if (boxType === ItemTypes.SECTION_VARIABLE) {
      containerHeight = minContainerHeight + 32;
    } else {
      containerHeight = minContainerHeight;
    }
  }
  return containerHeight;
};

export const mutateDropInContainer = ({ draft, container, box }) => {
  const drawMode = draft.columns.some(
    (c) => container.columnsIds.includes(c.id) && c.drawMode
  );
  mutateSelectContainer(draft, container);
  if (box && !drawMode) {
    draft.selectedBoxId = box.id;
  }
};

export const maxContainerHeight = ({ header, footer }) => {
  return (
    pageHeight -
    (header?.height ?? masksDefaultHeight.header) -
    (footer?.height ?? masksDefaultHeight.footer)
  );
};
