import React, { useMemo } from 'react'
import { useStore } from '../../store/store';
import { ItemTypes, VARIABLE_COLOR } from '../../constants/constants';
import { convertContentToRawText, getBlockStyle } from '../RichEditor/utils';
import { Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { transformTextToEditorState } from '../../utils/dataSync';
import { Editor } from 'draft-js';
import { useStyleMap } from '../../hooks';
import { isATitleBox } from '../../utils/boxes';
import { displayTitleIndex, getTitlesConfig } from '../../utils/titles';
import { useTranslation } from 'react-i18next';
import VariablesProposalUpdater from './VariablesProposalUpdater';

const VariablesProposalName = ({ variableBox, isLast, hasVariableSelected, variableContainer }) => {
  const styleMap = useStyleMap()
  const { t } = useTranslation()
  const boxes = useStore((state) => state.boxes)
  const defaultVariablesApi = useStore(({ defaultVariablesApi }) => defaultVariablesApi)
  const titleBoxesIndex = useStore((state) => state.boxes.reduce((filtered, box, index) => {
    if (isATitleBox(box.type)) {
      filtered.push({
        index, title: `${displayTitleIndex(
          box.content.indexTitle,
          state.configuration.separatorTitle,
          getTitlesConfig(state.configuration),
          state.configuration.hideTitleIndex
        )} ${box.content.editorState.getCurrentContent().getPlainText()}`
      });
    }
    return filtered;
  }, []));

  const textColor = hasVariableSelected ? VARIABLE_COLOR : "#b1b1b1";

  const getTitle = (v) => {
    const baseTitle = t('remarks')
    const vIndex = boxes.findIndex((b) => b.id === v.id);
    if (titleBoxesIndex.length < 1 ||
      titleBoxesIndex.every(({ index }) => vIndex < index)) {
      return `${baseTitle} :`;
    }
    return `${titleBoxesIndex.find(({ index }, currentIndex) => {
      return vIndex > index && (
        currentIndex + 1 === titleBoxesIndex.length ||
        vIndex < titleBoxesIndex[currentIndex + 1].index
      )
    }).title} :`
  }

  const canBeUpdated = useMemo(() => {
    const isVariableValueDifferent = (variable) => {
      const keys = variable.id.split('.')

      if (keys.length !== 2 && keys.length !== 3) return false

      const categoryKey = keys.length === 3 ? keys[1] : keys[0]

      let value;

      const informationKey = keys.length === 3 ? keys[2] : keys[1]

      if (keys.length === 3) {
        const indexKey = defaultVariablesApi.opportunities_variables.findIndex((opp) => opp[Object.keys(opp)[0]].opportunity.CHRONO === keys[0])
        const opportunityKey = Object.keys(defaultVariablesApi.opportunities_variables[indexKey])[0]
        value = defaultVariablesApi.opportunities_variables[indexKey][opportunityKey][categoryKey][informationKey] ?? ''
      } else if (categoryKey === 'custom') {
        value = defaultVariablesApi.custom_text_variables[`${variable.name}.${informationKey}`] ?? ''
      }
      else if (defaultVariablesApi.offer_variables) {
        value = defaultVariablesApi.offer_variables[categoryKey][informationKey] ?? ''
      } else {
        value = defaultVariablesApi[categoryKey][informationKey]
      }
      if (value !== '' && value !== variable.value) {
        return true
      }
      return false
    }
    if (variableBox.type === ItemTypes.CUSTOM_TEXT_VARIABLE) {
      return (isVariableValueDifferent(variableBox))
    }
    if (variableBox.type === ItemTypes.VARIABLE_GROUP) {
      return (variableContainer.variablesBoxes.filter((v) => !v.notUsed).some((variable) => isVariableValueDifferent(variable)))
    }
    return false
  }, [variableBox, defaultVariablesApi, variableContainer])

  return <span key={variableBox.id} style={{
    display: 'flex',
    alignItems: 'center',
    minHeight: 25
  }}>
    <span style={{ color: textColor }}>{typeof variableBox.name === "string" ? variableBox.name : <Editor
      readOnly
      editorState={variableBox.name} />}</span>
    {variableBox.remark && convertContentToRawText(transformTextToEditorState(variableBox.remark)).trim() !== '' &&
      <Popover
        placement='top'
        mouseLeaveDelay='0'
        content={<Editor
          stripPastedStyles={true}
          readOnly
          editorState={transformTextToEditorState(variableBox.remark)}
          customStyleMap={styleMap}
          blockStyleFn={getBlockStyle}
          className='RichEditor-editor'
          style={{
            overflow: 'visible',
          }}
        />}
        title={getTitle(variableBox)}
      >
        {" "}
        <FontAwesomeIcon
          icon={icon({ name: 'circle-info', style: 'solid' })}
          style={{ marginLeft: 2, color: "#5499DE" }}
        />
      </Popover>
    }
    {canBeUpdated && <span><VariablesProposalUpdater
      variableIds={
        variableBox.type === ItemTypes.VARIABLE_GROUP ? variableContainer.variablesBoxes.filter((v) => !v.notUsed && v.id.split('.')[0] !== 'custom').map(({ id }) => id) :
          [variableBox.id]
      }
    /></span>}
    <span style={{ color: "#b1b1b1" }}>{isLast ? "" : " - "}</span>
  </span>
}

export default VariablesProposalName
