import React, { useEffect, useState } from 'react'
import TextStylingComponent from '../TextStylingComponent/TextStylingComponent';

const FloatyTextStyling = ({
  editorState,
  setEditorState,
  defaultFontSize,
  defaultFontFamily,
  defaultColor
}) => {
  const [menuPosition, setMenuPosition] = useState({ top: null, left: null });

  useEffect(() => {
    const selection = window.getSelection && window.getSelection();
    if (selection && selection.rangeCount > 0 && editorState.getSelection().getStartOffset() !== editorState.getSelection().getEndOffset()) {
      const selectionRange = selection.getRangeAt(0);
      const { top, left, right } = selectionRange.getBoundingClientRect()
      setMenuPosition((oldPos) => {
        if (oldPos.top === null || oldPos.left === null)
          return { top: top - 50, left: editorState.getSelection().getIsBackward() ? left : right }
        return oldPos
      })
    } else {
      setMenuPosition({ top: null, left: null })
    }
  }, [editorState]);

  if (menuPosition.top === null || menuPosition.left === null) return null

  return (
    <div style={{
      position: 'absolute',
      zIndex: 9998,
      background: '#0b2239',
      padding: '4px 6px',
      borderRadius: 8,
      ...menuPosition
    }}
      onMouseDown={(e) => {
        e.stopPropagation()
        e.preventDefault()
      }}
    >
      <TextStylingComponent
        editorState={editorState}
        setEditorState={setEditorState}
        defaultFontSize={defaultFontSize}
        defaultFontFamily={defaultFontFamily}
        defaultColor={defaultColor}
        updateBoxOnStylingChange={() => null}
        hasOptionsAfter={false}
      />
    </div>
  )
}

export default FloatyTextStyling
