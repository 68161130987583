import { borderColumnWidth, uuid } from '../constants/constants';
import { pageHeight, pageWidth } from '../constants/gridConfig';
import { mutateAddBoxes, mutateRemoveBox } from './boxes';

export const newColumn = ({
  size = 1,
  drawMode = false,
  isOldPage = false,
  backgroundColor = 'transparent',
}) => {
  const id = uuid();
  return {
    id,
    size,
    drawMode,
    isOldPage,
    backgroundColor,
  };
};

export const mutateAddColumns = (draft, newColumns = [], boxes = []) => {
  draft.columns = draft.columns.concat(newColumns);
  newColumns.forEach((column, i) => {
    const newBoxes = boxes.map((box) => ({ ...box, columnId: column.id }));
    mutateAddBoxes({ draft, newBoxes, drawMode: column.drawMode });
  });
};

export const mutateDeleteColumns = (draft, columnsToDeleteIds = []) => {
  draft.boxes
    .filter((c) => columnsToDeleteIds.includes(c.columnId))
    .forEach((box) => mutateRemoveBox(draft, box.id));
  draft.columns = draft.columns.filter(
    (c) => !columnsToDeleteIds.includes(c.id)
  );
};

export const computeColumnWidth = ({
  drawMode = false,
  marginLeft,
  marginRight,
  containerColumnsLength = 1,
  size = 1,
  landscape = false,
}) =>
  ((landscape ? pageHeight : pageWidth) -
    (drawMode ? 0 : marginLeft + marginRight) -
    (containerColumnsLength - 1) * borderColumnWidth) *
  size;

export const mutateUpdateColumn = (draft, updatedColumn) => {
  draft.columns = draft.columns.map((c) => {
    if (c.id === updatedColumn.id) {
      return updatedColumn;
    }
    return c;
  });
};
