import { Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../store/store';
import { iconButtonStyle, navbarButtonStyle } from '../../utils/styles';
import { NavbarListButton } from './NavbarListButton';
import { ItemTypes } from '../../constants/constants';

const selector = (state) => {
  const box = [
    ...state.boxes,
    ...state.masks.map((m) => m.boxes).flat(),
  ].find((b) => b.id === state.selectedBoxId)
  return {
    alignBoxDefault: state.alignBoxDefault,
    alignBoxesDrawMode: state.alignBoxesDrawMode,
    alignBoxAbsolutDrawMode: state.alignBoxAbsolutDrawMode,
    groupSelection: state.groupSelection,
    box,
    drawMode: box && (!box.columnId || state.columns.find((col) => col.id === box.columnId).drawMode)
  }
};
export const AlignmentComponent = () => {
  const { alignBoxesDrawMode, alignBoxAbsolutDrawMode, groupSelection, box, drawMode, alignBoxDefault } = useStore(selector);
  const { t } = useTranslation();
  const iconFontSize = 22
  const secondIconFontSizeReduction = 11
  const columnAlignButtons = {
    alignLeft: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-left', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', right: '5px' }} />
      </span >,
      positions: { horizontal: "start" },
    },
    alignRight: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-right', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', left: '5px' }} />
      </span >,
      positions: { horizontal: "end" },
    },
    alignTop: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-up', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', top: '-4px' }} />
      </span >,
      positions: { vertical: "start" },
    },
    alignBottom: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'arrow-down', transform: 'shrink-6', style: 'light' })} style={{ position: 'absolute', bottom: '-4px' }} />
      </span >,
      positions: { vertical: "end" },
    },
    alignCenterHorizontal: {
      icon: < span className="fa-layers" style={{ width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'border-center-v', family: 'duotone', style: 'solid' })} style={{ "--fa-primary-opacity": "0.5", "--fa-secondary-opacity": "0", fontSize: iconFontSize - 4 }} />
      </span >,
      positions: { horizontal: "center" },
    },
    alignCenterVertical: {
      icon: < span className="fa-layers" style={{ display: 'flex', width: '21px' }}>
        <FontAwesomeIcon icon={icon({ name: 'square-dashed', style: 'light' })} style={{ fontSize: iconFontSize }} />
        <FontAwesomeIcon icon={icon({ name: 'border-center-h', family: 'duotone', style: 'solid' })} style={{ "--fa-primary-opacity": "0.5", "--fa-secondary-opacity": "0", fontSize: iconFontSize - 4 }} />
      </span >,
      positions: { vertical: "center" },
    },

    // alignTopLeft: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-top-left', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "start", horizontal: "start" },
    // },
    // alignTopRight: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-top-left', style: 'light' })} style={{ ...iconButtonStyle(), transform: 'rotate(90deg)' }} />,
    //   positions: { vertical: "start", horizontal: "end" },
    // },
    // alignBottomLeft: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-bottom-right', style: 'light' })} style={{ ...iconButtonStyle(), transform: 'rotate(90deg)' }} />,
    //   positions: { vertical: "end", horizontal: "start" },
    // },
    // alignBottomRight: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-bottom-right', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "end", horizontal: "end" },
    // },

    // alignCenter: {
    //   icon: < FontAwesomeIcon icon={icon({ name: 'border-inner', style: 'light' })} style={iconButtonStyle()} />,
    //   positions: { vertical: "center", horizontal: "center" },
    // },
  };

  const relativeAlignButtons = {
    alignLeft: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-left', style: 'light' })} style={iconButtonStyle()} />,
      side: 'left',
    },
    alignRight: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-right', style: 'light' })} style={iconButtonStyle()} />,
      side: 'right',
    },
    alignTop: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-top', style: 'light' })} style={iconButtonStyle()} />,
      side: 'top',
    },
    alignBottom: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-bottom', style: 'light' })} style={iconButtonStyle()} />,
      side: 'bottom',
    },
    alignVertical: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-center-vertical', style: 'light' })} style={iconButtonStyle()} />,
      side: 'vertical',
    },
    alignHorizontal: {
      icon: < FontAwesomeIcon icon={icon({ name: 'objects-align-center-horizontal', style: 'light' })} style={iconButtonStyle()} />,
      side: 'horizontal',
    },
  };

  const absoluteAlignButtons = {
    alignToMarginLeft: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-left-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'left',
    },
    alignToMarginRight: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-right-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'right',
    },
    alignToMarginTop: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-up-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'top',
    },
    alignToMarginBottom: {
      icon: < FontAwesomeIcon icon={icon({ name: 'arrow-down-to-line', style: 'light' })} style={iconButtonStyle()} />,
      side: 'bottom',
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarListButton
        key="AlignmentList"
        title={t('alignment')}
        disabled={!box || box.type === ItemTypes.LINE_BREAK}
        icon={< FontAwesomeIcon icon={icon({ name: 'objects-align-left', style: 'light' })} style={iconButtonStyle()} />}
      >
        {drawMode && groupSelection.length === 0 &&
          Object.keys(absoluteAlignButtons).map((buttonName) => {
            return (
              <Button
                key={`alignment${buttonName}`}
                style={{
                  ...navbarButtonStyle,
                  marginBottom: '1px',
                }}
                disabled={false}
                icon={absoluteAlignButtons[buttonName].icon}
                onMouseDown={() => alignBoxAbsolutDrawMode(absoluteAlignButtons[buttonName].side)}
              />
            );
          })}
        {drawMode && groupSelection.length !== 0 &&
          Object.keys(relativeAlignButtons).map((buttonName) => {
            return (
              <Button
                key={`alignment${buttonName}`}
                style={{
                  ...navbarButtonStyle,
                  marginBottom: '1px',
                }}
                disabled={false}
                icon={relativeAlignButtons[buttonName].icon}
                onMouseDown={() => alignBoxesDrawMode(relativeAlignButtons[buttonName].side)}
              />
            );
          })}
        {!drawMode &&
          Object.keys(columnAlignButtons).map((buttonName) => {
            return (
              <Button
                key={`alignment${buttonName}`}
                style={{
                  ...navbarButtonStyle,
                  marginBottom: '1px',
                }}
                disabled={false}
                icon={columnAlignButtons[buttonName].icon}
                onMouseDown={() => alignBoxDefault(columnAlignButtons[buttonName].positions)}
              />
            );
          })}
      </NavbarListButton>
    </div>
  );
};
