import { useCallback, useEffect, useRef, useState } from 'react';
import { useStore } from './store/store';
import { useMutation, useQuery } from 'react-query';
import { getDocumentTypes } from './requests/documentTypes';
import { getProposalSections, getTemplateSections } from './requests/sections';
import {
  createCustomTextVariable,
  updateCustomTextVariable,
} from './requests/customTextVariable';
import {
  generateColorsObject,
  generateFamilyObject,
  generateFontSizesObject,
  generateHighlightObject,
} from './constants/constants';
import {
  createVariableGroup,
  updateVariableGroup,
} from './requests/variableGroup';

export const useOutsideAlerter = (ref, stateChange) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        stateChange(false);
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, stateChange]);
};

const selector = (state) => ({
  setEventManagerEnabled: state.setEventManagerEnabled,
});

export const useModalOpener = (defaultValue) => {
  const [isOpen, setIsOpen] = useState(defaultValue);
  const { setEventManagerEnabled } = useStore(selector);

  const toggleModal = (opened) => {
    setIsOpen(opened);
    setEventManagerEnabled(!opened);
  };

  return [isOpen, toggleModal];
};

export const useKeyboard = (defaultValue) => {
  const { setEventManagerEnabled } = useStore(selector);

  const setIsUsingKeyboard = (isUsing) => {
    setEventManagerEnabled(!isUsing);
  };

  return setIsUsingKeyboard;
};

export const useUpdateBoxOnUnselect = ({ boxId, isSelected, updateBox }) => {
  const wasLastSelected = useRef(false);
  useEffect(() => {
    if (isSelected) {
      wasLastSelected.current = true;
    }
    if (!isSelected && wasLastSelected.current === true && boxId) {
      wasLastSelected.current = false;
      updateBox(boxId);
    }
  }, [boxId, isSelected, updateBox]);
};

export const useQueryDocumentTypes = (options = {}) =>
  useQuery(['DocumentTypes'], getDocumentTypes, {
    refetchOnWindowFocus: false,
    placeholderData: [],
    ...options,
  });

export const useQueryDocumentSections = (
  { documentType, documentId },
  options = {}
) => {
  const fetchFn =
    documentType === 'template' ? getTemplateSections : getProposalSections;
  return useQuery(
    ['Sections', { documentType, documentId }],
    () => fetchFn(documentId),
    {
      refetchOnWindowFocus: false,
      ...options,
    }
  );
};

export const useCreateCustomTextVariable = (options = {}) => {
  return useMutation(({ name }) => createCustomTextVariable({ name }), {
    ...options,
  });
};

export const useUpdateCustomTextVariable = (options = {}) => {
  return useMutation(({ name, id }) => updateCustomTextVariable({ id, name }), {
    ...options,
  });
};

export const useCreateVariableGroup = (options = {}) => {
  return useMutation(
    ({ name, remark, disposition }) =>
      createVariableGroup({ name, remark, disposition }),
    {
      ...options,
    }
  );
};

export const useUpdateVariableGroup = (options = {}) => {
  return useMutation(
    ({ name, remark, variableIds, id, disposition }) =>
      updateVariableGroup({
        name,
        remark,
        variableIds,
        id,
        disposition,
      }),
    {
      ...options,
    }
  );
};

export const useStyleMap = () => {
  const configuration = useStore((state) => state.configuration);
  const fromPdf = useStore((state) => state.fromPdf);
  const createStyleMap = useCallback(
    () => ({
      CODE: {
        backgroundColor: 'rgba(0, 0, 0, 1.0)',
        fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
        fontSize: 16,
        padding: 2,
      },
      BOLD: {
        fontWeight: 1000,
      },
      HIGHLIGHT: {
        backgroundColor: configuration.currentHighlightColor.style,
      },
      ...generateHighlightObject(configuration.highlightColors),
      ...generateFontSizesObject(),
      ...generateColorsObject(configuration.colors),
      ...generateFamilyObject(configuration.fontFamilyList, fromPdf),
    }),
    [configuration, fromPdf]
  );
  const [styleMap, setStyleMap] = useState(createStyleMap());

  useEffect(() => {
    setStyleMap(createStyleMap);
  }, [createStyleMap]);
  return styleMap;
};
