import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// Common
// Others
import { Editor, EditorState } from 'draft-js';
import { useStore } from '../../store/store';
import { displayTitleIndex, getTitlesConfig } from '../../utils/titles';
import { getTitleStyle } from './titleUtils';

import { debounce } from 'lodash';
import ReactDOM from 'react-dom';
import { decorator, handleBeforeInput } from '../RichEditor/utils';
import ContextualMenu from '../ContextualMenu/ContextualMenu';
import { MENU_ANCESTORS } from '../../constants/constants';
import { EditorProvider } from '../../contexts/EditorContext';
// Store selector
const selector = (id, type) => (state) => ({
  updateBox: state.updateBox,
  variables: state.opportunitieVariables,
  configuration: state.configuration,
  configurationType: state.configuration[type],
  setBoxMovable: state.setBoxMovable,
  separator: state.configuration.separatorTitle,
  gridConfiguration: state.gridConfiguration,
  titleTypeConfig: getTitlesConfig(state.configuration),
  isTemplate: state.currentDocument.type === 'template',
  setEventManagerEnabled: state.setEventManagerEnabled,
  isSelected: state.selectedBoxId === id,
});

const Title = ({ box, drawMode }) => {
  const editorRef = useRef(null);
  const boundingBoxRef = useRef(null);
  const {
    updateBox,
    configuration,
    configurationType,
    separator,
    setBoxMovable,
    titleTypeConfig,
    gridConfiguration,
    setEventManagerEnabled,
    isSelected,
  } = useStore(selector(box.id, box.type));

  const [editorState, setEditorState] = useState(EditorState.createWithContent(
    box.content.editorState.getCurrentContent(),
    decorator
  ));
  const saveBox = useCallback(debounce((editorState) => {
    if (boundingBoxRef.current) {
      updateBox(box.id, (box) => {
        box.height = boundingBoxRef?.current.firstChild.clientHeight;
        box.content.editorState = EditorState.createWithContent(
          editorState.getCurrentContent(),
          decorator
        );
      });
    }
  }, 100), [box?.id, updateBox]);

  useEffect(() => {
    if (isSelected) {
      saveBox(editorState)
    }
  }, [isSelected, saveBox, editorState]);

  const {
    color: { style: color },
    ...rest
  } = configurationType;


  const write = (editorState) => {
    setEditorState(editorState);
  };


  const fontSizeTitle = configuration[box.type].fontSize;
  const paddingTitle = gridConfiguration[box.type].paddingTitle;

  // If in default container, focus on one click
  useEffect(() => {
    if (!drawMode && isSelected) {
      editorRef.current.focus();
    }
  }, [drawMode, isSelected])

  const menuAncestorIds = useMemo(() => [box.id], [box.id])

  return (
    <EditorProvider setEditorState={setEditorState} editorState={editorState} readOnly={!isSelected}>
      <div
        ref={boundingBoxRef}
        id={box.id}
        style={{
          ...getTitleStyle(
            configuration[box.type].backgroundColor,
            configuration[box.type].borders,
            configuration[box.type],
            color,
            rest
          ),
          width: box.width,
        }}
        onKeyDown={(e) => {
          if (editorState.getSelection().getHasFocus())
            e.stopPropagation()
        }}
      >
        <div
          style={{ paddingRight: paddingTitle, fontSize: fontSizeTitle, width: 'auto', flexShrink: 0 }}
        >
          {displayTitleIndex(
            box.content.indexTitle,
            separator,
            titleTypeConfig,
            configuration.hideTitleIndex
          )}
        </div>
        <Editor
          stripPastedStyles={true}
          handleBeforeInput={(chars, editorState) => handleBeforeInput(
            {
              chars,
              editorState,
              setEditorState
            }
          )}
          editorState={editorState}
          onBlur={() => saveBox(editorState)}
          onChange={write}
          spellCheck={true}
          ref={editorRef}
          onFocus={(event) => {
            setBoxMovable(false);
            setEventManagerEnabled(false);
          }}
          readOnly={!isSelected}
        />
        {isSelected &&
          ReactDOM.createPortal(
            <ContextualMenu
              editorState={editorState}
              setEditorState={setEditorState}
              menuAncestorType={MENU_ANCESTORS.BOX.content}
              menuAncestorIds={menuAncestorIds}
            />,
            document.getElementById('SelectionVariablePortal')
          )}
      </div>
    </EditorProvider>
  );
};

export default Title;
