import { Input } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { useStore } from '../../store/store'
import { mutateInjectVariablesInDocument } from '../../utils/variables'
import { pageBackground } from '../../constants/constants'

const selector = (variableKey) => ({
  variables,
  updateVariable,
}) => {
  return {
    variables,
    variable: variables[variableKey],
    updateVariable
  }
}

const CustomTextVariable = ({ variableKey }) => {
  const { variable, updateVariable, variables } = useStore(selector(variableKey))
  const [value, setValue] = useState(variable.value)
  const ref = useRef()


  useEffect(() => {
    setValue(variable.value)
  }, [variable.value])

  return (
    <div style={{
      padding: "8px 16px",
      marginTop: 'auto'
    }}>
      <Input.TextArea
        value={value}
        data-role={pageBackground}
        ref={ref}
        onClick={() => ref.current.focus()}
        onChange={(e) => setValue(e.currentTarget.value ?? '')}
        placeholder="Insérer la valeur..."
        onBlur={(e) => {
          updateVariable(variableKey, (variable, draft) => {
            variable.value = e.target.value ?? '';
            mutateInjectVariablesInDocument({
              variables,
              variable,
              variableKey,
              draft
            })
          })
        }}
      />
    </div>
  )
}

export default CustomTextVariable
