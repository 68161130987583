import React, { useEffect, useMemo, useRef } from 'react'
import { Select } from 'antd'
import { useStore } from '../../store/store';
import './SelectorVariable.less'
import ReactDOM from 'react-dom';
import NavbarSelector from '../Layout/NavbarSelector';
import { CaretDownOutlined } from '@ant-design/icons';
import { Separator } from '../Layout/Navbar';
import { useTranslation } from 'react-i18next';
import { isAVariableBox } from '../../utils/boxes';
import { leftShownStatus, useOverlay } from '../../contexts/OverlayContext';
import { Editor } from 'draft-js';

const selector = (id) =>
  ({ updateBox, selectedBoxId, fromPdf, boxVariableSelectedId, configuration, boxes, masks }) => {
    let selectedBox = [...boxes, ...masks.map((m) => m.boxes).flat()].find((box) => box?.id === id)
    return {
      isSelected: selectedBoxId === id,
      updateBox,
      fromPdf,
      boxVariableSelectedId,
      configuration,
      selectorStyle: configuration.selectorStyles.find((ts) => ts.id === selectedBox?.selectorStyleId)

    };
  };

const { Option } = Select;

const StylingComponent = ({ updateBox, box, configuration, selectorStyle }) => {

  const { t } = useTranslation();
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <NavbarSelector
        tooltipContent={t('selector-style')}
        suffixIcon={<CaretDownOutlined />}
        width={120}
        onSelect={(e) => {
          updateBox(box.id, (box) => {
            box.selectorStyleId = configuration.selectorStyles.find((ts) => ts.name === e).id;
          });
        }}
        selectedValue={selectorStyle?.name}
        options={configuration.selectorStyles.map(style => ({ value: style.name, label: style.name }))}
      />
      <Separator />
    </div>
  )
}

const SelectorVariable = ({ box }) => {
  const {
    options,
    maxNumberOfAnwser
  } = box
  const { updateLeftShownStatus } = useOverlay();
  const {
    updateBox,
    isSelected,
    fromPdf,
    boxVariableSelectedId,
    selectorStyle,
    configuration,
  } = useStore(selector(box.id));
  const selectorRef = useRef()
  const hasCheckedMaxNumberOfAnswers = options.filter((c) => c.selected).length === maxNumberOfAnwser

  const selectedOptions = useMemo(() => {
    return options.filter((o) => o.selected)
  }, [options])

  // Focus on one click
  useEffect(() => {
    if (isSelected && boxVariableSelectedId !== box.id) {
      selectorRef.current.focus();
    }
  }, [isSelected, boxVariableSelectedId, box.id])

  const updateSelector = (optionIds = []) => updateBox(box.id, (box) => {
    box.options = box.options.map((option) => optionIds.includes(option.id) ? {
      ...option,
      selected: true
    } : {
      ...option,
      selected: false
    })
  })
  const removeOption = () => updateBox(box.id, (box) => {
    box.options = box.options.map((option) => ({
      ...option,
      selected: false
    }))
  })

  const renderSelectedOption = (option, isLast) => {
    const separator = selectorStyle?.separator === "\n" ? selectorStyle?.separator : ` ${selectorStyle?.separator} `
    return (
      <span key={option.id}>
        <Editor
          readOnly
          editorState={option.label} />
        {!isLast && separator}
      </span>
    )
  }

  const getStyle = () => {
    return {
      width: "100%",
      minHeight: 16,
      backgroundColor: selectorStyle?.backgroundColor?.style,
      borderRadius: selectorStyle?.borderRadius,
      padding: `${selectorStyle?.paddingTopBottom}px ${selectorStyle?.paddingLeftRight}px`,
      border: `${selectorStyle?.bordersSize}px solid ${selectorStyle?.borderColor?.style}`,
      color: selectorStyle?.color?.style,
      fontSize: selectorStyle?.fontSize,
      display: 'flex',
      justifyContent: selectorStyle?.position,
      fontFamily: selectorStyle?.fontFamily,
    }
  }

  return (
    <div style={{
      position: 'relative',
      width: box.width,
      display: 'flex',
      alignItems: 'center',
      zIndex: 1000,
    }}
      className='selector-variable'
    >
      {!isSelected && <div style={getStyle()} onDoubleClick={(event) => {
        if (isAVariableBox(box?.type)) {
          updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
        }
        event.stopPropagation()
      }}>
        <span style={{
          overflow: 'hidden',
          wordWrap: 'break-word',
          whiteSpace: 'break-spaces'
        }}>
          {selectedOptions.length < 1 && !fromPdf &&
            <div style={{
              color: 'grey'
            }}>
              Sélectionnez une réponse
            </div>}
          {selectedOptions.map((o, idx) => renderSelectedOption(o, idx + 1 === selectedOptions.length))}
        </span>
      </div>}
      {isSelected && <Select
        onDoubleClick={(event) => {
          if (isAVariableBox(box?.type)) {
            updateLeftShownStatus(leftShownStatus["VARIABLES"], box.id)
          }
          event.stopPropagation()
        }}
        onChange={updateSelector}
        ref={selectorRef}
        showAction={['focus']}
        value={selectedOptions.map((o) => o.id)}
        allowClear
        onClear={removeOption}
        mode={maxNumberOfAnwser > 1 ? 'multiple' : undefined}
        search={false}
        style={{
          width: '100%',
          minHeight: 16
        }}
      >
        {options.map((option) => {
          return (
            <Option
              key={option.id}
              value={option.id}
              disabled={hasCheckedMaxNumberOfAnswers && !option.selected && maxNumberOfAnwser !== 1}
            >
              <Editor
                readOnly
                editorState={option.label} />
            </Option>
          );
        })}
      </Select>}
      {isSelected &&
        ReactDOM.createPortal(
          <StylingComponent updateBox={updateBox} box={box} configuration={configuration} selectorStyle={selectorStyle} />,
          document.getElementById('ComponentPortal')
        )}
    </div>
  )
}

export default SelectorVariable
