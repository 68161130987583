import React, { useCallback } from 'react'
import VariableName from './VariableName'
import VariableRemark from './VariableRemark'
import VariableDisposition from './VariableDisposition'
import { useUpdateVariableGroup } from '../../hooks'
import { useStore } from '../../store/store'
import { rawVariableGroupToStoreVariableGroup, variableGroupToRaw } from '../../utils/variableGroups'
import { convertToRaw } from 'draft-js'

const VariableGroupLeftForm = ({ box, validateFormAndRetrieveValues, form }) => {
  const updateVariableGroup = useStore(({ updateVariableGroup }) => updateVariableGroup)
  const {
    mutate: updateVariableGroupMutation,
  } = useUpdateVariableGroup();

  const handleOnBlur = useCallback(async () => {
    try {
      const values = await validateFormAndRetrieveValues();
      updateVariableGroupMutation(variableGroupToRaw({ id: box.id, name: values.name, remark: values.remark, disposition: values.disposition }), {
        onSuccess: (updatedVariableGroup) => {
          updateVariableGroup(box.id,
            (variableGroup) => {
              const newVG = rawVariableGroupToStoreVariableGroup(updatedVariableGroup)
              Object.keys(newVG).forEach((key) => {
                variableGroup[key] = newVG[key]
              }
              )
            })
        }
      })
    } catch (errors) {
    }
  }, [box.id, updateVariableGroup, updateVariableGroupMutation, validateFormAndRetrieveValues])

  const handleOnBlurRemark = async () => {
    try {
      const values = await validateFormAndRetrieveValues();
      updateVariableGroup(box.id, (variableGroup) => {
        variableGroup.remark = JSON.stringify(convertToRaw(values.remark.getCurrentContent()));
      })
    } catch (errors) {
    }
  }

  return (
    <div className='variable-editor__left'>
      <VariableName box={box} onBlur={handleOnBlur} form={form} />
      <VariableDisposition box={box} onBlur={handleOnBlur} />
      <VariableRemark box={box} onBlur={handleOnBlurRemark} form={form} />
    </div>
  )
}

export default VariableGroupLeftForm
