import React, { useMemo } from 'react'
import './VariableHandler.less'
import { navbarHeight } from '../../constants/gridConfig'
import { useStore } from '../../store/store'
import { pageBackground, variableHandlingType } from '../../constants/constants'
import VariableViewer from './VariableViewer'
import VariableEditor from './VariableEditor'
import { ConfigProvider } from 'antd'

const selector = ({
  currentDocument,
  boxVariableSelectedId
}) => {
  return {
    documentType: currentDocument.type,
    boxVariableSelectedId
  }
}

const VariableHandler = ({
  variablesGroupedByContainer,
  isHandlingVariables,
}) => {
  const { documentType, boxVariableSelectedId } = useStore(selector)

  const variablesGroupedByContainerSelected = useMemo(() => {
    if (isHandlingVariables) return;
    return variablesGroupedByContainer.find((vInfos) => {
      if (vInfos.variableGroupBox && vInfos.variableGroupBox.id === boxVariableSelectedId) {
        return true
      }
      return vInfos.variablesBoxes.some((b) => b.id === boxVariableSelectedId)
    })
  }, [boxVariableSelectedId, isHandlingVariables, variablesGroupedByContainer])

  const box = useMemo(() => {
    if (!variablesGroupedByContainerSelected) return;
    if (variablesGroupedByContainerSelected.variableGroupBox && variablesGroupedByContainerSelected.variableGroupBox.id === boxVariableSelectedId) {
      return variablesGroupedByContainerSelected.variableGroupBox
    }
    return variablesGroupedByContainerSelected.variablesBoxes.find((b) => b.id === boxVariableSelectedId)
  }, [boxVariableSelectedId, variablesGroupedByContainerSelected])

  const hideViewer = documentType === 'template' &&
    (isHandlingVariables || variablesGroupedByContainerSelected?.type !== variableHandlingType.FORM)

  return (
    <ConfigProvider
      theme={{
        components: {
          InputNumber: {
            colorTextDisabled: '#0000003f',
            colorBgContainerDisabled: '#000000a'
          },
        }
      }}
    >
      <div
        className='variable-editor__wrapper'
        data-role={pageBackground}
        style={{
          position: 'absolute',
          width: '100vw',
          top: navbarHeight,
          left: 0,
          backgroundColor: "#f5f5f5",
          height: window.innerHeight - navbarHeight,
          zIndex: 16,
          paddingLeft: 220,
        }}
      >
        <div style={{
          position: 'relative',
          width: "100%",
          height: "100%",
          display: 'flex',
          flexDirection: 'column'
        }}
          data-role={pageBackground}
        >

          {documentType === 'template' &&
            <VariableEditor
              key={boxVariableSelectedId}
              box={box}
              isHandlingVariables={isHandlingVariables}
              variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
              variablesGroupedByContainer={variablesGroupedByContainer}
            />
          }

          <div style={{
            flex: hideViewer ? 0 : 1,
            transition: 'min-height 0.3s ease-in-out, flex 0.3s ease-in-out',
            minHeight: hideViewer ? 1 : 450
          }}>
            {!hideViewer &&
              <VariableViewer
                variablesGroupedByContainer={variablesGroupedByContainer}
                box={box}
                variablesGroupedByContainerSelected={variablesGroupedByContainerSelected}
              />}
          </div>
        </div>
      </div>
    </ConfigProvider>
  )
}

export default VariableHandler
