import React from 'react'
import Header from './Header'

const TableHead = ({
  table,
  columns,
  isSelected,
  boxId,
  handleClickInTable,
  updateTheadHeight,
  tableStyle,
  convertAlign,
  rotation,
  setColumnSizeVars,
  computeMaxColumnWidth,
  drawMode
}) => {
  const renderHeader = (header) => {
    const colIdx = columns.findIndex((col) => col.key === header.id)
    const col = columns[colIdx]
    const nextColKey = colIdx !== columns.length - 1 ? columns[colIdx + 1].key : undefined

    return (
      <Header
        isLastColumn={colIdx === columns.length - 1}
        rotation={rotation}
        computeMaxColumnWidth={computeMaxColumnWidth}
        setColumnSizeVars={setColumnSizeVars}
        nextColKey={nextColKey}
        key={header.id}
        tableIsSelected={isSelected}
        title={col.title}
        boxId={boxId}
        handleClickInTable={handleClickInTable}
        updateTheadHeight={updateTheadHeight}
        columnKey={col.key}
        tableStyle={tableStyle}
        align={convertAlign(col.textAlign ?? 'start')}
        drawMode={drawMode}
      />
    )
  }
  return (
    <thead>
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(renderHeader)}
        </tr>
      ))}
    </thead>
  )
}

export default TableHead
