import { Button, Checkbox, Col, Form, InputNumber, Row, Switch } from 'antd';
import { ItemTypes, roundButtonWidth } from '../../../constants/constants';
import {
  calculateLeftPadding,
  calculateWidth,
  unitName,
} from '../../../constants/gridConfig';
import { useStore } from '../../../store/store';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

const selector = ({
  configuration,
  setConfiguration,
  gridConfiguration,
  setGridConfiguration,
}) => ({
  configuration,
  setConfiguration,
  gridConfiguration,
  setGridConfiguration,
});

export const TabsMarges = () => {
  const {
    configuration,
    setConfiguration,
    gridConfiguration,
    setGridConfiguration,
  } = useStore(selector);
  const { t } = useTranslation();
  const [isHovered, setIsHovered] = useState(false);
  const changeItemConfig = (item, margins, indentValue, indentLevel) => {
    setGridConfiguration({
      [item]: {
        ...gridConfiguration[item],
        width: calculateWidth(margins, indentValue, indentLevel),
        left: calculateLeftPadding(margins, indentValue, indentLevel),
      },
    });
  }

  const handleChangeEditableTemplate = (value) => {
    if (configuration?.editableTemplate || !configuration.hasOwnProperty('editableTemplate')) {
      setConfiguration({
        editableTemplate: false
      })
    } else {
      setConfiguration({
        editableTemplate: true
      })
    }
  }

  const handleChangeUnlockProposal = (value) => {
    if (configuration?.unlockProposal || !configuration.hasOwnProperty('unlockProposal')) {
      setConfiguration({
        unlockProposal: false
      })
    } else {
      setConfiguration({
        unlockProposal: true
      })
    }
  }

  const padLockState = () => {
    if (configuration?.editableTemplate || !configuration.hasOwnProperty('editableTemplate')) {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole-open', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
    else {
      return (<FontAwesomeIcon
        icon={icon({ name: 'lock-keyhole', style: 'light' })}
        style={{ margin: 0, fontSize: 15 }} />)
    }
  }

  const handleChangeMargin = (side) => (value) => {
    setConfiguration({
      ...configuration,
      margins: {
        ...configuration.margins,
        [side]: value,
      },
    });
    [ItemTypes.TITLE, ItemTypes.TITLE_2, ItemTypes.TITLE_3, ItemTypes.TITLE_4, ItemTypes.SUMMARY, ItemTypes.TABLE].forEach(
      (item, index) => {
        let indentation = index;
        if (item === ItemTypes.SUMMARY || item === ItemTypes.TABLE) { indentation = 0; }
        const margins = {
          ...configuration.margins,
          [side]: value,
        }
        changeItemConfig(item, margins, configuration.indentationTitles, indentation);
      }
    );
  };

  const handleChangeTitlesIndentation = (value) => {
    setConfiguration({
      ...configuration,
      indentationTitles: value,
    });
    [ItemTypes.TITLE, ItemTypes.TITLE_2, ItemTypes.TITLE_3, ItemTypes.TITLE_4, ItemTypes.TABLE, ItemTypes.SUMMARY].forEach(
      (item, index) => {
        let indentation = index;
        if (item === ItemTypes.SUMMARY || item === ItemTypes.TABLE) { indentation = 0; }
        changeItemConfig(item, configuration.margins, value, indentation);
      }
    );
  };

  return (
    <>
      {['left', 'right'].map((side) => (
        <Form.Item
          label={t(`margin-${side}`)}
        >
          <InputNumber
            min={0}
            max={16}
            formatter={(value) => `${value} ${unitName}`}
            onChange={handleChangeMargin(side)}
            value={configuration.margins[side]}
          />
        </Form.Item>
      ))}
      <Form.Item
        label="Décalage des titres (vers la droite par rapport au titre de niveau supérieur)"
      >
        <InputNumber
          min={0}
          max={16}
          formatter={(value) => `${value} ${unitName}`}
          onChange={handleChangeTitlesIndentation}
          value={configuration.indentationTitles}
        />
      </Form.Item>
      <Form.Item
        name='page-format'
        label={'Containers éditables ?'}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Button shape="circle"
            size={14}
            onClick={(value) => handleChangeEditableTemplate()}
            style={{
              width: roundButtonWidth,
              height: roundButtonWidth,
              minWidth: roundButtonWidth,
              minHeight: roundButtonWidth,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            icon={
              padLockState()
            }
          />
        </div>
      </Form.Item>
      <Form.Item
        name='page-format'
        label={'Ajout et suppression de lignes autorisés ?'}
      >
        <div
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}>
          <Checkbox checked={configuration.unlockProposal} onClick={(value) => handleChangeUnlockProposal()} />
        </div>
      </Form.Item>
    </>
  );
};
